import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Close from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
//components
import * as generalConstants from "../../../../../_constants/generalConstants";
import PageSubmitWBackAction from "../../../../../components/PageSubmitWBackAction";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import { useStyles } from "../../../../../components/globalStyles";
import * as generalConstant from "../../../../../_constants/generalConstants";
import StyledCheckbox from "../../../../../components/StyledCheckbox";

export default function AddAdhocModal({
  openModal,
  setOpenModal,
  branchId,
  editDeliveryCutoffSchedule,
  setEditDeliveryCutoffSchedule,
  handleUpdate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit } = useForm();
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const common = useSelector((state) => state.common);

  const [adhocModal, setAdhocModal] = useState({
    id: editDeliveryCutoffSchedule ? editDeliveryCutoffSchedule.id : 0,
    day_of_the_week: editDeliveryCutoffSchedule
      ? editDeliveryCutoffSchedule.day_of_the_week
      : null,
    cutoff_time: editDeliveryCutoffSchedule
      ? editDeliveryCutoffSchedule.cutoff_time
      : null,
    is_brewing_services: editDeliveryCutoffSchedule
      ? editDeliveryCutoffSchedule.is_brewing_services
      : null,
    name: editDeliveryCutoffSchedule ? editDeliveryCutoffSchedule.name : null,
    status_type: editDeliveryCutoffSchedule
      ? editDeliveryCutoffSchedule.status_type
      : generalConstant.APPROVED_STATUS_TYPE,
  });

  const onConfirmSubmit = () => {
    let newResult = [];

    newResult = [
      {
        id: adhocModal.id,
        day_of_the_week: adhocModal.day_of_the_week,
        cutoff_time: adhocModal.cutoff_time,
        is_brewing_services: adhocModal.is_brewing_services,
        name: adhocModal.name,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      },
    ];

    const submitJson = {
      branch: { id: branchId, delivery_cutoff_schedules: newResult },
    };
    handleUpdate(submitJson);
    handleReset();
  };

  const handleReset = () => {
    setAdhocModal({
      id: 0,
      closed_date: null,
      closed_day: null,
      name: null,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
    });
    setEditDeliveryCutoffSchedule(null);
    setOpenModal(false);
  };

  const onSubmit = () => {
    setConfirmSubmit(true);
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="xs"
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleReset();
        }
      }}
      fullWidth>
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => handleReset()}
      />
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="confirm-dialog">
          {editDeliveryCutoffSchedule
            ? t("translation:Edit Non Business Day")
            : t("translation:Add Non Business Day")}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              required
              value={adhocModal.name ? adhocModal.name : ""}
              className={classes.userFieldColor}
              variant="outlined"
              label={t("translation:Name")}
              fullWidth
              onChange={(e) =>
                setAdhocModal({
                  ...adhocModal,
                  name: e.target.value ? e.target.value : null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              required
              value={
                adhocModal.day_of_the_week ? adhocModal.day_of_the_week : ""
              }
              className={classes.userFieldColor}
              variant="outlined"
              type="number"
              label={t("translation:Day of the week")}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E" ||
                  event?.key === "."
                ) {
                  event.preventDefault();
                }
              }}
              fullWidth
              InputProps={{
                inputProps: {
                  max: 7,
                  min: 1,
                  step: "1",
                },
              }}
              onChange={(e) =>
                setAdhocModal({
                  ...adhocModal,
                  day_of_the_week: e.target.value ? e.target.value : null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={adhocModal.cutoff_time ? adhocModal.cutoff_time : ""}
              className={classes.userFieldColor}
              type="time"
              variant="outlined"
              label={t("translation:Cut Off Time")}
              fullWidth
              onChange={(e) =>
                setAdhocModal({
                  ...adhocModal,
                  cutoff_time: e.target.value
                    ? moment(e.target.value, "HH:mm").format("HH:mm:ss")
                    : null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              name="status"
              options={
                adhocModal.id
                  ? common.generalStatusList.status_general
                  : common.generalStatusList.status_general.filter(
                      (item) => item.id === generalConstant.APPROVED_STATUS_TYPE
                    )
              }
              disableClearable
              value={common.generalStatusList.status_general.find(
                (item) => item.id === adhocModal.status_type
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (option === value) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) =>
                setAdhocModal({
                  ...adhocModal,
                  status_type: option ? option.id : null,
                })
              }
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Status")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={adhocModal.is_brewing_services ? true : false}
                  onClick={(e) => {
                    setAdhocModal({
                      ...adhocModal,
                      is_brewing_services: e.target.checked
                        ? e.target.checked
                        : null,
                    });
                  }}
                  name="is_brewing_services"
                />
              }
              label={t("translation:Brewing Services")}
            />
          </Grid>
        </DialogContent>

        <PageSubmitWBackAction
          setView={() => {
            handleReset();
          }}
        />
      </form>
    </Dialog>
  );
}
