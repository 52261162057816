//lib
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button, Grid, Link } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { putDispensaryStoreProceedOn } from "../../../../actions/dispensaryActions";
import noEnterSubmitForm from "../../../../components/functions/noEnterSubmitForm";
import { useStyles } from "../../../../components/globalStyles";

//style
export default function EditProceedOnDialog({
  memoSelectedGroup,
  setGroupOption,
}) {
  const { handleSubmit } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevUpdateProceedOnStatus = useRef();

  const [editDeliveryOpen, setEditDeliveryOpen] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(null);

  const [selectedGroup, setSelectedGroup] = useState(
    memoSelectedGroup.store_medicine_header.proceed_on
  );

  const dispensary = useSelector((state) => state.dispensary);

  const onSubmit = () => {
    const submitJson = {
      dispensary_header: {
        id: memoSelectedGroup.id,
      },
      proceed_on: selectedGroup,
    };
    dispatch(putDispensaryStoreProceedOn.pending(submitJson));
  };

  const onConfirmSubmit = () => {
    setConfirmSubmitOpen(true);
  };

  useEffect(() => {
    prevUpdateProceedOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      dispensary.isLoadingPutDispensaryStoreProceedOn !==
      prevUpdateProceedOnStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateProceedOnStatus.current =
        dispensary.isLoadingPutDispensaryStoreProceedOn;

      if (
        dispensary.putDispensaryStoreProceedOnIsSuccess === true &&
        dispensary.isLoadingPutDispensaryStoreProceedOn === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);

        setSelectedGroup(
          dispensary.putDispensaryStoreProceedOn.dispensary_header
            .store_medicine_header.proceed_on
        );
      }
      if (
        dispensary.putDispensaryStoreProceedOnIsSuccess === false &&
        dispensary.isLoadingPutDispensaryStoreProceedOn === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(dispensary.error.putDispensaryInvoicePrintDateError);
      }
    }
  }, [dispensary, setGroupOption]);

  return (
    <>
      <Link className={classes.boldMaroon075}>
        <Button
          className={classes.boldMaroon075}
          onClick={() => setEditDeliveryOpen(true)}>
          {t("translation:Edit Proceed On")}
        </Button>
      </Link>

      <Dialog
        open={editDeliveryOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setEditDeliveryOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="md">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setEditDeliveryOpen(false)}
        />
        <form
          onSubmit={handleSubmit(onConfirmSubmit)}
          onKeyDown={(e) => noEnterSubmitForm(e)}
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}>
          <Collapse in={submitErrorAlarm}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSubmitErrorAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
          <Collapse in={submitSuccessAlarm}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSubmitSuccessAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {t("translation:Address has been updated")}
            </Alert>
          </Collapse>
          <ConfirmDialog
            Yes={t("translation:Yes")}
            No={t("translation:No")}
            title={t("translation:Confirmation")}
            open={confirmSubmitOpen}
            setOpen={setConfirmSubmitOpen}
            onConfirm={onSubmit}>
            {t("translation:Are you sure you want to proceed?")}
          </ConfirmDialog>

          <DialogContent>
            <Grid container>
              <Grid item xs={12} className={classes.userInfoField}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={classes.userFieldColor}
                    inputVariant="outlined"
                    id="date"
                    label={t("translation:Proceed Date")}
                    autoOk={true}
                    isRequired
                    value={dayjs(selectedGroup)}
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    format="DD MMM YYYY"
                    onChange={(e) => {
                      if (dayjs(e, true).isValid()) {
                        setSelectedGroup(
                          dayjs(e, true).isValid()
                            ? dayjs(e).format("YYYY-MM-DD")
                            : null
                        );
                      }
                      if (e === null) {
                        setSelectedGroup(null);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                name="submitButton"
                type="submit">
                {t("translation:SUBMIT")}
              </Button>
            </Link>

            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                name="backButton"
                onClick={() => setEditDeliveryOpen(false)}>
                {t("translation:BACK")}
              </Button>
            </Link>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
