import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DISPENSARYBRANCHES_ASYNC = createAsyncTypes(
  "GET_DISPENSARYBRANCHES"
);

export const GET_MEDICINETYPES_ASYNC = createAsyncTypes("GET_MEDICINETYPES");

export const GET_DISPENSARYBRANCHESALLIANCE_ASYNC = createAsyncTypes(
  "GET_DISPENSARYBRANCHESALLIANCE"
);

export const GET_MEDICINETYPESALLIANCE_ASYNC = createAsyncTypes(
  "GET_MEDICINETYPESALLIANCE"
);

export const POST_FORMULASALLIANCE_ASYNC = createAsyncTypes(
  "POST_FORMULASALLIANCE"
);

export const POST_MEDICINEFORMULASALLIANCE_ASYNC = createAsyncTypes(
  "POST_MEDICINEFORMULASALLIANCE"
);

export const POST_BRANCHMEDICINES_ASYNC = createAsyncTypes(
  "POST_BRANCHMEDICINES"
);

export const GET_CURRENTORGANISATION_ASYNC = createAsyncTypes(
  "GET_CURRENTORGANISATION"
);

export const POST_SAVESTOREORDER_ASYNC = createAsyncTypes(
  "POST_SAVESTOREORDER"
);

export const POST_SUBMITSTOREORDER_ASYNC = createAsyncTypes(
  "POST_SUBMITSTOREORDER"
);

export const GET_STOREORDERLIST_ASYNC = createAsyncTypes("GET_STOREORDERLIST");

export const GET_STOREORDERLISTLINKEDDELIVERY_ASYNC = createAsyncTypes(
  "GET_STOREORDERLISTLINKEDDELIVERY"
);

export const GET_STOREORDERSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_STOREORDERSUMMARYLIST"
);

export const GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC = createAsyncTypes(
  "GET_STOREORDERSUMMARYLISTLINKEDDELIVERY"
);

export const GET_STOREORDERBYID_ASYNC = createAsyncTypes("GET_STOREORDERBYID");

export const GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC = createAsyncTypes(
  "GET_STOREORDERBYIDLINKEDDELIVERY"
);

export const PUT_STOREEDITMODE_ASYNC = createAsyncTypes("PUT_STOREEDITMODE");

export const PUT_STOREPRINTDATE_ASYNC = createAsyncTypes("PUT_STOREPRINTDATE");

export const GET_STOREPRESCRIPTIONMSGLIST_ASYNC = createAsyncTypes(
  "GET_STOREPRESCRIPTIONMSGLIST"
);

export const GET_STOREMEDICINEINFO_ASYNC = createAsyncTypes(
  "GET_STOREMEDICINEINFO"
);

export const GET_STOREPOSTALCODESURCHARGE_ASYNC = createAsyncTypes(
  "GET_STOREPOSTALCODESURCHARGE"
);

export const GET_STOREAVAILABLEPROCEEDON_ASYNC = createAsyncTypes(
  "GET_STOREAVAILABLEPROCEEDON"
);

export const GET_STOREVERIFYADDRESS_ASYNC = createAsyncTypes(
  "GET_STOREVERIFYADDRESS"
);
