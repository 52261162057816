//lib
import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//action
import { putDeliveryTrackingUploadFile } from "../../../actions/deliveryTrackingActions";

//components
import RequiredNote from "../../../components/RequiredNote";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import PageCustomAction from "../../../components/PageCustomAction";
import ViewDelivery from "../../workOrder/Store/components/viewOrder/ViewDelivery";
import Panel from "../../../components/Panel";
import EditAttachment from "./components/EditAttachment";
import DownloadAttachment from "./components/DownloadAttachment";

const OrderDetailPage = ({
  setOrderDetailView,
  orderDetailInfo,
  editMode = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevUpdateAttrachmentStatus = useRef();

  const curUser = useSelector((state) => state.curUser);
  const deliveryTracking = useSelector((state) => state.deliveryTracking);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [tempEditMode, setTempEditMode] = useState(editMode);
  const [msgAlarm, setMsgAlarm] = React.useState(null);
  const defualtOrganisation =
    curUser.organisationInfo &&
    Object.keys(curUser.organisationInfo.user_organisations).length !== 0
      ? curUser.organisationInfo?.user_organisations?.find(
          (o) => o.is_default === true
        )
      : null;

  const [uploadFileObjects, setUploadFileObjects] = React.useState(
    orderDetailInfo.delivery_tracking_attachments.map((item) => {
      return { ...item, file: { name: item.original_filename } };
    })
  );

  const onSubmit = () => {
    const temUploadFileObjects = uploadFileObjects.map((item) => {
      return {
        id: item.id,
        name: item.name,
        original_filename: item.original_filename,
        status_type: item.status_type,
        url: item.url,
      };
    });
    const submitJSON = {
      delivery_tracking_header: {
        delivery_order_header: {
          id: orderDetailInfo.delivery_order_header.id,
        },
        delivery_tracking_attachments: temUploadFileObjects,
        id: orderDetailInfo.id,
      },
    };
    dispatch(putDeliveryTrackingUploadFile.pending(submitJSON));
  };

  useEffect(() => {
    prevUpdateAttrachmentStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryTracking.isLoadingPutDeliveryTrackingUploadFile !==
      prevUpdateAttrachmentStatus.current
    ) {
      //
      //check the previous ref with current state
      prevUpdateAttrachmentStatus.current =
        deliveryTracking.isLoadingPutDeliveryTrackingUploadFile;

      if (
        deliveryTracking.putDeliveryTrackingUploadFileIsSuccess === true &&
        deliveryTracking.isLoadingPutDeliveryTrackingUploadFile === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setTempEditMode(false);
      }

      if (
        deliveryTracking.putDeliveryTrackingUploadFileIsSuccess === false &&
        deliveryTracking.isLoadingPutDeliveryTrackingUploadFile === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(deliveryTracking.error.putDeliveryTrackingCloseStatusError);
      }
    }
  }, [dispatch, deliveryTracking]);

  return (
    <div>
      <Collapse in={submitSuccessAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Delivery Tracking has been submitted")}
        </Alert>
      </Collapse>

      <Collapse in={submitErrorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <Typography display="block" variant="h4">
        {orderDetailInfo.delivery_order_header.order_number}
      </Typography>
      <br />
      <Panel>
        {!defualtOrganisation && (
          <Alert severity="error">
            {t("translation:Missing Organisation")}
          </Alert>
        )}
        <Grid container>
          {defualtOrganisation !== null && (
            <Grid item xs={12}>
              {tempEditMode === true && (
                <EditAttachment
                  organisationCode={defualtOrganisation.organisations.codename}
                  folderPath={"delivery_tracking_attachment"}
                  uploadFileObjects={uploadFileObjects}
                  setUploadFileObjects={setUploadFileObjects}
                />
              )}
              <DownloadAttachment
                organisationCode={defualtOrganisation.organisations.codename}
                folderPath={"delivery_tracking_attachment"}
                uploadFileObjects={uploadFileObjects}
              />
            </Grid>
          )}
          <ViewDelivery memoSelectedGroup={orderDetailInfo} />
        </Grid>
      </Panel>
      <>
        <RequiredNote />
        {tempEditMode === false && (
          <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
        )}

        {tempEditMode === true && (
          <PageCustomAction
            button1Name="SUBMIT"
            button1Click={() => onSubmit()}
            button1label={t("translation:SUBMIT")}
            button2Name="BACK"
            button2Click={() => setOrderDetailView(false)}
            button2label={t("translation:BACK")}
          />
        )}
      </>
    </div>
  );
};

export default OrderDetailPage;
