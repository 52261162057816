import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "./apiHeader";
import axios from "axios";

import { history } from "../App";

// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
//
// HOW TO USE:
// import applyAppTokenRefreshInterceptor from 'axios.refresh_token.2.js';
// import axios from 'axios';
// ...
// applyAppTokenRefreshInterceptor(axios); // register the interceptor with all axios instance
// ...
// - Alternatively:
// const apiClient = axios.create({baseUrl: 'example.com/api'});
// applyAppTokenRefreshInterceptor(apiClient); // register the interceptor with one specific axios instance
// ...
// - With custom options:
// applyAppTokenRefreshInterceptor(apiClient, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// ); // register the interceptor with one specific axios instance
//
// PS: You may need to figure out some minor things yourself as this is just a proof of concept and not a tutorial.
// Forgive me in advance

const shouldIntercept = (error) => {
  try {
    return (
      error.response?.status === 401 &&
      error.response?.data.msg === "Token has expired"
    );
  } catch (e) {
    return false;
  }
};

const handleTokenRefresh = () => {
  // return new Promise((resolve, reject) => {
  //   axios
  //     .get(apiConstants.apiGetRefreshToken, headerWithauth)
  //     .then(({ data }) => {
  //       resolve(data);
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });

  history.push("/reSignIn");
};

export const handleResponse = (axiosClient) => {
  let isRefreshing = false;

  const options = {
    handleTokenRefresh,
    shouldIntercept,
  };

  const interceptor = (error) => {
    // if (error.response?.data?.msg === 'Missing cookie "access_token_cookie"') {
    //   history.push("/reSignIn");
    // }

    if (
      error.response?.data?.message === "Organisation changed" ||
      error.response?.data?.message === "公司已更改"
    ) {
      history.push("/OrganisationChanged");
    }

    if (error.response?.status === 403) {
      history.push("/403");
    }

    // if (error.response.status === 504 && error.config.method === "get") {
    //   history.push("/504");
    // }

    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (
      error.config.url === apiConstants.apiGetRefreshToken &&
      error.response.data.msg === "Token has expired"
    ) {
      history.push("/reSignIn");
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        resolve(axiosClient.request(originalRequest));
      }).catch((err) => {
        return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then(() => {
          //   options.handleTokenRefresh.call(options.handleTokenRefresh);

          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};
