//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { withStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";

//action
import {
  postSubmitStoreOrder,
  postSaveStoreOrder,
  getCurrentOrganisation,
  getStorePostalCodeSurchargeList,
  getStoreVerifyAddress,
} from "../../../actions/workOrderActions";

import { getMedicineMeasureUnit } from "../../../actions/commonActions";
//components
import * as generalConstants from "../../../_constants/generalConstants";
import { history } from "../../../App";
import StyledCheckbox from "../../../components/StyledCheckbox";
import Panel from "../../../components/Panel";
import ConfirmDialog from "../../../components/ConfirmDialog";
import MedicineListTable from "./MedicineListTable";
import { UserInfoTable } from "./UserInfoTable";
import OrderDetailPage from "./OrderDetailPage";
import AddBrewing from "./components/addOrder/AddBrewing";
import AddDelivery from "./components/addOrder/AddDelivery";
import Note from "./components/addOrder/Note";
import GroupTotal from "./components/addOrder/GroupTotal";
import PageAction from "../../../components/PageAction";
import GroupMedicineInfo from "./components/addOrder/GroupMedicineInfo";
import GroupOption from "./components/addOrder/GroupOption";
import AllianceTotalPrice from "./components/addOrder/AllianceTotalPrice";
import RequiredNote from "../../../components/RequiredNote";
import submisionJSON from "./components/addOrder/submisionJSON";
import noEnterSubmitForm from "../../../components/functions/noEnterSubmitForm";
import { useStyles } from "../../../components/globalStyles";
import SharedDelivery from "./components/addOrder/SharedDelivery";
import ProceedDay from "./components/addOrder/ProceedDay";

//style
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const AddOrderPage = ({ setStoreListView }) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [saveSuccessAlarm, setSaveSuccessAlarm] = useState(false);
  const [saveErrorAlarm, setSaveErrorAlarm] = useState(false);
  const prevSubmitWorkOrderStatus = useRef();
  const prevSaveWorkOrderStatus = useRef();
  const prevValidateStatus = useRef();

  const [showDelivery, setShowDelivery] = useState(false);
  const [showBrewing, setShowBrewing] = useState(false);
  const [isRawHerb, setIsRawHerb] = useState(false);

  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);

  const [price, setPrice] = useState({
    deliveryFee: 0,
    brewingFee: 0,
    medicineFee: 0,
    medicineTab: 0,
    vendorGstAmt: 0,
    medicineGroupTotal: 0,
  });

  const [desc, setDesc] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [orderDetailInfo, setOrderDetailInfo] = React.useState([]);
  const [orderEditView, setOrderEditView] = React.useState(false);
  const [orderEditInfo, setOrderEditInfo] = React.useState([]);
  const [costingHeader, setCostingHeader] = React.useState([]);
  const [msgAlarm, setMsgAlarm] = useState("");
  const { handleSubmit } = useForm();
  const [tabValue, setTabValues] = useState("0");
  const [group, setGroup] = useState("");

  const [groupOption, setGroupOption] = useState([]);

  const [medicineList, setMedicineList] = useState([]);

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [verifyAddressList, setVerifyAddressList] = useState([]);

  const [isSubmitMode, setIsSubmitMode] = useState(true);

  const [isInformalAddressAlert, setIsInformalAddressAlert] = useState(false);

  const [isAddressChoiceAlert, setIsAddressChoiceAlert] = useState(false);

  const [addressChoiceList, seAddressChoiceList] = useState([]);

  const [chosenAddress, setChosenAddress] = useState(null);

  //redux variable
  const workOrder = useSelector((state) => state.workOrder);
  const userAuth = useSelector((state) => state.userAuth);
  const common = useSelector((state) => state.common);
  const curUser = useSelector((state) => state.curUser);

  const user = userAuth.systemUserInfo;
  //useEffects
  useEffect(() => {
    prevSubmitWorkOrderStatus.current = false;
    prevSaveWorkOrderStatus.current = false;
    prevValidateStatus.current = false;
  }, []);
  useEffect(() => {
    dispatch(getCurrentOrganisation.pending());
    dispatch(getMedicineMeasureUnit.pending());
    dispatch(
      getStorePostalCodeSurchargeList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      curUser.allRightAccess !== null &&
      !curUser.allRightAccess?.user_rights?.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_STORE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    ) {
      history.push("/403");
    }
  }, [curUser.allRightAccess]);

  useEffect(() => {
    if (
      workOrder.isLoadingSubmitStoreOrder !== prevSubmitWorkOrderStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitWorkOrderStatus.current = workOrder.isLoadingSubmitStoreOrder;

      if (
        workOrder.postSubmitStoreOrderIsSuccess === true &&
        workOrder.isLoadingSubmitStoreOrder === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setOrderDetailInfo(workOrder.submitStoreOrder.store_header);
        setOrderDetailView(true);
        window.scrollTo(0, 0);
      }
      if (
        workOrder.postSubmitStoreOrderIsSuccess === false &&
        workOrder.isLoadingSubmitStoreOrder === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(workOrder.error.submitStoreOrderError);
        window.scrollTo(0, 0);
      }
    }
  }, [
    workOrder.postSubmitStoreOrderIsSuccess,
    workOrder.isLoadingSubmitStoreOrder,
    workOrder.error,
    workOrder.submitStoreOrder,
  ]);

  useEffect(() => {
    if (workOrder.isLoadingSaveStoreOrder !== prevSaveWorkOrderStatus.current) {
      //check the previous ref with current state
      prevSaveWorkOrderStatus.current = workOrder.isLoadingSaveStoreOrder;

      if (
        workOrder.postSaveStoreOrderIsSuccess === true &&
        workOrder.isLoadingSaveStoreOrder === false
      ) {
        setSaveSuccessAlarm(true);
        setSaveErrorAlarm(false);
        setOrderEditInfo(workOrder.saveStoreOrder.store_header);
        setOrderEditView(true);
        window.scrollTo(0, 0);
      }
      if (
        workOrder.postSaveStoreOrderIsSuccess === false &&
        workOrder.isLoadingSaveStoreOrder === false
      ) {
        setSaveSuccessAlarm(false);
        setSaveErrorAlarm(true);
        setMsgAlarm(workOrder.error.saveStoreOrderError);
        window.scrollTo(0, 0);
      }
    }
  }, [
    workOrder.postSaveStoreOrderIsSuccess,
    workOrder.isLoadingSaveStoreOrder,
    workOrder.error,
    workOrder.saveStoreOrder,
  ]);

  useEffect(() => {
    if (
      workOrder.isLoadingGetStoreVerifyAddress !== prevValidateStatus.current
    ) {
      //check the previous ref with current state
      prevValidateStatus.current = workOrder.isLoadingGetStoreVerifyAddress;

      if (
        workOrder.getStoreVerifyAddressIsSuccess === true &&
        workOrder.isLoadingGetStoreVerifyAddress === false
      ) {
        const temVerifyAddressList = verifyAddressList.filter(
          (item, index) => index !== 0
        );

        setVerifyAddressList(temVerifyAddressList);

        if (Object.keys(workOrder.getStoreVerifyAddress).length !== 0) {
          if (workOrder.getStoreVerifyAddress[0].postal_code === "NIL") {
            setVerifyAddressList(null);
            setIsSaveLoading(false);
            //error pop up
            setIsInformalAddressAlert(true);
          } else {
            //let user to choose
            setIsAddressChoiceAlert(true);
            const temAddressChoiceList = {
              addressList: workOrder.getStoreVerifyAddress,
              groups: verifyAddressList[0][1],
            };
            seAddressChoiceList(temAddressChoiceList);
            setIsSaveLoading(false);
            setChosenAddress(null);
          }
        } else {
          //cant found any address
          setVerifyAddressList(null);
          setIsSaveLoading(false);
          //error pop up
          setIsInformalAddressAlert(true);
        }
      }
      if (
        workOrder.getStoreVerifyAddressIsSuccess === false &&
        workOrder.isLoadingGetStoreVerifyAddress === false
      ) {
        setIsSaveLoading(false);
        setVerifyAddressList(null);
        //error pop up
        setIsInformalAddressAlert(true);
      }
    }
  }, [
    workOrder.getStoreVerifyAddressIsSuccess,
    workOrder.isLoadingGetStoreVerifyAddress,
    workOrder.error,
    workOrder.getStoreVerifyAddress,
  ]);

  const groupOptionAll = () => {
    return groupOption;
  };

  const memoGroupOption = React.useMemo(groupOptionAll, [groupOption]);

  //function for select group based on group name
  const selectedGroup = () => {
    if (price) {
      return memoGroupOption.find((item) => item.name === group);
    }
  };

  const memoSelectedGroup = React.useMemo(selectedGroup, [
    memoGroupOption,
    group,
    price,
  ]);
  const selectedGroupIndex = () => {
    return memoGroupOption.findIndex((item) => item.name === group);
  };

  const memoSelectedGroupIndex = React.useMemo(selectedGroupIndex, [
    memoGroupOption,
    group,
  ]);

  //functions

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const handleMedicineGroupInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value ? event.target.value : null,
    };

    setGroupOption(newArray);
  };

  const handleCheckIncluded = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.checked,
    };

    setGroupOption(newArray);
  };

  const handleMedicineGroupNumInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setGroupOption(newArray);
  };
  const setview = () => {
    setStoreListView(true);
    setOrderDetailView(false);
    setOrderEditView(false);
  };

  //set table data
  const setMedicineData = (rowIndex, columnID, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let oldArray = [...memoGroupOption];
    let storeMedicineDetail = oldArray[elementsIndex].store_medicine_details;
    if (columnID === "delete") {
      let result = storeMedicineDetail.filter((e, index) => index !== rowIndex);

      result = result.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: result,
      };
      setGroupOption(oldArray);
    } else if (columnID === "up") {
      let result = [...storeMedicineDetail];
      const newAddMedicineVerified = storeMedicineDetail.findIndex(
        (e, index) => index === rowIndex && e.id === 0
      );

      [result[newAddMedicineVerified], result[newAddMedicineVerified - 1]] = [
        result[newAddMedicineVerified - 1],
        result[newAddMedicineVerified],
      ];

      result = result.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: result,
      };
      setGroupOption(oldArray);
    } else if (columnID === "down") {
      let result = [...storeMedicineDetail];
      const newAddMedicineVerified = storeMedicineDetail.findIndex(
        (e, index) => index === rowIndex && e.id === 0
      );

      [result[newAddMedicineVerified], result[newAddMedicineVerified + 1]] = [
        result[newAddMedicineVerified + 1],
        result[newAddMedicineVerified],
      ];

      result = result.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: result,
      };
      setGroupOption(oldArray);
    } else {
      const selectedstoreMedicine = storeMedicineDetail.map(
        (medicine, index) => {
          if (index === rowIndex && columnID !== "delete") {
            return {
              ...medicine,
              [columnID]: value,
            };
          }
          return medicine;
        }
      );

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: selectedstoreMedicine,
      };

      setGroupOption(oldArray);
    }
  };

  const onSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (workOrder.currentOrganistion) {
      const invalidAddress = groupOption.filter(
        (item) =>
          item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
          item.is_delivery_services === true &&
          item.delivery_road_name == null
      );

      if (invalidAddress.length) {
        const result = Object.groupBy(
          invalidAddress,
          ({ delivery_address }) => delivery_address
        );

        const addressKeys = Object.entries(result);
        setVerifyAddressList(addressKeys);
        setIsSubmitMode(true);
        setIsSaveLoading(true);
        dispatch(
          getStoreVerifyAddress.pending({
            address: addressKeys[0][0] !== "null" ? addressKeys[0][0] : null,
          })
        );
      } else {
        dispatch(
          postSubmitStoreOrder.pending(
            submisionJSON(
              user,
              workOrder.currentOrganistion.organisation,
              price,
              groupOption,
              costingHeader,
              desc
            )
          )
        );
      }
    } else {
      setMsgAlarm(t("translation:Missing Organisation"));
      setSubmitErrorAlarm(true);
    }
  };

  const onSave = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();

    if (checkVal) {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });

      if (workOrder.currentOrganistion) {
        const invalidAddress = groupOption.filter(
          (item) =>
            item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
            item.is_delivery_services === true &&
            item.delivery_road_name == null
        );

        if (invalidAddress.length) {
          const result = Object.groupBy(
            invalidAddress,
            ({ delivery_address }) => delivery_address
          );

          const addressKeys = Object.entries(result);
          setVerifyAddressList(addressKeys);
          setIsSubmitMode(false);
          setIsSaveLoading(true);
          dispatch(
            getStoreVerifyAddress.pending({
              address: addressKeys[0][0] !== "null" ? addressKeys[0][0] : null,
            })
          );
        } else {
          dispatch(
            postSaveStoreOrder.pending(
              submisionJSON(
                user,
                workOrder.currentOrganistion.organisation,
                price,
                groupOption,
                costingHeader,
                desc
              )
            )
          );
        }
      } else {
        setMsgAlarm(t("translation:Missing Organisation"));
        setSaveErrorAlarm(true);
      }
    }
  };

  const onSubmitWInformalAddr = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (isSubmitMode) {
      dispatch(
        postSubmitStoreOrder.pending(
          submisionJSON(
            user,
            workOrder.currentOrganistion.organisation,
            price,
            groupOption,
            costingHeader,
            desc
          )
        )
      );
    } else {
      dispatch(
        postSaveStoreOrder.pending(
          submisionJSON(
            user,
            workOrder.currentOrganistion.organisation,
            price,
            groupOption,
            costingHeader,
            desc
          )
        )
      );
    }
  };

  const onConfirmSubmit = (event, action) => {
    setConfirmSubmitOpen(true);
  };

  const onComfirmAddress = () => {
    let newArray = [...memoGroupOption];
    addressChoiceList.groups.map((item) => {
      const elementsIndex = memoGroupOption.findIndex(
        (groupItem) => groupItem.id === item.id && groupItem.name === item.name
      );
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        delivery_address: chosenAddress.address,
        delivery_blk_no: chosenAddress.blk_no,
        delivery_road_name: chosenAddress.road_name,
        delivery_building: chosenAddress.building,
        delivery_postal_code: chosenAddress.postal_code,
      };
    });

    setGroupOption(newArray);
  };

  if (!orderDetailView && !orderEditView) {
    return (
      <div>
        <Collapse in={saveErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSaveErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <br />

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={isInformalAddressAlert}
          setOpen={setIsInformalAddressAlert}
          onConfirm={onSubmitWInformalAddr}>
          {t("translation:Found an informal address")}.
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmSubmitOpen}
          setOpen={setConfirmSubmitOpen}
          onConfirm={onSubmit}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={isAddressChoiceAlert}
          setOpen={setIsAddressChoiceAlert}
          onConfirm={onComfirmAddress}>
          {t("translation:Please choose the delivery address")}.
          {addressChoiceList !== null && (
            <Grid item xs={12} className={classes.subTotalInfoField}>
              <Autocomplete
                name="Address"
                disableClearable
                required
                options={addressChoiceList?.addressList}
                getOptionLabel={(option) =>
                  option.address ? option.address : ""
                }
                value={chosenAddress}
                isOptionEqualToValue={(option, value) => {
                  if (option.address === value.address) return option;
                }}
                onChange={(e, option) => {
                  setChosenAddress(option);
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Address")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
        </ConfirmDialog>
        <UserInfoTable
          name={user.name}
          staff_contact_number={user.contact_number}
          staff_address={user.address}
          t={t}
        />

        <form
          onSubmit={handleSubmit(onConfirmSubmit)}
          onKeyDown={(e) => noEnterSubmitForm(e)}>
          <br />
          <Panel>
            <Grid container>
              <Grid xs={12} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={desc}
                  cy_data="storeOrderDescription"
                  name="order description"
                  label={t("translation:Order Description")}
                  variant="outlined"
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Grid>
            </Grid>
          </Panel>

          <Grid className={classes.gridRoot} container>
            <TabContext value={tabValue}>
              <Grid item xs={12}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth">
                  <StyledTab
                    className={classes.defualtCursor}
                    value="0"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:MEDICINE")}
                        </Typography>

                        <Typography
                          cy_data="medicineTabPrice"
                          display="block"
                          variant="subtitle1">
                          $ {price.medicineTab}
                        </Typography>
                      </span>
                    }></StyledTab>
                </StyledTabs>
              </Grid>

              <TabPanel className={classes.gridRoot} value="0">
                <Panel>
                  <GroupOption
                    memoGroupOption={memoGroupOption}
                    groupOption={groupOption}
                    setGroupOption={setGroupOption}
                    group={group}
                    setGroup={setGroup}
                    memoSelectedGroupIndex={memoSelectedGroupIndex}
                    medicineList={medicineList}
                    setMedicineList={setMedicineList}
                    memoSelectedGroup={memoSelectedGroup}
                    setShowBrewing={setShowBrewing}
                    setShowDelivery={setShowDelivery}
                    setIsRawHerb={setIsRawHerb}
                  />
                </Panel>

                {Object.keys(medicineList).length !== 0 &&
                  workOrder.currentOrganistion &&
                  workOrder.dispensaryBranchesAlliance &&
                  common.medicineMeasureUnitList &&
                  curUser.organisationInfo &&
                  Object.keys(
                    workOrder.dispensaryBranchesAlliance.organisation_branch
                  ).length !== 0 && (
                    <div>
                      <Panel>
                        <Grid container>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <MedicineListTable
                              t={t}
                              currentOrganistionBranch={
                                memoSelectedGroup.organisation_branch
                              }
                              number_of_days={memoSelectedGroup.number_of_days}
                              data={medicineList.sort(
                                (a, b) => a.row_no - b.row_no
                              )}
                              order={workOrder}
                              isRawHerb={isRawHerb}
                              setData={setMedicineData}
                              measureUnit={
                                common.medicineMeasureUnitList.measure_units
                              }
                              storeOrderMode={true}
                              userOrganisations={
                                curUser.organisationInfo.user_organisations
                              }
                              infoModule="STORE"
                            />
                          </Grid>

                          <GroupMedicineInfo
                            memoGroupOption={memoGroupOption}
                            handleMedicineGroupNumInfo={
                              handleMedicineGroupNumInfo
                            }
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            setGroupOption={setGroupOption}
                            module={"STORE"}
                          />
                        </Grid>
                        <GroupTotal
                          price={price}
                          memoSelectedGroup={memoSelectedGroup}
                          handleMedicineGroupNumInfo={
                            handleMedicineGroupNumInfo
                          }
                        />
                      </Panel>

                      {(showBrewing === true ||
                        showDelivery === true ||
                        memoSelectedGroup.delivery_incl_prescription === true ||
                        memoSelectedGroup.delivery_incl_receipt === true) && (
                        <Panel cy_data="deliveryBrewingComponent">
                          <Grid item xs={12} className={classes.userInfoField}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={
                                    memoSelectedGroup.delivery_incl_prescription
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckIncluded}
                                  name="delivery_incl_prescription"
                                  cy_data="deliveryInclPrescription"
                                />
                              }
                              label={t(
                                "translation:Delivery Include Prescription"
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={
                                    memoSelectedGroup.delivery_incl_receipt
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckIncluded}
                                  name="delivery_incl_receipt"
                                  cy_data="deliveryInclInvoice"
                                />
                              }
                              label={t("translation:Delivery Include Invoice")}
                            />
                          </Grid>

                          <AddBrewing
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            memoGroupOption={memoGroupOption}
                            groupOption={groupOption}
                            setGroupOption={setGroupOption}
                            showBrewing={showBrewing}
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                          />
                          <AddDelivery
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            memoGroupOption={memoGroupOption}
                            groupOption={groupOption}
                            setGroupOption={setGroupOption}
                            user={user}
                            showDelivery={showDelivery}
                            price={price}
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            module="STORE"
                            confirmSubmitOpen={confirmSubmitOpen}
                          />

                          <SharedDelivery
                            memoSelectedGroup={memoSelectedGroup}
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoGroupOption={memoGroupOption}
                            setGroupOption={setGroupOption}
                            module="workOrder"
                          />
                        </Panel>
                      )}
                    </div>
                  )}

                {memoSelectedGroup && (
                  <ProceedDay
                    memoSelectedGroup={memoSelectedGroup}
                    memoGroupOption={memoGroupOption}
                    setGroupOption={setGroupOption}
                    memoSelectedGroupIndex={memoSelectedGroupIndex}
                    module="STORE"
                  />
                )}

                {workOrder.currentOrganistion &&
                  curUser.organisationInfo &&
                  workOrder?.getStorePostalCodeSurchargeList && (
                    <AllianceTotalPrice
                      medicineList={medicineList}
                      memoGroupOption={memoGroupOption}
                      group={group}
                      setGroupOption={setGroupOption}
                      price={price}
                      setPrice={setPrice}
                      memoSelectedGroupIndex={memoSelectedGroupIndex}
                      memoSelectedGroup={memoSelectedGroup}
                      costingHeader={costingHeader}
                      setCostingHeader={setCostingHeader}
                      userOrganisations={
                        curUser.organisationInfo.user_organisations
                      }
                      storeOrderMode={true}
                    />
                  )}
              </TabPanel>

              <Note
                groupPrice={true}
                prescriptionTimes={true}
                noOfPacks={true}
              />
              <RequiredNote />
              <PageAction
                onSave={onSave}
                isSaveLoading={isSaveLoading}
                submissionTitle={t("translation:Submit to Dispensary Branch")}
                setView={setStoreListView}
              />
            </TabContext>
          </Grid>
        </form>
      </div>
    );
  } else if (orderDetailInfo && orderDetailView) {
    return (
      <div>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            onClick={() => {
              setSubmitSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSubmitOrder">
            {t("translation:Order has been submitted")}
          </Alert>
        </Collapse>
        {workOrder.submitStoreOrder && (
          <OrderDetailPage
            orderDetailInfo={orderDetailInfo}
            setOrderDetailInfo={setOrderDetailInfo}
            setOrderDetailView={setview}
          />
        )}
      </div>
    );
  } else if (orderEditInfo && orderEditView) {
    return (
      <div>
        <Collapse in={saveSuccessAlarm}>
          <Alert
            onClick={() => {
              setSaveSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSaveOrder">
            {t("translation:Order has been saved")}
          </Alert>
        </Collapse>
        {workOrder.saveStoreOrder && (
          <OrderDetailPage
            orderDetailInfo={orderEditInfo}
            setOrderDetailInfo={setOrderEditInfo}
            setOrderDetailView={setview}
          />
        )}
      </div>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
};

export default AddOrderPage;
