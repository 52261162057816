const apiConstants = {
  apiDomain: "",
  /*user List*/
  apiGetUserList: "/api/v1/admin/user/search",
  apiGetAllUserList: "/api/v1/admin/user/index",
  apiGetUserById: "/api/v1/admin/user/view/",
  apiPutEditUserById: "/api/v1/admin/user/update/",
  apiPostCreateUser: "/api/v1/admin/user/create",
  apiPutPhysicianLicenseNoById:
    "/api/v1/admin/user/update_physician_license_no/",
  apiPostFailedLoginCount: "/api/v1/admin/user/set_user_failed_login_count",
  apiGetLoginAttemptMax: "/api/v1/admin/user/login_attempt_max",

  /*user Auth*/
  apiGetRefreshToken: "/api/v1/auth/token_refresh",
  apiRegistration: "/api/v1/auth/register",
  apiPostSelfRegistration: "/api/v1/auth/self_register",
  apiPostUserLogIn: "/api/v2/auth/login",
  apiPostUserLogOut: "/api/v1/auth/logout",
  apiPostUserResetPasswordRq: "/api/v1/auth/reset_password_request",
  apiPostUserResetPassword: "/api/v1/auth/reset_password/",
  apiPutUserChangePassword: "/api/v1/auth/user_profile_password_change",
  apiPostVerifyEmailRq: "/api/v1/auth/verify_email_request",
  apiPostVerifyEmail: "/api/v1/auth/verify_email/",
  apiGetProviderAuthorize: "/api/v2/auth/authorize/",
  apiPostProviderAccessToken: "/api/v2/auth/provider_access_token/",
  apiPostOtpCallback: "/api/v2/auth/otp_callback",

  /*current user*/
  //get defualt organisation and default branch
  apiGetOrganisationInfo:
    "/api/v1/admin/current_user/view_default_organisation",
  apiGetOrganisationBranchList:
    "/api/v1/admin/current_user/view_organisation_branch",
  apiGetOrganisationBranchListById:
    "/api/v1/admin/current_user/view_organisation_branch/",
  apiPutOrganisationInfo:
    "/api/v1/admin/current_user/update_default_organisation",
  apiPutUserInfo: "/api/v1/admin/current_user/update_user_profile",

  apiGetDefaultBranch: "/api/v1/admin/current_user/view_user_default_branch",

  apiPostTopUpCredit: "/api/v1/admin/current_user/top_up_credit",

  apiGetCreditBalance: "/api/v1/admin/current_user/view_credit_balance",

  apiGetAllAllianceOrganisation:
    "/api/v1/admin/current_user/view_all_alliance_organisation",

  apiGetDefaultAlliance: "/api/v1/admin/current_user/view_current_alliance",

  apiGetAllOrganisationStripe:
    "/api/v1/admin/current_user/view_all_organisation_stripe",

  apiGetOrganisationLogo: "/api/v1/admin/current_user/organisation_logo/",

  //update deafult language or can be done by apiPutUserInfo
  apiPutUserLang: "/api/v1/admin/current_user/update_user_language",
  apiGetCurRoleRight: "/api/v1/admin/current_user/view_all_role_rights",
  apiGetAllRightAccess: "/api/v1/admin/current_user/view_all_rights_access",
  apiGetOrganisation: "/api/v1/admin/current_user/organisation/",
  apiGetCurrentUserUserCreditList:
    "/api/v1/admin/current_user/search_user_credits",

  /*user role*/
  apiGetUserRoleList: "/api/v1/admin/user_role/search_by_user",
  apiGetUserRole: "/api/v1/admin/user_role/view/user/",
  apiPutUserRole: "/api/v1/admin/user_role/update_by_user_id",
  apiGetUserRoleAllRole: "/api/v1/admin/user_role/select_field/all_roles",
  /*role*/
  apiGetAllRoleList: "/api/v1/admin/role/index",
  apiGetRoleList: "/api/v1/admin/role/search",
  apiGetRoleById: "/api/v1/admin/role/view/",
  apiPostCreateRole: "/api/v1/admin/role/create",
  apiPutUpdateRole: "/api/v1/admin/role/update",

  /*role-right*/
  apiGetRoleRight: "/api/v1/admin/role_rights/view/role/",
  apiPutRoleRight: "/api/v1/admin/role_rights/update_by_role_id",
  apiGetRoleRightList: "/api/v1/admin/role_rights/search_by_role",
  /*common info*/
  apiGetRace: "/api/v1/common/user/race_select_field",
  apiGetNationality: "/api/v1/common/user/nationality_select_field",
  apiGetGender: "/api/v1/common/user/gender_select_field",
  apiGetMarital: "/api/v1/common/user/marital_select_field",
  //apiGetRight: "/api/v1/common/user/rights_select_field", change to
  apiGetRight: "/api/v1/admin/current_user/rights_select_field",
  apiGetPermission: "/api/v1/common/user/permission_select_field",
  apiGetViewNeeds: "/api/v1/common/user/view_needs_select_field",
  apiGetLanguage: "/api/v1/common/user/language_select_field",
  apiGetMedicineConsumeTime: "/api/v1/common/user/when_consume_select_field",
  apiGetMedicineMeasureUnit: "/api/v1/common/user/measure_units_select_field",
  apiGetGeneralStatus: "/api/v1/common/user/status_general_select_field",
  apiGetWaterAmount: "/api/v1/common/user/water_amount_select_field",
  apiGetPaymentTerm: "/api/v1/common/payment_term/select_field",
  apiGetPaymentMethod: "/api/v1/common/payment_method/select_field",
  apiGetUserCreditType: "/api/v1/common/user/user_credit_type_select_field",
  apiGetInOutType: "/api/v1/common/user/in_out_type_select_field",
  apiGetSystemSettings: "/api/v1/common/user/view_system_settings",
  apiGetMfaType: "/api/v1/common/user/mfa_type_select_field",
  apiGetWorkOrderType: "/api/v1/common/user/work_order_type_select_field",

  /*store order*/
  // apiGetDispensaryBranches:
  //   "/api/v1/work_order/store/select_field/dispensary_branches",
  apiGetMedicineTypes: "/api/v1/work_order/store/select_field/medicine_types",
  apiGetDispensaryBranchesAlliance:
    "/api/v2/work_order/store/select_field/dispensary_branches_incl_alliance",
  apiGetMedicineTypesAlliance:
    "/api/v2/work_order/store/select_field/medicine_types_incl_alliance/",
  apiPostFormulasAlliance:
    "/api/v2/work_order/store/select_field/formulas_incl_alliance",
  apiPostMedicineFormulasAlliance:
    "/api/v2/work_order/store/select_field/medicines_in_formula/",
  apiPostBranchMedicines:
    "/api/v2/work_order/store/select_field/branch_medicines",
  apiGetCurrentOrganisation: "/api/v1/work_order/store/current_organisation",
  apiPostSaveStoreOrder: "/api/v1/work_order/store/save_store_header",
  apiPostSubmitStoreOrder: "/api/v1/work_order/store/submit_store_header",

  apiGetStoreOrderById: "/api/v1/work_order/store/view/",

  apiGetStoreOrderList: "/api/v1/work_order/store/search",
  apiGetStoreOrderSummaryList: "/api/v1/work_order/store/search_store_summary",

  apiPutStoreEditMode: "/api/v1/work_order/store/set_store_edit_mode/",

  apiPutStorePrintDate: "/api/v1/work_order/store/set_store_print_on",

  apiGetStorePrescriptionMsgList:
    "/api/v1/work_order/store/select_field/prescription_messages",

  apiGetStoreMedicineInfo:
    "/api/v1/work_order/store/select_field/medicine_info",

  apiGetStorePostalCodeSurchargeList:
    "/api/v1/work_order/store/postal_code_surcharge/search",

  apiGetStoreAvailableProceedOn:
    "/api/v1/work_order/store/search_available_proceed_on",

  apiGetStoreVerifyAddress: "/api/v1/work_order/store/verify_address",

  /*dispensary*/
  apiPutStoreOrderStatusById:
    "/api/v1/work_order/dispensary/set_store_wip_mode/",
  apiPutDispensaryWIPMode:
    "/api/v1/work_order/dispensary/set_dispensary_wip_mode/",
  apiPutDispensaryPendingStatus:
    "/api/v1/work_order/dispensary/set_dispensary_pending_status/",

  apiGetDispensaryList: "/api/v1/work_order/dispensary/search_dwo",

  apiGetDispensarySummaryList:
    "/api/v1/work_order/dispensary/search_dwo_summary",

  apiGetDispensaryById: "/api/v1/work_order/dispensary/view/",

  apiPostSubmitDispensary:
    "/api/v1/work_order/dispensary/submit_dispensary_header",

  apiPutDispensaryPrintDate:
    "/api/v1/work_order/dispensary/set_dispensary_print_on",

  apiPutDispensaryVisitPrintDate:
    "/api/v1/work_order/dispensary/set_visit_print_on",

  apiPutDispensaryStorePrintDate:
    "/api/v1/work_order/dispensary/set_store_print_on",

  apiGetDispensaryInvoiceOrder:
    "/api/v1/work_order/dispensary/get_invoice_order",

  apiPutDispensaryInvoicePrintDate:
    "/api/v1/work_order/dispensary/set_invoice_print_on",

  apiPutDispensaryStoreProceedOn:
    "/api/v1/work_order/dispensary/set_store_medicine_header_proceed_on",

  /*brewing*/

  apiPutBrewingWIPMode: "/api/v1/work_order/brewing/set_brewing_wip_mode/",

  apiPutBrewingPendingStatus:
    "/api/v1/work_order/brewing/set_brewing_pending_status/",

  apiGetBrewingList: "/api/v1/work_order/brewing/search_bwo",

  apiGetBrewingSummaryList: "/api/v1/work_order/brewing/search_bwo_summary",

  apiGetBrewingById: "/api/v1/work_order/brewing/view/",

  apiGetBrewingByOrderNum: "/api/v1/work_order/brewing/order_number/",

  apiPostSubmitBrewing: "/api/v1/work_order/brewing/submit_brewing_header",

  /*visit work order*/
  apiGetVisitStoreOrderList: "/api/v1/work_order/visit/search",
  apiGetVisitStoreOrderSummaryList:
    "/api/v1/work_order/visit/search_visit_summary",
  apiGetVisitStoreOrderById: "/api/v1/work_order/visit/view/",
  apiGetPatientVisitStoreOrderList:
    "/api/v2/work_order/visit/view_visit_order_history_summary",
  apiGetSearchPatientVisitStoreOrder:
    "/api/v2/work_order/visit/search_visit_order_history",
  // apiGetVisitDispensaryBranches:
  //   "/api/v1/work_order/visit/select_field/dispensary_branches",
  apiGetVisitMedicineTypes:
    "/api/v1/work_order/visit/select_field/medicine_types",
  apiGetVisitDispensaryBranchesAlliance:
    "/api/v2/work_order/visit/select_field/dispensary_branches_incl_alliance",
  apiGetVisitMedicineTypesAlliance:
    "/api/v2/work_order/visit/select_field/medicine_types_incl_alliance/",
  apiPostVisitFormulasAlliance:
    "/api/v2/work_order/visit/select_field/formulas_incl_alliance",
  apiPostVisitMedicineFormulasAlliance:
    "/api/v2/work_order/visit/select_field/medicines_in_formula/",
  apiPostVisitBranchMedicines:
    "/api/v2/work_order/visit/select_field/branch_medicines",
  apiGetVisitCurrentOrganisation:
    "/api/v1/work_order/visit/current_organisation",
  apiPostVisitSaveStoreOrder: "/api/v1/work_order/visit/save_store_header",
  apiPostVisitSubmitStoreOrder: "/api/v1/work_order/visit/submit_store_header",
  apiPutVisitStoreEditMode: "/api/v1/work_order/visit/set_store_edit_mode/",
  apiGetVisitAcupoints: "/api/v1/work_order/visit/select_field/acupoints",
  apiGetVisitTreatmentTypes:
    "/api/v1/work_order/visit/select_field/treatment_types",
  apiPostVisitTreatments: "/api/v1/work_order/visit/select_field/treatments",
  apiGetVisitPaymentMethods:
    "/api/v1/work_order/visit/select_field/payment_methods",
  apiGetVisitPaymentTerms:
    "/api/v1/work_order/visit/select_field/payment_terms",
  apiPostVisitEmptyVisitOrder:
    "/api/v1/work_order/visit/select_field/create_empty_visit_order",
  //this is physician list using  organisation_physician table
  apiPostVisitPhysicians: "/api/v1/work_order/visit/search_physicians",
  //this is physician list using user table
  apiGetVisitPhysicians: "/api/v1/work_order/visit/select_field/physicians",
  apiGetVisitUserList: "/api/v1/work_order/visit/select_field/users/search",
  apiGetVisitUserAdvancedSearch:
    "/api/v1/work_order/visit/select_field/users/search",
  apiPostVisitCreateUser: "/api/v1/work_order/visit/create_user",
  apiPutVisitUpdateUser: "/api/v1/work_order/visit/update_user/",
  apiGetVisitIsUserCurrentOrganisation:
    "/api/v1/work_order/visit/is_user_in_current_organisation/",
  apiPutVisitUpdateUserOrganisation:
    "/api/v1/work_order/visit/update_user_organisation/",
  apiPutVisitSetArrivalOn: "/api/v2/work_order/visit/set_arrival_on",
  apiPutVisitSetDepartureOn: "/api/v2/work_order/visit/set_departure_on",
  apiPutVisitSetConsultOn: "/api/v2/work_order/visit/set_consult_on",
  apiPutVisitSetQueueNumber: "/api/v2/work_order/visit/set_queue_number",
  apiPutVisitUpdateVisitNotes: "/api/v1/work_order/visit/update_visit_notes",

  apiPutVisitPrintDate: "/api/v1/work_order/visit/set_visit_print_on",

  apiPutUpdateUserMedicalHistory:
    "/api/v1/work_order/visit/update_user_medical_history",

  apiGetVisitPrescriptionMsgList:
    "/api/v1/work_order/visit/select_field/prescription_messages",

  apiGetVisitMedicineInfo:
    "/api/v1/work_order/visit/select_field/medicine_info",

  apiGetVisitAcupointInfo:
    "/api/v1/work_order/visit/select_field/acupoint_info",

  apiGetVisitTreatmentInfo:
    "/api/v1/work_order/visit/select_field/treatment_info",

  apiPostVisitChangeBranch: "/api/v1/work_order/visit/change_branch",

  apiPutUpdateVisitAcupoints: "/api/v1/work_order/visit/update_visit_acupoints",

  apiGetVisitPostalCodeSurchargeList:
    "/api/v1/work_order/visit/postal_code_surcharge/search",

  apiGetVisitAvailableProceedOn:
    "/api/v1/work_order/visit/search_available_proceed_on",

  apiGetVisitVerifyAddress: "/api/v1/work_order/visit/verify_address",
  /*Delivery*/

  apiGetDeliveryList: "/api/v1/work_order/delivery/search_dowo",
  apiPostSubmitDelivery: "/api/v1/work_order/delivery/submit_delivery_header",
  apiPutDeliveryPrintDate: "/api/v1/work_order/delivery/set_delivery_print_on",
  apiGetDeliveryDistrictDetailsList:
    "/api/v1/work_order/delivery/search_dowo_district_details",
  apiGetExportDeliveryDistrictDetailsList:
    "/api/v1/work_order/delivery/export_dowo_district_details",
  apiGetDeliveryDistrictDetailsSummaryList:
    "/api/v1/work_order/delivery/search_dowo_district_summary",
  apiGetExportDeliveryDistrictDetailsSummaryList:
    "/api/v1/work_order/delivery/export_dowo_district_summary",
  apiGetDeliveryPostalcodeDistrictList:
    "/api/v1/work_order/delivery/search_postal_code_district",

  /*Delivery Order*/
  apiGetDeliveryOrderSummaryList:
    "/api/v1/work_order/delivery_order/search_do_summary",
  apiGetDeliveryOrderById: "/api/v1/work_order/delivery_order/view/",
  apiGetDeliveryOrderList: "/api/v1/work_order/delivery_order/search_do",
  apiPostSaveDeliveryOrder:
    "/api/v1/work_order/delivery_order/save_delivery_order_header",
  apiPostSubmitDeliveryOrder:
    "/api/v1/work_order/delivery_order/submit_delivery_order_header",
  apiPostSaveDeliveryOrderWCheck:
    "/api/v1/work_order/delivery_order/save_delivery_order_header_with_address_check",
  apiPostSubmitDeliveryOrderWCheck:
    "/api/v1/work_order/delivery_order/submit_delivery_order_header_with_address_check",
  apiPostSubmitAllDelivery:
    "/api/v1/work_order/delivery_order/submit_all_delivery_headers",
  apiPutDeliveryOrderWIPMode:
    "/api/v1/work_order/delivery_order/set_delivery_order_wip_mode",

  apiPutDeliveryOrderEditMode:
    "/api/v1/work_order/delivery_order/set_delivery_order_edit_mode/",
  apiPutDeliveryOrderPendingMode:
    "/api/v1/work_order/delivery_order/set_delivery_order_pending_status/",
  apiGetDeliveryCourierList:
    "/api/v1/work_order/delivery_order/select_field/couriers",

  apiGetDeliveryCourierByBranch:
    "/api/v1/work_order/delivery_order/search_courier_by_branch",

  apiGetDeliveryOrderByStoreHeaderId:
    "/api/v1/work_order/delivery_order/get_delivery_orders_by_store_header_id/",

  apiGetStoreHeaderList: "/api/v1/work_order/delivery_order/view/store_orders",

  apiGetStoreHeaderSummaryList:
    "/api/v1/work_order/delivery_order/view/store_orders_summary",

  apiPutDeliveryOrderPrintDate:
    "/api/v1/work_order/delivery_order/set_delivery_order_print_on",

  apiPutDeliveryOrderUpdateStoreDeliveryAddress:
    "/api/v1/work_order/delivery_order/update_store_delivery_address",

  apiPostExportDeliveryOrders:
    "/api/v1/work_order/delivery_order/export_delivery_orders",

  apiPostExportDeliveryOrdersV2:
    "/api/v2/work_order/delivery_order/export_delivery_orders",

  apiGetRoutingServicePayload:
    "/api/v1/work_order/delivery_order/get_routing_service_payload",

  apiGetGeometryValuePayload:
    "/api/v1/work_order/delivery_order/get_geometry_value_payload",

  apiGetRouteByGridPayload:
    "/api/v1/work_order/delivery_order/get_route_by_grid_payload",

  apiGetAreaClusterPayload:
    "/api/v1/work_order/delivery_order/get_area_cluster_payload",

  apiGetOnemapStaticMapPayload:
    "/api/v1/work_order/delivery_order/get_onemap_static_map_payload",

  apiGetDeliveryOrderPostalCodes:
    "/api/v1/work_order/delivery_order/get_delivery_order_postal_codes",

  apiGetGoogleStaticMapPayload:
    "/api/v1/work_order/delivery_order/get_b64_google_static_map",

  apiGetDeliveryOptimizedRouting:
    "/api/v1/work_order/delivery_order/get_delivery_optimized_routing",

  apiGetDeliveryOptimizedRoutingMap:
    "/api/v1/work_order/delivery_order/get_delivery_optimized_routing_map",

  apiGetDeliveryVerifyAddress:
    "/api/v1/work_order/delivery_order/verify_address",

  apiPutUpdateAllStoreDeliveryAddress:
    "/api/v1/work_order/delivery_order/update_all_store_delivery_address",

  apiPostSearchCsvCourierDeliveryAddress:
    "/api/v1/work_order/delivery_order/export_csv_courier_delivery_address",

  apiPostSearchCourierDeliveryAddress:
    "/api/v1/work_order/delivery_order/search_courier_delivery_address",

  apiPutUpdateDeliveryTrackingRowNo:
    "/api/v1/work_order/delivery_order/update_delivery_tracking_row_no",

  apiGetDeliveryOrderVerifyAddressList:
    "/api/v1/work_order/delivery_order/verify_addresses",

  /*medicine type*/
  apiGetMedicineTypeList: "/api/v1/admin/medicine_type/search",
  apiGetMedicineType: "/api/v1/admin/medicine_type/view/",
  apiPutMedicineType: "/api/v1/admin/medicine_type/update",
  apiCreateMedicineType: "/api/v1/admin/medicine_type/create",

  /*
  index,search, select_field/all_users, /view/?id, view/user/?id, /search_by_user, /update_by_user_id

  //medicine type
  index, search, /view/id?, view/name? , /create/ , update, 
  */
  /*Appointment*/

  apiGetAppointmentList: "/api/v1/work_order/appointment/search_appointments",

  apiPostSubmitAppointment:
    "/api/v1/work_order/appointment/submit_physician_appointment",

  apiPostAppointmentPhysicians:
    "/api/v1/work_order/appointment/select_field/physicians_v2",

  apiGetAppointmentBranch:
    "/api/v1/work_order/appointment/select_field/branches",

  apiGetAppointmentTimeslotList:
    "/api/v1/work_order/appointment/get_appointment_timeslot",

  apiGetAppointmentBranchPhysician:
    "/api/v1/work_order/appointment/get_branch_physicians/",

  apiGetAppointmentUserList: "/api/v1/work_order/appointment/users/search",

  apiGetAppointmentTempUserList:
    "/api/v1/work_order/appointment/temp_users/search",

  /*Dashboard*/

  apiGetDashboardAppointmentList:
    "/api/v1/work_order/dashboard/search_appointments",

  apiGetDashboardBrewingList: "/api/v1/work_order/dashboard/search_bwo",

  apiGetDashboardDeliveryOrderList: "/api/v1/work_order/dashboard/search_do",

  apiGetDashboardDispensaryList: "/api/v1/work_order/dashboard/search_dwo",

  apiGetDashboardInvoiceList: "/api/v1/work_order/dashboard/search_iwo",

  apiGetDashboardInvoiceMedicineCostingList:
    "/api/v1/work_order/dashboard/search_imc",

  apiGetDashboardStoreOrderList: "/api/v1/work_order/dashboard/search_store",

  apiGetDashboardVisitOrderList: "/api/v1/work_order/dashboard/search_visit",

  apiGetDashboardAllApprovedLocalDispensary:
    "/api/v1/work_order/dashboard/get_all_visit_approved_local_dispensary_headers",

  apiGetDashboardAllStoreWDispensaryList:
    "/api/v1/work_order/dashboard/search_store_header_with_local_dispensary",

  apiGetDashboardCounterView: "/api/v2/work_order/dashboard/get_counter_view",

  /*branch schedule*/

  apiGetBranchScheduleList: "/api/v1/admin/branch_schedule/search",

  apiGetBranchScheduleById: "/api/v1/admin/branch_schedule/view/",

  apiPostBranchScheduleByBranchId: "/api/v1/admin/branch_schedule/update/",

  apiGetBranchScheduleBranchList:
    "/api/v1/admin/branch_schedule/search_branches",

  /*branch adhoc schedule*/

  apiGetBranchAdhocScheduleList: "/api/v1/admin/branch_adhoc_schedule/search",

  apiGetBranchAdhocScheduleById: "/api/v1/admin/branch_adhoc_schedule/view/",

  apiPostBranchAdhocScheduleByBranchId:
    "/api/v1/admin/branch_adhoc_schedule/update/",

  apiGetBranchAdhocScheduleBranchList:
    "/api/v1/admin/branch_adhoc_schedule/search_branches",

  /*branch physician schedule*/

  apiGetBranchPhysicianScheduleList:
    "/api/v1/admin/branch_physician_schedule/search",

  apiGetBranchPhysicianScheduleById:
    "/api/v1/admin/branch_physician_schedule/view/",

  apiPostBranchPhysicianScheduleByBranchIdPhysicianId:
    "/api/v1/admin/branch_physician_schedule/update/",

  apiGetBranchPhysicianScheduleBranchList:
    "/api/v1/admin/branch_physician_schedule/search_branches",

  apiGetBranchPhysicianSchedulePhysicianList:
    "/api/v1/admin/branch_physician_schedule/search_physicians",

  /*branch physician adhoc schedule*/

  apiGetBranchPhysicianAdhocScheduleList:
    "/api/v1/admin/branch_physician_adhoc_schedule/search",

  apiGetBranchPhysicianAdhocScheduleById:
    "/api/v1/admin/branch_physician_adhoc_schedule/view/",

  apiPostBranchPhysicianAdhocScheduleByBranchIdPhysicianId:
    "/api/v1/admin/branch_physician_adhoc_schedule/update/",

  apiGetBranchPhysicianAdhocScheduleBranchList:
    "/api/v1/admin/branch_physician_adhoc_schedule/search_branches",

  apiGetBranchPhysicianAdhocSchedulePhysicianList:
    "/api/v1/admin/branch_physician_adhoc_schedule/search_physicians",

  /*invoice*/

  apiPutInvoiceWIPMode: "/api/v1/work_order/invoice/set_invoice_wip_mode/",

  apiPutInvoicePendingStatus:
    "/api/v1/work_order/invoice/set_invoice_pending_status/",

  apiGetInvoiceList: "/api/v1/work_order/invoice/search_iwo",

  apiGetInvoiceSummaryList: "/api/v1/work_order/invoice/search_iwo_summary",

  apiGetInvoiceById: "/api/v1/work_order/invoice/view/",

  apiGetInvoiceByOrderNum: "/api/v1/work_order/invoice/order_number/",

  apiPostSubmitInvoice: "/api/v1/work_order/invoice/submit_invoice_header",

  apiPostCreateInvoiceById:
    "/api/v1/work_order/invoice/create_iwo_by_store_header/",

  apiPutInvoicePrintDate: "/api/v1/work_order/invoice/set_print_on",

  apiPostOverrideInvoiceAmount:
    "/api/v1/work_order/invoice/override_invoice_amount",

  apiPostInvoiceWhatsappPDFWTemplate:
    "/api/v1/work_order/invoice/whatsapp_pdf_invoice_via_content_message_template",

  apiPostInvoiceWhatsappAttachments:
    "/api/v1/work_order/invoice/whatsapp_attachments",

  apiPostInvoiceWhatsappAttachmentsWTemplate:
    "/api/v1/work_order/invoice/whatsapp_attachments_via_content_message_template",

  apiGetInvoiceOrganisationTwilio:
    "/api/v1/work_order/invoice/organisation_twilio/search",

  apiGetInvoiceTwillioTemplate:
    "/api/v1/work_order/invoice/twilio_content_template/search",

  apiGetInvoiceFileAttachmentNotification:
    "/api/v1/work_order/invoice/file_attachment_notification/search",

  apiGetInvoiceCurrentOrganisation:
    "/api/v1/work_order/invoice/current_organisation",

  apiPostInvoiceS3CopyObject: "/api/v1/work_order/invoice/s3_copy_object",

  /*invoice medicine costing*/

  apiPutInvoiceMedicineCostingWIPMode:
    "/api/v1/work_order/invoice_medicine_costing/set_invoice_medicine_costing_wip_mode/",

  apiPutInvoiceMedicineCostingPendingStatus:
    "/api/v1/work_order/invoice_medicine_costing/set_invoice_medicine_costing_pending_status/",

  apiGetInvoiceMedicineCostingList:
    "/api/v1/work_order/invoice_medicine_costing/search_imc",

  apiGetInvoiceMedicineCostingSummaryList:
    "/api/v1/work_order/invoice_medicine_costing/search_imc_summary",

  apiGetInvoiceMedicineCostingById:
    "/api/v1/work_order/invoice_medicine_costing/view/",

  apiGetInvoiceMedicineCostingByOrderNum:
    "/api/v1/work_order/invoice_medicine_costing/order_number/",

  apiPostSubmitInvoiceMedicineCosting:
    "/api/v1/work_order/invoice_medicine_costing/submit_invoice_medicine_costing_header",

  apiPutInvoiceMedicineCostingPrintDate:
    "/api/v1/work_order/invoice_medicine_costing/set_invoice_medicine_costing_print_on",

  /*user credit*/

  apiGetUserCreditList: "/api/v1/admin/user_credit/search",

  apiPostCreateUserCredit: "/api/v1/admin/user_credit/create",

  apiGetUserCreditUserList: "/api/v1/admin/user_credit/users/search",

  apiGetUserCreditById: "/api/v1/admin/user_credit/view/",

  apiGetUserCreditBalanceById:
    "/api/v1/admin/user_credit/search_user_credit_balance",

  // purchase requisition

  apiGetOrderFormList: "/api/v1/work_order/order_form/search_ofwo",

  apiGetOrderFormById: "/api/v1/work_order/order_form/view/",

  apiGetOrderFormMedicineTypes:
    "/api/v1/work_order/order_form//select_field/medicine_types",

  apiPostOrderFormMedicines:
    "/api/v1/work_order/order_form/select_field/medicines",

  apiPostOrderFormSuppliers:
    "/api/v1/work_order/order_form/select_field/suppliers",

  apiPostSubmitOrderForm:
    "/api/v1/work_order/order_form/submit_order_form_header",

  apiPutApproveOrderForm:
    "/api/v1/work_order/order_form/approve_order_form_header",

  apiPutOrderFormEditMode:
    "/api/v1/work_order/order_form/set_order_form_edit_mode",

  apiPutOrderFormCancelled:
    "/api/v1/work_order/order_form/set_order_form_cancelled",

  apiPutOrderFormPrintedDate:
    "/api/v1/work_order/order_form/set_order_form_print_on",

  // Purchase Receiving

  apiGetPurchaseOrderList: "/api/v1/work_order/purchase_order/search_powo",

  apiGetPurchaseOrderById: "/api/v1/work_order/purchase_order/view/",

  apiGetPurchaseOrderMedicineTypes:
    "/api/v1/work_order/purchase_order/select_field/medicine_types",

  apiPostPurchaseOrderMedicines:
    "/api/v1/work_order/purchase_order/select_field/medicines",

  apiPostSubmitPurchaseOrder:
    "/api/v1/work_order/purchase_order/submit_purchase_order_header",

  apiPutPurchaseOrderWIPMode:
    "/api/v1/work_order/purchase_order/set_purchase_order_wip_mode",

  apiPutPurchaseOrderCancelled:
    "/api/v1/work_order/purchase_order/set_purchase_order_cancelled",

  apiPutPurchaseOrderPrintedDate:
    "/api/v1/work_order/purchase_order/set_purchase_order_print_on",

  /*branch medicine*/

  apiGetBranchMedicineList:
    "/api/v1/admin/branch_medicine/search_branch_medicine",

  apiGetBranchMedicineById: "/api/v1/admin/branch_medicine/view/",

  apiPutUpdateBranchMedicine:
    "/api/v1/admin/branch_medicine/update_branch_medicine",

  apiPostBranchMedicineBranchList:
    "/api/v1/admin/branch_medicine/search_branches",

  apiPostBranchMedicineMedicineTypeList:
    "/api/v1/admin/branch_medicine/search_medicine_types",

  apiPostBranchMedicineMedicineList:
    "/api/v1/admin/branch_medicine/search_medicines",

  apiPostBranchMedicineBranchMedicineHistoryList:
    "/api/v1/admin/branch_medicine/branch_medicine_history",

  /*homecare*/

  apiGetAllVisitByLastModified:
    "/api/v1/homecare/get_all_visit_orders_by_last_modified_on/",

  apiGetVerifyMedicine: "/api/v1/homecare/verify_homecare_with_cms_medicine",

  apiPostUpdateHomecareMedicine:
    "/api/v1/homecare/update_homecare_with_cms_medicine",

  apiGetAllVisitByTimestampRange:
    "/api/v1/homecare/get_all_visit_orders_by_utc_timestamp_range/",

  apiGetUpdateHCVisitOrderByStoreId: "/api/v1/homecare/update_hc_visit_order/",

  apiPostUpdateHomecareManualHc2cms: "/api/v1/homecare/manual_update_hc2cms",

  /*reporting*/

  apiGetReportingInvoiceDetailsListByStore:
    "/api/v1/report/work_order/invoice/search_invoice_details_by_store",
  apiGetReportingInvoiceSummaryListByStore:
    "/api/v1/report/work_order/invoice/search_invoice_summary_by_store",
  apiGetReportingInvoiceMedicineCostingDetailsListByStore:
    "/api/v1/report/work_order/invoice_medicine_costing/search_invoice_medicine_costing_details_by_store",
  apiGetReportingInvoiceMedicineCostingListByStore:
    "/api/v1/report/work_order/invoice_medicine_costing/search_invoice_medicine_costing_summary_by_store",
  apiGetReportingInvoiceDetailsListByVisit:
    "/api/v1/report/work_order/invoice/search_invoice_details_by_visit",
  apiGetReportingInvoiceSummaryListByVisit:
    "/api/v1/report/work_order/invoice/search_invoice_summary_by_visit",
  apiGetReportingInvoiceMedicineCostingDetailsListByVisit:
    "/api/v1/report/work_order/invoice_medicine_costing/search_invoice_medicine_costing_details_by_visit",
  apiGetReportingInvoiceMedicineCostingListByVisit:
    "/api/v1/report/work_order/invoice_medicine_costing/search_invoice_medicine_costing_summary_by_visit",

  apiGetReportingExportInvoiceDetailsListByStore:
    "/api/v1/report/work_order/invoice/export_invoice_details_by_store",
  apiGetReportingExportInvoiceSummaryListByStore:
    "/api/v1/report/work_order/invoice/export_invoice_summary_by_store",
  apiGetReportingExportInvoiceMedicineCostingDetailsListByStore:
    "/api/v1/report/work_order/invoice_medicine_costing/export_invoice_medicine_costing_details_by_store",
  apiGetReportingExportInvoiceMedicineCostingListByStore:
    "/api/v1/report/work_order/invoice_medicine_costing/export_invoice_medicine_costing_summary_by_store",
  apiGetReportingExportInvoiceDetailsListByVisit:
    "/api/v1/report/work_order/invoice/export_invoice_details_by_visit",
  apiGetReportingExportInvoiceSummaryListByVisit:
    "/api/v1/report/work_order/invoice/export_invoice_summary_by_visit",
  apiGetReportingExportInvoiceMedicineCostingDetailsListByVisit:
    "/api/v1/report/work_order/invoice_medicine_costing/export_invoice_medicine_costing_details_by_visit",
  apiGetReportingExportInvoiceMedicineCostingListByVisit:
    "/api/v1/report/work_order/invoice_medicine_costing/export_invoice_medicine_costing_summary_by_visit",

  apiGetReportingExportStoreMedicineHeader:
    "/api/v1/report/delivery_recipient/export_csv_delivery_recipient",

  apiGetReportingExportMedicineInOut:
    "/api/v1/report/branch_medicine/export_medicine_in_out",

  apiGetReportingExportAcupoints: "/api/v1/report/acupoint/export_acupoints",

  apiPostReportingVerifyCSVAcupoints:
    "/api/v1/report/acupoint/verify_csv_acupoints",

  apiPostReportingImportCSVAcupoints:
    "/api/v1/report/acupoint/import_csv_acupoints",

  apiGetReportingExportMedicines: "/api/v1/report/medicine/export_medicines",

  apiPostReportingVerifyCSVMedicines:
    "/api/v1/report/medicine/verify_csv_medicines",

  apiPostReportingImportCSVMedicines:
    "/api/v1/report/medicine/import_csv_medicines",

  apiGetReportingExportTreatments: "/api/v1/report/treatment/export_treatments",

  apiPostReportingVerifyCSVTreatments:
    "/api/v1/report/treatment/verify_csv_treatments",

  apiPostReportingImportCSVTreatments:
    "/api/v1/report/treatment/import_csv_treatments",

  apiGetReportingExportFormulas: "/api/v1/report/formula/export_formulas",

  apiPostReportingVerifyCSVFormulas:
    "/api/v1/report/formula/verify_csv_formulas",

  apiPostReportingImportCSVFormulas:
    "/api/v1/report/formula/import_csv_formulas",

  apiGetReportingExportFormulaMedicine:
    "/api/v1/report/formula_medicine/export_formula_medicine",

  apiPostReportingVerifyCSVFormulaMedicine:
    "/api/v1/report/formula_medicine/verify_csv_formula_medicine",

  apiPostReportingImportCSVFormulaMedicine:
    "/api/v1/report/formula_medicine/import_csv_formula_medicine",

  apiGetReportingExportStoreMedicineDetails:
    "/api/v1/report/order_details/export_store_medicine_details",

  apiGetReportingExportVisitNotesDetails:
    "/api/v1/report/work_order/visit/export_visit_notes_details",

  apiGetReportingExportBranchMedicine:
    "/api/v1/report/branch_medicine/export_branch_medicine",

  apiPostReportingVerifyCSVBranchMedicine:
    "/api/v1/report/branch_medicine/verify_csv_branch_medicine",

  apiPostReportingImportCSVBranchMedicine:
    "/api/v1/report/branch_medicine/import_csv_branch_medicine",

  apiGetReportingExportMedicineInOutDetails:
    "/api/v1/report/branch_medicine/export_medicine_in_out_details",

  apiGetReportingExportFixedMedicinePrice:
    "/api/v1/report/medicine_fixed_selling_price/export_csv_medicine_fixed_selling_price",
  apiPostReportingVerifyCSVFixedMedicinePrice:
    "/api/v1/report/medicine_fixed_selling_price/verify_csv_medicine_fixed_selling_price/",
  apiPostReportingImportCSVFixedMedicinePrice:
    "/api/v1/report/medicine_fixed_selling_price/import_csv_medicine_fixed_selling_price/",
  apiPostReportingVerifyFixedMedicinePriceSync:
    "/api/v1/report/medicine_fixed_selling_price/verify_medicine_fixed_selling_price_sync",
  apiPostReportingImportFixedMedicinePriceSync:
    "/api/v1/report/medicine_fixed_selling_price/import_medicine_fixed_selling_price_sync",
  apiGetMedicineFixedSellingPriceHeader:
    "/api/v1/report/medicine_fixed_selling_price/search_medicine_selling_price_header_summary",
  apiGetReportingExportUserCredits:
    "/api/v1/report/user_credit/export_user_credits",
  apiGetReportingExportUserCreditsStatement:
    "/api/v1/report/user_credit/export_user_credits_statement",

  apiGetReportingBillAbleInvoiceByStore:
    "/api/v1/report/work_order/invoice/get_billable_invoice_by_store",

  apiGetReportingExportBillAbleInvoiceByStore:
    "/api/v1/report/work_order/invoice/export_billable_invoice_by_store",

  apiGetReportingBillAbleInvoiceByVisit:
    "/api/v1/report/work_order/invoice/get_billable_invoice_by_visit",

  apiGetReportingExportBillAbleInvoiceByVisit:
    "/api/v1/report/work_order/invoice/export_billable_invoice_by_visit",

  apiGetReportingBillAbleIMCByStore:
    "/api/v1/report/work_order/invoice_medicine_costing/get_billable_invoice_medicine_costing_by_store",

  apiGetReportingExportBillAbleIMCByStore:
    "/api/v1/report/work_order/invoice_medicine_costing/export_billable_invoice_medicine_costing_by_store",

  apiGetReportingBillAbleIMCByVisit:
    "/api/v1/report/work_order/invoice_medicine_costing/get_billable_invoice_medicine_costing_by_visit",

  apiGetReportingExportBillAbleIMCByVisit:
    "/api/v1/report/work_order/invoice_medicine_costing/export_billable_invoice_medicine_costing_by_visit",

  apiGetReportingPurchaseOrderSummary:
    "/api/v1/report/work_order/purchase_order/search_purchase_order_summary",

  apiGetReportingExportPurchaseOrderSummary:
    "/api/v1/report/work_order/purchase_order/export_purchase_order_summary",

  apiGetReportingPurchaseOrderDetails:
    "/api/v1/report/work_order/purchase_order/search_purchase_order_details",

  apiGetReportingExportPurchaseOrderDetails:
    "/api/v1/report/work_order/purchase_order/export_purchase_order_details",

  apiGetReportingDeliveryTrackinDetails:
    "/api/v1/report/work_order/delivery_tracking/search_delivery_tracking_details",

  apiGetReportingExportDeliveryTrackingDetails:
    "/api/v1/report/work_order/delivery_tracking/export_delivery_tracking_details",

  /*prescription message*/
  apiGetPrescriptionMsgList: "/api/v1/admin/prescription_message/search",
  apiGetPrescriptionMsgById: "/api/v1/admin/prescription_message/view/",

  apiPostCreatePrescriptionMsg: "/api/v1/admin/prescription_message/create",

  apiPutUpdatePrescriptionMsg: "/api/v1/admin/prescription_message/update",

  /*system schedules*/
  apiGetSystemScheduleList: "/api/v1/admin/system_schedule/search",
  apiGetSystemScheduleById: "/api/v1/admin/system_schedule/view/",

  apiPostCreateSystemSchedule: "/api/v1/admin/system_schedule/create",

  apiPutUpdateSystemSchedule: "/api/v1/admin/system_schedule/update",

  /*medicine*/
  apiGetMedicineList: "/api/v1/admin/medicine/search_medicine",
  apiGetMedicineById: "/api/v1/admin/medicine/view_medicine/",

  apiPostCreateMedicine: "/api/v1/admin/medicine/create_medicine",

  apiPutUpdateMedicine: "/api/v1/admin/medicine/update_medicine",

  apiGetMedicineMedicineTypes:
    "/api/v1/admin/medicine/select_field/medicine_types",

  /*acupoint*/
  apiGetAcupointList: "/api/v1/admin/acupoint/search",
  apiGetAcupointById: "/api/v1/admin/acupoint/view/",

  apiPostCreateAcupoint: "/api/v1/admin/acupoint/create",

  apiPutUpdateAcupoint: "/api/v1/admin/acupoint/update",

  /*treatment*/
  apiGetTreatmentList: "/api/v1/admin/treatment/search",
  apiGetTreatmentById: "/api/v1/admin/treatment/view/",

  apiPostCreateTreatment: "/api/v1/admin/treatment/create",

  apiPutUpdateTreatment: "/api/v1/admin/treatment/update",

  apiGetTreatmentTreatmentTypes:
    "/api/v1/admin/treatment/select_field/treatment_types",

  /*treatment type*/
  apiGetTreatmentTypeList: "/api/v1/admin/treatment_type/search",
  apiGetTreatmentTypeById: "/api/v1/admin/treatment_type/view/",

  apiPostCreateTreatmentType: "/api/v1/admin/treatment_type/create",

  apiPutUpdateTreatmentType: "/api/v1/admin/treatment_type/update",

  /*cloud storage*/
  apiPostCreatePresignedUrl: "/api/v1/admin/current_user/create_presigned_url",
  apiPostVisitCreatePresignedJson:
    "/api/v1/work_order/visit/create_presigned_post",
  apiPostOrganisationPhysicianCreatePresignedJson:
    "/api/v1/admin/organisation_physician/create_presigned_post",
  apiPostDeliveryTrakingCreatePresignedJson:
    "/api/v1/work_order/delivery_tracking/create_presigned_post",
  apiPostInvoiceCreatePresignedJson:
    "/api/v1/work_order/invoice/create_presigned_post",

  /*organisation physician*/
  apiGetOrganisationPhysicianList:
    "/api/v1/admin/organisation_physician/search",
  apiGetOrganisationPhysicianById:
    "/api/v1/admin/organisation_physician/view/user/",
  apiPutUpdateOrganisationPhysician:
    "/api/v1/admin/organisation_physician/update",

  apiGetOrganisationPhysicianUserList:
    "/api/v1/admin/organisation_physician/users/search",

  /*formula*/
  apiGetFormulaList: "/api/v1/admin/formula/search",
  apiGetFormulaById: "/api/v1/admin/formula/view/",

  apiPostCreateFormula: "/api/v1/admin/formula/create",

  apiPutUpdateFormula: "/api/v1/admin/formula/update",

  apiGetFormulaDispensaryBranch:
    "/api/v1/admin/formula/select_field/dispensary_branches_incl_alliance",

  apiGetFormulaMedicineTypeById:
    "/api/v1/admin/formula/select_field/medicine_types_incl_alliance/",

  /*formula medicine*/
  apiGetFormulaMedicineFormulaList:
    "/api/v1/admin/formula_medicine/search_formulas",
  apiGetFormulaMedicineFormulaById:
    "/api/v1/admin/formula_medicine/view/formula_medicine/",
  apiPutUpdateFormulaMedicineFormula:
    "/api/v1/admin/formula_medicine/update_by_formula",
  apiGetFormulaMedicineMedicineList:
    "/api/v1/admin/formula_medicine/select_field/medicine_info",

  /*User MFA*/
  apiGetUserMfaList: "/api/v1/admin/user_mfa/search",
  apiGetUserMfaById: "/api/v1/admin/user_mfa/view/",
  apiPutUpdateUserMfa: "/api/v1/admin/user_mfa/update",
  apiGetRandomMfaSecretKey:
    "/api/v1/admin/user_mfa/generate_random_mfa_secret_key",
  apiPostVerifyMfaSecretKey:
    "/api/v1/admin/user_mfa/post_verify_mfa_secret_key",
  apiGetOtpProvisioningUrl: "/api/v1/admin/user_mfa/get_otp_provisioning_uri",
  apiPostVerifyOtpToken: "/api/v1/admin/user_mfa/verify_otp_token",
  apiGetUserMfaUserList: "/api/v1/admin/user_mfa/users/search",

  /*Postal Code Surcharge*/
  apiGetPostalCodeSurchargeList: "/api/v1/admin/postal_code_surcharge/search",
  apiGetPostalCodeSurchargeById: "/api/v1/admin/postal_code_surcharge/view/",
  apiPostCreatePostalCodeSurcharge:
    "/api/v1/admin/postal_code_surcharge/create",
  apiPutUpdatePostalCodeSurcharge: "/api/v1/admin/postal_code_surcharge/update",

  /*Notification*/
  apiGetNotificationList: "/api/v1/admin/notification/search",
  apiGetNotificationById: "/api/v1/admin/notification/view/",
  apiPostCreateNotification: "/api/v1/admin/notification/create",
  apiPutUpdateNotification: "/api/v1/admin/notification/update",

  /*NotificationGroup*/
  apiGetNotificationGroupList: "/api/v1/admin/notification_group/search",
  apiGetNotificationGroupById: "/api/v1/admin/notification_group/view/",
  apiPostCreateNotificationGroup: "/api/v1/admin/notification_group/create",
  apiPutUpdateNotificationGroup: "/api/v1/admin/notification_group/update",

  /*FilterRule*/
  apiGetFilterRuleList: "/api/v1/admin/filter_rule/search",
  apiGetFilterRuleById: "/api/v1/admin/filter_rule/view/",
  apiPostCreateFilterRule: "/api/v1/admin/filter_rule/create",
  apiPutUpdateFilterRule: "/api/v1/admin/filter_rule/update",
  apiGetAllModelNames: "/api/v1/admin/filter_rule/get_all_model_names",

  /*Email Store Account Admin*/
  apiGetEmailStoreAccountList: "/api/v1/admin/email_store_account/search",
  apiGetEmailStoreAccountById: "/api/v1/admin/email_store_account/view/",
  apiPostCreateEmailStoreAccount: "/api/v1/admin/email_store_account/create",
  apiPutUpdateEmailStoreAccount: "/api/v1/admin/email_store_account/update",

  /*Email Store Sender Admin*/
  apiGetEmailStoreSenderList:
    "/api/v1/admin/email_store_sender/search_email_store_account",
  apiGetEmailStoreSenderById:
    "/api/v1/admin/email_store_sender/view/email_store_account/",
  apiPostCreateEmailStoreSender: "/api/v1/admin/email_store_sender/create",
  apiPutUpdateEmailStoreSender: "/api/v1/admin/email_store_sender/update",
  apiGetEmailStoreSenderOrganisationBranch:
    "/api/v1/admin/email_store_sender/search_organisation_branch",
  apiGetEmailStoreSenderMedicineType:
    "/api/v1/admin/email_store_sender/search_medicine_type",
  apiGetEmailStoreSenderOrganisationPhysician:
    "/api/v1/admin/email_store_sender/search_organisation_physician",

  /*Email Store Recipient Admin*/
  apiGetEmailStoreRecipientList: "/api/v1/admin/email_store_recipient/search",
  apiGetEmailStoreRecipientById:
    "/api/v1/admin/email_store_recipient/view/email_store_account/",
  apiPostCreateEmailStoreRecipient:
    "/api/v1/admin/email_store_recipient/create",
  apiPutUpdateEmailStoreRecipient: "/api/v1/admin/email_store_recipient/update",

  /*Email Store Work Order*/
  apiGetEmailStoreOrderList:
    "/api/v1/work_order/email_store/search_eswo_summary",
  apiGetEmailStoreOrderById: "/api/v1/work_order/email_store/view/",
  apiPostEmailStoreReadEmail: "/api/v1/work_order/email_store/read_email",
  apiPutEmailStorePendingStatus:
    "/api/v1/work_order/email_store/set_new_email_store_header_to_pending/",
  apiPutProcessEmailStorePendingStatus:
    "/api/v1/work_order/email_store/process_pending_email_store_header/",

  apiPutUpdateEmailStore:
    "/api/v1/work_order/email_store/update_email_store_header",
  apiGetEmailStoreBranchMedicine:
    "/api/v1/work_order/email_store/search_branch_medicine",

  /*medicine fixed selling price header*/
  apiGetMedicineFixedSellingPriceList:
    "/api/v1/admin/medicine_fixed_selling_price_header/search_medicine_selling_price_header",
  apiGetMedicineFixedSellingPriceSummaryList:
    "/api/v1/admin/medicine_fixed_selling_price_header/search_medicine_selling_price_header_summary",
  apiGetMedicineFixedSellingPriceById:
    "/api/v1/admin/medicine_fixed_selling_price_header/view/",
  apiPostCreateMedicineFixedSellingPrice:
    "/api/v1/admin/medicine_fixed_selling_price_header/create",
  apiPutUpdateMedicineFixedSellingPrice:
    "/api/v1/admin/medicine_fixed_selling_price_header/update",

  /*user medicine fixed selling price header*/
  apiGetUserMedicineFixedSellingPriceList:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/search_user_medicine_selling_price_header",
  apiGetUserMedicineFixedSellingPriceSummaryList:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/search_user_medicine_selling_price_header_summary",
  apiGetUserMedicineFixedSellingPriceById:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/view/",
  apiPostCreateUserMedicineFixedSellingPrice:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/create",
  apiPutUpdateUserMedicineFixedSellingPrice:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/update",
  apiGetUserMedicineFixedSellingPriceMedicineFixedSellingPriceList:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/medicine_fixed_selling_price_header/search",
  apiGetUserMedicineFixedSellingPriceUserList:
    "/api/v1/admin/user_medicine_fixed_selling_price_header/users/search",

  /*medicine fixed selling price details*/
  apiGetMedicineFixedSellingPriceDetails:
    "/api/v1/admin/medicine_fixed_selling_price_details/search_medicine_selling_price_details",
  apiGetMedicineFixedSellingPriceHeaderDetailsList:
    "/api/v1/admin/medicine_fixed_selling_price_details/search_medicine_selling_price_header_details",
  apiGetMedicineFixedSellingPriceDetailsSummaryList:
    "/api/v1/admin/medicine_fixed_selling_price_details/search_medicine_selling_price_header_summary",
  apiGetMedicineFixedSellingPriceDetailsById:
    "/api/v1/admin/medicine_fixed_selling_price_details/view/",
  apiPutUpdateMedicineFixedSellingPriceDetails:
    "/api/v1/admin/medicine_fixed_selling_price_details/update",
  apiGetMedicineFixedSellingPriceDetailsMedicineTypes:
    "/api/v1/admin/medicine_fixed_selling_price_details/select_field/medicine_types",
  apiGetMedicineFixedSellingPriceDetailsMedicineInfo:
    "/api/v1/admin/medicine_fixed_selling_price_details/select_field/medicine_info",

  /*wiki administration*/
  apiGetWikiDataList: "/api/v1/admin/wiki_data/search",
  apiGetWikiDataById: "/api/v1/admin/wiki_data/view/",
  apiPostCreateWikiData: "/api/v1/admin/wiki_data/create",
  apiPutUpdateWikiData: "/api/v1/admin/wiki_data/update",
  apiGetWikiAcupointList: "/api/v1/admin/wiki_data/search_acupoints",
  apiGetWikiCustomWordList: "/api/v1/admin/wiki_data/search_custom_words",
  apiGetWikiTreatmentTypes:
    "/api/v1/admin/wiki_data/select_field/treatment_types",
  apiGetWikiTreatmentList: "/api/v1/admin/wiki_data/search_treatments",
  apiGetWikiMedicineTypes:
    "/api/v1/admin/wiki_data/select_field/medicine_types",
  apiGetWikiMedicineList: "/api/v1/admin/wiki_data/search_medicine",

  /*fulfillment*/
  apiGetFulfillmentSummaryList:
    "/api/v1/work_order/fulfillment/get_fulfillment_summary",
  apiPutFulfillmentColourHexCode:
    "/api/v1/work_order/fulfillment/set_row_colour_hex_code",

  /*packing*/

  apiGetPackingSummaryList: "/api/v1/work_order/packing/search_pwo_summary",
  apiGetPackingList: "/api/v1/work_order/packing/search_pwo",
  apiGetPackingById: "/api/v1/work_order/packing/view/",
  apiGetPackingByOrderNum: "/api/v1/work_order/packing/order_number/",
  apiPostSubmitPacking: "/api/v1/work_order/packing/submit_packing_header",
  apiPutPackingWIPMode: "/api/v1/work_order/packing/set_packing_wip_mode/",
  apiPutPackingPendingStatus:
    "/api/v1/work_order/packing/set_packing_pending_status/",
  apiPutPackingCloseMode: "/api/v1/work_order/packing/set_packing_close_status",

  /*non business day*/

  apiGetNonBusinessDayList: "/api/v1/admin/non_business_day/search",
  apiGetNonBusinessDayById: "/api/v1/admin/non_business_day/view/",
  apiPostCreateNonBusinessDay: "/api/v1/admin/non_business_day/create",
  apiPutUpdateNonBusinessDay: "/api/v1/admin/non_business_day/update",
  apiPostSearchNonBusinessDayBranch:
    "/api/v1/admin/non_business_day/search_branches",

  /*external api*/
  apiGetSingaporeHolidayList:
    "/v2/public/api/collections/691/metadata?withDatasetMetadata=true",
  apiGetSingaporeYearlyHoliday: "/api/action/datastore_search?resource_id=",

  /*delivery cut off api*/

  apiGetDeliveryCutoffScheduleList:
    "/api/v1/admin/delivery_cutoff_schedule/search",
  apiGetDeliveryCutoffScheduleById:
    "/api/v1/admin/delivery_cutoff_schedule/view/",
  apiPutDeliveryCutoffSchedule: "/api/v1/admin/delivery_cutoff_schedule/update",
  apiPostDeliveryCutoffScheduleBranch:
    "/api/v1/admin/delivery_cutoff_schedule/search_branches",

  /*delivery tracking api*/

  apiGetDeliveryTrackingList:
    "/api/v1/work_order/delivery_tracking/search_delivery_tracking",
  apiGetDeliveryTrackingSummaryList:
    "/api/v1/work_order/delivery_tracking/search_delivery_tracking_summary",
  apiGetDeliveryTrackingById: "/api/v1/work_order/delivery_tracking/view/",
  apiGetDeliveryTrackingCourierList:
    "/api/v1/work_order/delivery_tracking/select_field/couriers",
  apiGetDeliveryTrackingCourierByBranch:
    "/api/v1/work_order/delivery_tracking/search_courier_by_branch",
  apiPutDeliveryTrackingWIPMode:
    "/api/v1/work_order/delivery_tracking/set_delivery_tracking_wip_mode/",
  apiPostDeliveryTracking:
    "/api/v1/work_order/delivery_tracking/submit_delivery_tracking_header",
  apiPutDeliveryTrackingPendingStatus:
    "/api/v1/work_order/delivery_tracking/set_delivery_tracking_pending_status/",
  apiPutDeliveryTrackingCloseStatus:
    "/api/v1/work_order/delivery_tracking/set_delivery_tracking_close_status",
  apiPutDeliveryTrackingPrintDate:
    "/api/v1/work_order/delivery_tracking/set_delivery_tracking_print_on",
  apiPutDeliveryTrackingUploadFile:
    "/api/v1/work_order/delivery_tracking/set_upload_file",

  /*courier*/
  apiGetCourierList: "/api/v1/admin/courier/search",
  apiGetCourierById: "/api/v1/admin/courier/view/",
  apiPutUpdateCourier: "/api/v1/admin/courier/update",
  apiPostCreateCourier: "/api/v1/admin/courier/create",

  /*branch courier*/
  apiGetBranchCourierList: "/api/v1/admin/branch_courier/search_branch_courier",
  apiGetBranchCourierById: "/api/v1/admin/branch_courier/view/branch/",
  apiPutUpdateBranchCourier: "/api/v1/admin/branch_courier/update_by_branch",
  apiPostBranchCourierCourierList:
    "/api/v1/admin/branch_courier/search_couriers",
  apiPostBranchCourierBranchList: "/api/v1/admin/branch_courier/search_branch",
  apiPostBranchCourierUserDeviceKeyList:
    "/api/v1/admin/branch_courier/search_user_device_keys",

  /*user device key*/
  apiGetUserDeviceKeyList: "/api/v1/admin/user_device_key/search",
  apiGetUserDeviceKeyById: "/api/v1/admin/user_device_key/view/",
  apiPutUpdateUserDeviceKey: "/api/v1/admin/user_device_key/update",
  apiPostCreateUserDeviceKey: "/api/v1/admin/user_device_key/create",
};

export default apiConstants;
