//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

//actions
import {
  getDeliveryOptimizedRouting,
  getGoogleStaticMapPayload,
  getDeliveryOrderVerifyAddressList,
} from "../../../../actions/deliveryOrderActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { useStyles } from "../../../../components/globalStyles";
import * as pageConfigure from "../../../../_constants/pageConstants";
//style
export default function DeliveryOptimizedDialog({
  isLoading,
  selectedValue,
  confirmOpen,
  setConfirmOpen,
  handleConfirmProcess,
  groupOrder,
  setGroupOrder,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const prevRouteStatus = useRef();

  const prevVerifyAddressStatus = useRef();

  const prevViewRouteStatus = useRef();

  const curUser = useSelector((state) => state.curUser);

  const deliveryOrder = useSelector((state) => state.deliveryOrder);

  const [viewRoute, setViewRoute] = useState(false);

  const [routeLoading, setRouteLoading] = useState(false);

  const [alertMsg, setAlertMsg] = useState(null);

  const [alertType, setAlertType] = useState(null);

  const [msgAlarm, setMsgAlarm] = useState(false);

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });

  const [imageString, setImageString] = useState(null);

  const setData = (rowIndex, columnID) => {
    if (columnID === "up") {
      let result = [...groupOrder];
      const newAddMedicineVerified = groupOrder.findIndex(
        (e, index) => index === rowIndex
      );

      [result[newAddMedicineVerified], result[newAddMedicineVerified - 1]] = [
        result[newAddMedicineVerified - 1],
        result[newAddMedicineVerified],
      ];
      result = result.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      setGroupOrder(result);
    } else if (columnID === "down") {
      let result = [...groupOrder];
      const newAddMedicineVerified = groupOrder.findIndex(
        (e, index) => index === rowIndex
      );

      [result[newAddMedicineVerified], result[newAddMedicineVerified + 1]] = [
        result[newAddMedicineVerified + 1],
        result[newAddMedicineVerified],
      ];

      result = result.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      setGroupOrder(result);
    }
  };

  const columnUpdateRow = [
    {
      id: "reorder",
      textAlign: "left",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <span className={classes.fullWidthField}>
          {row.index !== 0 && (
            <IconButton
              aria-label="up"
              color="inherit"
              onClick={() => setData(row.index, "up")}>
              <KeyboardDoubleArrowUpIcon fontSize="inherit" />
            </IconButton>
          )}
          {row.index + 1 !== groupOrder.length && (
            <IconButton
              aria-label="down"
              color="inherit"
              onClick={() => setData(row.index, "down")}>
              <KeyboardDoubleArrowDownIcon fontSize="inherit" />
            </IconButton>
          )}
        </span>
      ),
    },
    {
      Header: t("translation:Recipient"),
      accessor: "delivery_name",
      sortable: false,
    },
    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "delivery_contact_number",
    },
    {
      Header: t("translation:Postal Code"),
      sortable: false,
      accessor: "delivery_postal_code",
    },
    {
      Header: t("translation:Address"),
      sortable: false,
      accessor: "delivery_address",
    },
    {
      Header: t("translation:Address Details"),
      sortable: false,
      accessor: "delivery_address_details",
    },
    {
      Header: t("translation:Remarks"),
      sortable: false,
      accessor: "delivery_remarks",
    },
  ];

  const onClickVerifyRoute = () => {
    const temAddressList = groupOrder.filter(
      (item) => item.delivery_postal_code && item.delivery_address
    );
    if (temAddressList.length === groupOrder.length) {
      const addressList = temAddressList.map((item) => {
        if (item.delivery_postal_code || item.delivery_address) {
          return {
            address: item.delivery_address,
            postal_code: item.delivery_postal_code,
          };
        }
      });

      const submission = { addresses: addressList };
      submission.addresses.splice(0, 0, {
        address: curUser.defaultBranch.branch.address,
        postal_code: curUser.defaultBranch.branch.postal_code,
      });
      setViewRoute(true);
      dispatch(getDeliveryOrderVerifyAddressList.pending(submission));
    } else {
      setMsgAlarm(t("translation:Failed to view routes"));
      setAlertType("error");
      setAlertMsg(true);
    }
  };

  const onClickViewRoute = (value) => {
    if (value.length - 1 === groupOrder.length) {
      const pointList = value.map((item) => {
        return { latitude: item.latitude, longitude: item.longitude };
      });

      const submission = { points: pointList };
      dispatch(getGoogleStaticMapPayload.pending(submission));
    } else {
      setMsgAlarm(t("translation:Failed to view routes"));
      setAlertType("error");
      setAlertMsg(true);
      setViewRoute(false);
    }
  };

  const onClickRoute = (e) => {
    const submission = {
      is_pagination: false,
      search_delivery_order_id_value: selectedValue.id,
      geometry: {
        first_stop: {
          name: "first_stop",
          postal_code: curUser.defaultBranch.branch.postal_code,
        },
        remove_duplicate: true,
      },
    };
    dispatch(getDeliveryOptimizedRouting.pending(submission));
  };

  const overrideRoute = (value) => {
    if (value.length) {
      const tempRoute = value[0].geometry.filter(
        (item) => item !== null && item.name !== "first_stop"
      );
      let temValidAddress = [];
      //find the addr and arrange it

      tempRoute.map((o1, index) => {
        if (
          groupOrder.some((o2) => o2.delivery_postal_code === o1.postal_code)
        ) {
          const filterList = groupOrder.filter(
            (o2) => o2.delivery_postal_code === o1.postal_code
          );
          temValidAddress = [...temValidAddress, ...filterList];
        }
      });

      let temInvalidAddress = groupOrder.filter(
        (o1) =>
          !tempRoute.some((o2) => o1.delivery_postal_code === o2.postal_code)
      );
      let temCombinationAddress = [...temValidAddress, ...temInvalidAddress];

      temCombinationAddress.map((o1, index) => {
        temCombinationAddress[index].row_no = index + 1;
      });
      setGroupOrder(temCombinationAddress);
    }
  };

  //useEffects
  useEffect(() => {
    prevRouteStatus.current = false;
    prevVerifyAddressStatus.current = false;
    prevViewRouteStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList !==
      prevVerifyAddressStatus.current
    ) {
      //check the previous ref with current state
      prevVerifyAddressStatus.current =
        deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList;

      if (
        deliveryOrder.getDeliveryOrderVerifyAddressListIsSuccess === true &&
        deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList === false
      ) {
        onClickViewRoute(deliveryOrder.getDeliveryOrderVerifyAddressList);
      }

      if (
        deliveryOrder.getDeliveryOrderVerifyAddressListIsSuccess === false &&
        deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList === false
      ) {
        setMsgAlarm(t("translation:Failed to view routes"));
        setAlertType("error");
        setAlertMsg(true);
        setViewRoute(false);
      }
    }
  }, [dispatch, deliveryOrder]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetDeliveryOptimizedRouting !==
      prevRouteStatus.current
    ) {
      //check the previous ref with current state
      prevRouteStatus.current =
        deliveryOrder.isLoadingGetDeliveryOptimizedRouting;

      if (
        deliveryOrder.getDeliveryOptimizedRoutingIsSuccess === true &&
        deliveryOrder.isLoadingGetDeliveryOptimizedRouting === false
      ) {
        overrideRoute(deliveryOrder.getDeliveryOptimizedRouting);
        setRouteLoading(false);
        setMsgAlarm(t("translation:Optimun routes successfully"));
        setAlertType("success");
        setAlertMsg(true);
      }

      if (
        deliveryOrder.getDeliveryOptimizedRoutingIsSuccess === false &&
        deliveryOrder.isLoadingGetDeliveryOptimizedRouting === false
      ) {
        setRouteLoading(false);
        setMsgAlarm(t("translation:Failed to optimum routes"));
        setAlertType("error");
        setAlertMsg(true);
      }
    }
  }, [dispatch, deliveryOrder]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetGoogleStaticMapPayload !==
      prevViewRouteStatus.current
    ) {
      //check the previous ref with current state
      prevViewRouteStatus.current =
        deliveryOrder.isLoadingGetGoogleStaticMapPayload;

      if (
        deliveryOrder.getGoogleStaticMapPayloadIsSuccess === true &&
        deliveryOrder.isLoadingGetGoogleStaticMapPayload === false
      ) {
        setImageString(deliveryOrder.getGoogleStaticMapPayload.data);
      }

      if (
        deliveryOrder.getGoogleStaticMapPayloadIsSuccess === false &&
        deliveryOrder.isLoadingGetGoogleStaticMapPayload === false
      ) {
        setImageString([]);
      }
    }
  }, [dispatch, deliveryOrder]);

  return (
    <>
      <ConfirmDialog
        title={t("translation:View Routes")}
        open={viewRoute}
        setOpen={setViewRoute}
        maxWidth="md">
        <Grid item xs={12} container justifyContent="center">
          {imageString !== null && (
            <img
              src={imageString}
              height="640px"
              weight="640px"
              alt="routeMap"
            />
          )}
          {deliveryOrder.isLoadingGetGoogleStaticMapPayload === true &&
            t("translation:Loading") + "..."}
        </Grid>
      </ConfirmDialog>

      {isLoading && (
        <ConfirmDialog
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Loading")}...
        </ConfirmDialog>
      )}
      {isLoading === false && (
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          maxWidth="lg"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {alertType !== null && alertMsg !== null && (
            <Collapse in={alertMsg}>
              <Alert
                severity={alertType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertMsg(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {msgAlarm}
              </Alert>
            </Collapse>
          )}
          <Grid item xs={12}>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                disabled={routeLoading}
                onClick={() => onClickRoute()}>
                {t("translation:Optimum Routes")}
              </Button>
            </Link>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                onClick={() => onClickVerifyRoute()}>
                {t("translation:View Routes")}
              </Button>
            </Link>
          </Grid>

          <SortingSearchTable
            tableHeading=""
            disabledSearch
            disabledFooter
            searchValue={" "}
            columns={columnUpdateRow}
            data={groupOrder}
            pageInfo={viewPageInfo}
            setPageInfo={setViewPageInfo}
            pageCount={0}
            loading={false}
            totalItems={0}
            handleSearch={() => null}
          />
        </ConfirmDialog>
      )}
    </>
  );
}
