//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//action
import { putUpdateBranchCourier } from "../../../../actions/branchCourierActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import CourierSelectionField from "./components/CourierSelectionField";
import BranchCourierField from "./components/BranchCourierField";
import Panel from "../../../../components/Panel";

const ProcessPage = ({
  setView,
  branchId,
  infoData = null,
  setInfoData = () => null,
  viewMode = false,
}) => {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const branchCourier = useSelector((state) => state.branchCourier);
  const prevSubmitBranchCourierStatus = useRef();
  const prevGetBranchCourierListStatus = useRef();
  const { handleSubmit } = useForm();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [courier, setCourier] = useState(
    infoData?.couriers ? infoData.couriers : null
  );

  const [branchCouriers, setBranchCouriers] = useState(
    infoData ? infoData.branch_couriers : null
  );

  //functions

  const onSubmit = () => {
    setConfirmSubmit(true);
  };
  const onConfirmSubmit = () => {
    //reset submit error
    setErrorAlarm(false);
    setSuccessAlarm(false);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      branches: [
        {
          branch_courier_associations: [
            {
              courier: {
                id: courier.id,
              },
              delivery_charge: branchCouriers?.delivery_charge
                ? branchCouriers?.delivery_charge
                : null,
              status_type: branchCouriers?.status_type
                ? branchCouriers?.status_type
                : null,
              user_device_key: branchCouriers?.user_device_key
                ? { id: branchCouriers?.user_device_key.id }
                : null,
            },
          ],
          id: branchId,
        },
      ],
    };
    dispatch(putUpdateBranchCourier.pending(submitJson));
  };

  useEffect(() => {
    //set ref when first render
    prevSubmitBranchCourierStatus.current = false;
    prevGetBranchCourierListStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      branchCourier.isLoadingPutUpdateBranchCourier !==
      prevSubmitBranchCourierStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitBranchCourierStatus.current =
        branchCourier.isLoadingPutUpdateBranchCourier;

      if (
        branchCourier.putUpdateBranchCourierIsSuccess === false &&
        branchCourier.isLoadingPutUpdateBranchCourier === false
      ) {
        setErrorAlarm(true);
        window.scrollTo(0, 0);
        setMsgAlarm(branchCourier.error.putUpdateBranchCourierError);
      }

      if (
        branchCourier.putUpdateBranchCourierIsSuccess === true &&
        branchCourier.isLoadingPutUpdateBranchCourier === false
      ) {
        setSuccessAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [branchCourier]);

  useEffect(() => {
    if (
      branchCourier.isLoadingGetBranchCourierList !==
      prevGetBranchCourierListStatus.current
    ) {
      //check the previous ref with current state
      prevGetBranchCourierListStatus.current =
        branchCourier.isLoadingGetBranchCourierList;

      if (
        branchCourier.getBranchCourierListIsSuccess === true &&
        branchCourier.isLoadingGetBranchCourierList === false
      ) {
        if (branchCourier.getBranchCourierList.branch_couriers.length) {
          setBranchCouriers(
            branchCourier.getBranchCourierList.branch_couriers[0]
          );
        } else {
          setBranchCouriers({});
        }

        // setStatusType(
        //   branchCourier.branchCourierById.branch_couriers[0]?.status_type
        // );
      }
    }
  }, [branchCourier]);

  return (
    <Panel
      heading={
        viewMode
          ? t("translation:Branch Courier Details")
          : t("translation:Edit Branch Courier Details")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <Collapse in={successAlarm}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Branch Courier has been submitted")}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <CourierSelectionField
          courier={courier}
          setCourier={setCourier}
          setBranchCouriers={setBranchCouriers}
          branchId={branchId}
        />

        {courier && branchCourier.getBranchCourierList && branchCouriers && (
          <>
            <BranchCourierField
              viewMode={viewMode}
              branchCouriers={branchCouriers}
              setBranchCouriers={setBranchCouriers}
            />
          </>
        )}

        <RequiredNote />
        {viewMode && <PageOnlyBackAction setView={() => setView()} />}
        {!viewMode && <PageSubmitWBackAction setView={() => setView()} />}
      </form>
    </Panel>
  );
};

export default ProcessPage;
