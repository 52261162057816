//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { Grid, TextField, Link, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
//action

import {
  getVisitDispensaryBranchesAlliance,
  getVisitMedicineTypesAlliance,
  postVisitFormulasAlliance,
  postVisitMedicineFormulasAlliance,
  postVisitBranchMedicines,
  postVisitPhysicians,
  postVisitSubmitStoreOrder,
  postVisitSaveStoreOrder,
  getVisitCurrentOrganisation,
  getVisitPostalCodeSurchargeList,
  getVisitVerifyAddress,
} from "../../../actions/visitOrderActions";

import { getDefaultBranch } from "../../../actions/curUserActions";

import { getMedicineMeasureUnit } from "../../../actions/commonActions";
//components
import StyledCheckbox from "../../../components/StyledCheckbox";
import Panel from "../../../components/Panel";
import { PatientInfoTable } from "./PatientInfoTable";
import organisationPriceRounding from "../../../components/functions/organisationPriceRounding";
import organisationPriceRoundingWGst from "../../../components/functions/organisationPriceRoundingWGst";
import ConfirmDialog from "../../../components/ConfirmDialog";
import MedicineListTable from "../../workOrder/Store/MedicineListTable";
import OrderDetailPage from "./OrderDetailPage";
import * as generalConstants from "../../../_constants/generalConstants";
import Note from "../../workOrder/Store/components/addOrder/Note";
import PageAction from "../../../components/PageAction";
import AllianceTotalPrice from "../../workOrder/Store/components/editOrder/AllianceTotalPrice";
import EditBrewing from "../../workOrder/Store/components/editOrder/EditBrewing";
import EditDelivery from "../../workOrder/Store/components/editOrder/EditDelivery";
import GroupOption from "../../workOrder/Store/components/editOrder/GroupOption";
import GroupTotal from "../../workOrder/Store/components/addOrder/GroupTotal";
import GroupMedicineInfo from "../../workOrder/Store/components/addOrder/GroupMedicineInfo";
import AcupunctureGroupOption from "./components/editOrder/AcupunctureGroupOption";
import AcupointListTable from "./components/editOrder/AcupointListTable";
import GroupAcupunctureInfo from "./components/editOrder/GroupAcupunctureInfo";
import EditTreatment from "./components/editOrder/EditTreatment";
import TreatmentListTable from "./components/editOrder/TreatmentListTable";
import EditMiscFee from "./components/editOrder/EditMiscFee";
import PaymentTotal from "./components/editOrder/PaymentTotal";
import MiscListTable from "./components/editOrder/MiscListTable";
import PaymentTable from "./components/editOrder/PaymentTable";
import EditVisitNotesDetails from "./components/editOrder/EditVisitNotesDetails";
import EditMcIssued from "./components/editOrder/EditMcIssued";
import EditMdIssued from "./components/editOrder/EditMdIssued";
import PatientHistoryDialog from "./components/viewOrder/PatientHistoryDialog";
import RequiredNote from "../../../components/RequiredNote";
import submisionJSON from "./components/editOrder/submisionJSON";
import noEnterSubmitForm from "../../../components/functions/noEnterSubmitForm";
import { useStyles } from "../../../components/globalStyles";
import SubmissionNotificationDialog from "../../../components/SubmissionNotificationDialog";
import EditUserMedicalDialog from "./components/editOrder/EditUserMedicalDialog";
import EditVisitAttachment from "./components/editOrder/EditVisitAttachment";
import DownloadVisitAttachment from "./components/editOrder/DownloadVisitAttachment";
import SharedDelivery from "../../workOrder/Store/components/addOrder/SharedDelivery";
import ProceedDay from "../../workOrder/Store/components/addOrder/ProceedDay";
//style
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const OrderEditPage = ({
  setOrderEditView,
  editOrderInfo,
  handleCopyOnClick = () => null,
  setEditOrderInfo = () => null,
  copyableMode = false,
}) => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visitOrder = useSelector((state) => state.visitOrder);
  const userAuth = useSelector((state) => state.userAuth);
  const user = userAuth.systemUserInfo;
  const common = useSelector((state) => state.common);

  const curUser = useSelector((state) => state.curUser);
  const { handleSubmit } = useForm();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [saveSuccessAlarm, setSaveSuccessAlarm] = useState(false);
  const [saveErrorAlarm, setSaveErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [orderDetailInfo, setOrderDetailInfo] = React.useState([]);
  const [orderSaveDetailView, setOrderSaveDetailView] = React.useState(false);
  const [orderSaveDetailInfo, setOrderSaveDetailInfo] = React.useState([]);
  const prevSubmitVisitOrderStatus = useRef();
  const prevSaveVisitOrderStatus = useRef();
  const prevChangeBranchTreatmentOnlyStatus = useRef();
  const prevChangeBranchAcupointOnlyStatus = useRef();
  const prevValidateStatus = useRef();

  const [costingHeader, setCostingHeader] = React.useState([]);
  const [showDelivery, setShowDelivery] = useState(true);

  const [showBrewing, setShowBrewing] = useState(true);
  const [isRawHerb, setIsRawHerb] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(true);

  const [verifyAddressList, setVerifyAddressList] = useState([]);

  const [isSubmitMode, setIsSubmitMode] = useState(true);

  const [isInformalAddressAlert, setIsInformalAddressAlert] = useState(false);

  const [isAddressChoiceAlert, setIsAddressChoiceAlert] = useState(false);

  const [addressChoiceList, seAddressChoiceList] = useState([]);

  const [chosenAddress, setChosenAddress] = useState(null);

  // const [choseBranch, setChoseBranch] = useState(false);
  const [uploadFileObjects, setUploadFileObjects] = React.useState(
    editOrderInfo.visit_attachments.map((item) => {
      return { ...item, file: { name: item.original_filename } };
    })
  );

  //dont display deleted group as default
  const defaultMedicineGroup = editOrderInfo.store_medicine_headers.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );
  const defaultAcupunctureGroup = editOrderInfo.visit_acupuncture_headers.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );

  const defaultVisitNoteDetails = editOrderInfo.visit_notes_details.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );

  const [price, setPrice] = useState({
    deliveryFee: 0,
    brewingFee: 0,
    consultationFee: 0,
    medicineTab: 0,
    medicineFee: 0,
    vendorGstAmt: 0,
    medicineGroupTotal: 0,
    acupuncturePrice: 0,
    treatmentPrice: 0,
    miscPrice: 0,
  });

  const [extraAmt, setExtraAmt] = useState({
    deliveryFee: Number(editOrderInfo.delivery_extra_amt),
    brewingFee: Number(editOrderInfo.brewing_extra_amt),
    consultationFee: Number(editOrderInfo.consultation_extra_amt),
    medicineTab: Number(editOrderInfo.medicine_extra_amt),
    acupuncturePrice: Number(editOrderInfo.acupuncture_extra_amt),
    treatmentPrice: 0,
    miscPrice: 0,
  });

  const [discount, setDiscount] = useState({
    deliveryFee: Number(editOrderInfo.delivery_discount_amt),
    brewingFee: Number(editOrderInfo.brewing_discount_amt),
    consultationFee: Number(editOrderInfo.consultation_discount_amt),
    medicineTab: Number(editOrderInfo.medicine_discount_amt),
    acupuncturePrice: Number(editOrderInfo.acupuncture_discount_amt),
    treatmentPrice: Number(editOrderInfo.treatment_discount_amt),
    miscPrice: Number(editOrderInfo.misc_discount_amt),
  });

  const [payment, setPayment] = useState({
    discountInPrecent: Number(editOrderInfo.discount_in_percent),
    paymentTerm: editOrderInfo.payment_term,
    paymentMethod: editOrderInfo.payment_method,
    referral_code: editOrderInfo.referral_code,
  });

  const [visitNotesDetails, setVisitNotesDetails] = useState({
    id: defaultVisitNoteDetails ? defaultVisitNoteDetails.id : 0,
    is_medical_diagnosis_issued: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.is_medical_diagnosis_issued
      : null,
    medical_diagnosis_reason: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.medical_diagnosis_reason
      : null,
    is_mc_issued: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.is_mc_issued
      : null,
    mc_number_of_days: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.mc_number_of_days
      : null,
    mc_reason: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.mc_reason
      : null,
    name: defaultVisitNoteDetails ? defaultVisitNoteDetails.name : null,
    physician_advice: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.physician_advice
      : null,
    physician_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.physician_diagnosis
      : null,
    pulse_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.pulse_diagnosis
      : null,
    remarks: defaultVisitNoteDetails ? defaultVisitNoteDetails.remarks : null,
    review_notes: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.review_notes
      : null,
    status_type: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.status_type
      : null,
    tongue_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.tongue_diagnosis
      : null,
    western_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.western_diagnosis
      : null,
    others: defaultVisitNoteDetails ? defaultVisitNoteDetails.others : null,
    syndrome: defaultVisitNoteDetails ? defaultVisitNoteDetails.syndrome : null,
  });

  const [amountHeader, setAmountHeader] = useState({
    amt_after_discount: editOrderInfo.amt_after_discount,
    amt_before_discount: editOrderInfo.amt_before_discount,
    amt_with_gst: editOrderInfo.amt_with_gst,
    amt_wo_gst: editOrderInfo.amt_wo_gst,
    gst_amt: editOrderInfo.gst_amt,
    amt_rounding: editOrderInfo.amt_rounding,
  });

  const [tabValue, setTabValues] = useState("0");

  const [group, setGroup] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.name : null
  );
  const [groupId, setGroupId] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.id : null
  );

  const [groupOption, setGroupOption] = useState(
    editOrderInfo.store_medicine_headers
  );

  const [medicineList, setMedicineList] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.store_medicine_details : []
  );

  const [acupunctureGroup, setAcupunctureGroup] = useState(
    defaultAcupunctureGroup ? defaultAcupunctureGroup.name : ""
  );
  const [acupunctureGroupId, setAcupunctureGroupId] = useState(
    defaultAcupunctureGroup ? defaultAcupunctureGroup.id : null
  );
  const [acupunctureGroupOption, setAcupunctureGroupOption] = useState(
    editOrderInfo.visit_acupuncture_headers
  );
  const [acupointList, setAcupointList] = useState(
    defaultAcupunctureGroup
      ? defaultAcupunctureGroup.visit_acupuncture_details
      : []
  );

  //treatmentList and miscList dont contain group so no need to filter out default arrary
  const [treatmentList, setTreatmentList] = useState(
    editOrderInfo ? editOrderInfo.visit_treatment_details : []
  );

  const [miscList, setMiscList] = useState(
    editOrderInfo ? editOrderInfo.visit_misc_details : []
  );

  const [editMedicalOpen, setEditMedicalOpen] = useState(false);
  //function

  //set table data
  const setMedicineData = (rowIndex, columnID, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );

    //[a[m], a[n]] = [a[n], a[m]]

    //make a copy of group option
    let oldArray = [...groupOption];

    let storeMedicineDetail = oldArray[elementsIndex].store_medicine_details;

    if (columnID === "delete") {
      let result = [...storeMedicineDetail];
      //check whether is a current existing medicine

      const currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = result.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        result[currentMedicineIndex] = {
          ...currentMedicineVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.medicine_name !== newAddMedicineVerified.medicine_name
        );
      }
      let resultWDelete = result.filter(
        (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
      );
      let resultWoDelete = result.filter(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      resultWDelete = resultWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      resultWoDelete = resultWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: [...resultWoDelete, ...resultWDelete],
      };
      setGroupOption(oldArray);
    } else if (columnID === "up") {
      //check whether is a current existing medicine

      let currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentMedicineWDelete = storeMedicineDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = currentMedicineWoDelete.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [
          currentMedicineWoDelete[currentMedicineIndex],
          currentMedicineWoDelete[currentMedicineIndex - 1],
        ] = [
          currentMedicineWoDelete[currentMedicineIndex - 1],
          currentMedicineWoDelete[currentMedicineIndex],
        ];
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [
          currentMedicineWoDelete[newAddMedicineVerified],
          currentMedicineWoDelete[newAddMedicineVerified - 1],
        ] = [
          currentMedicineWoDelete[newAddMedicineVerified - 1],
          currentMedicineWoDelete[newAddMedicineVerified],
        ];
      }
      currentMedicineWDelete = currentMedicineWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentMedicineWoDelete = currentMedicineWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: [
          ...currentMedicineWoDelete,
          ...currentMedicineWDelete,
        ],
      };
      setGroupOption(oldArray);
    } else if (columnID === "down") {
      //check whether is a current existing medicine

      let currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentMedicineWDelete = storeMedicineDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = currentMedicineWoDelete.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [
          currentMedicineWoDelete[currentMedicineIndex],
          currentMedicineWoDelete[currentMedicineIndex + 1],
        ] = [
          currentMedicineWoDelete[currentMedicineIndex + 1],
          currentMedicineWoDelete[currentMedicineIndex],
        ];
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [
          currentMedicineWoDelete[newAddMedicineVerified],
          currentMedicineWoDelete[newAddMedicineVerified + 1],
        ] = [
          currentMedicineWoDelete[newAddMedicineVerified + 1],
          currentMedicineWoDelete[newAddMedicineVerified],
        ];
      }

      currentMedicineWDelete = currentMedicineWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentMedicineWoDelete = currentMedicineWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: [
          ...currentMedicineWoDelete,
          ...currentMedicineWDelete,
        ],
      };
      setGroupOption(oldArray);
    } else {
      //without deleted medicine
      let currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      //deleted medicine
      const currentMedicineWDelete = storeMedicineDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      currentMedicineWoDelete = currentMedicineWoDelete.sort(
        (a, b) => a.row_no - b.row_no
      );
      const selectedstoreMedicine = currentMedicineWoDelete.map(
        (medicine, index) => {
          if (index === rowIndex && columnID !== "delete") {
            return {
              ...medicine,
              [columnID]: value,
            };
          }
          return medicine;
        }
      );

      //need to merge back delete medicine in order to update
      const mergeSelectedWDeletedMedicine = [
        ...currentMedicineWDelete,
        ...selectedstoreMedicine,
      ];

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: mergeSelectedWDeletedMedicine,
      };
      setGroupOption(oldArray);
    }
  };

  //set table data
  const setAcupointsData = (rowIndex, columnID, value) => {
    const elementsIndex = memoSelectedAcupunctureGroupIndex;
    //make a copy of group option
    let oldArray = [...acupunctureGroupOption];
    let visitAcupunctureDetail =
      oldArray[elementsIndex].visit_acupuncture_details;
    if (columnID === "delete") {
      let result = [...visitAcupunctureDetail];

      const currentAcupointWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentAcupointVerified = currentAcupointWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentAcupointVerified) {
        const currentAcupointIndex = result.findIndex(
          (e) => e.id === currentAcupointVerified.id
        );
        result[currentAcupointIndex] = {
          ...currentAcupointVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddAcupointVerified = currentAcupointWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.acupoint_name !== newAddAcupointVerified.acupoint_name
        );
      }

      const checkActiveAcupoints = result.some(
        (e) => e.status_type === generalConstants.APPROVED_STATUS_TYPE
      );

      let resultWDelete = result.filter(
        (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
      );
      let resultWoDelete = result.filter(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      resultWDelete = resultWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      resultWoDelete = resultWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      if (checkActiveAcupoints) {
        oldArray[elementsIndex] = {
          ...oldArray[elementsIndex],
          visit_acupuncture_details: [...resultWoDelete, ...resultWDelete],
        };
      } else {
        oldArray[elementsIndex] = {
          ...oldArray[elementsIndex],
          visit_acupuncture_details: [...resultWoDelete, ...resultWDelete],
          price: null,
        };
      }
      setAcupunctureGroupOption(oldArray);
    } else if (columnID === "up") {
      //check whether is a current existing medicine

      let currentWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex - 1]] = [
          currentWoDelete[currentIndex - 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified - 1]] =
          [
            currentWoDelete[newAddVerified - 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        visit_acupuncture_details: [...currentWoDelete, ...currentWDelete],
      };
      setAcupunctureGroupOption(oldArray);
    } else if (columnID === "down") {
      //check whether is a current existing medicine

      let currentWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex + 1]] = [
          currentWoDelete[currentIndex + 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified + 1]] =
          [
            currentWoDelete[newAddVerified + 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        visit_acupuncture_details: [...currentWoDelete, ...currentWDelete],
      };
      setAcupunctureGroupOption(oldArray);
    } else {
      let currentAcupointWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      currentAcupointWoDelete = currentAcupointWoDelete.sort(
        (a, b) => a.row_no - b.row_no
      );
      const selectedAcupoint = currentAcupointWoDelete.map((item, index) => {
        if (index === rowIndex && columnID !== "delete") {
          return {
            ...item,
            [columnID]: value,
          };
        }
        return item;
      });

      const currentAcupointWDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      //need to merge back delete acupooint in order to update
      const mergeSelectedWDeletedAcupoint = [
        ...currentAcupointWDelete,
        ...selectedAcupoint,
      ];

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        visit_acupuncture_details: mergeSelectedWDeletedAcupoint,
      };

      setAcupunctureGroupOption(oldArray);
    }
  };

  //set table data
  const setTreatmentData = (rowIndex, columnID, value) => {
    let visitTreatmenteDetail = [...treatmentList];

    if (columnID === "delete") {
      let result = [...visitTreatmenteDetail];
      //check whether is a current existing treatment

      const currentTreatmentneWoDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentTreatmentVerified = currentTreatmentneWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentTreatmentVerified) {
        const currentTreatmentIndex = result.findIndex(
          (e) => e.id === currentTreatmentVerified.id
        );
        result[currentTreatmentIndex] = {
          ...currentTreatmentVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddTreatmentVerified = currentTreatmentneWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.treatment_name !== newAddTreatmentVerified.treatment_name
        );
      }
      let resultWDelete = result.filter(
        (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
      );
      let resultWoDelete = result.filter(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      resultWDelete = resultWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      resultWoDelete = resultWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      setTreatmentList([...resultWoDelete, ...resultWDelete]);
    } else if (columnID === "up") {
      //check whether is a current existing medicine

      let currentWoDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex - 1]] = [
          currentWoDelete[currentIndex - 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified - 1]] =
          [
            currentWoDelete[newAddVerified - 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      setTreatmentList([...currentWoDelete, ...currentWDelete]);
    } else if (columnID === "down") {
      //check whether is a current existing medicine

      let currentWoDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex + 1]] = [
          currentWoDelete[currentIndex + 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified + 1]] =
          [
            currentWoDelete[newAddVerified + 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      setTreatmentList([...currentWoDelete, ...currentWDelete]);
    } else {
      let currentTreatmentWoDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      currentTreatmentWoDelete = currentTreatmentWoDelete.sort(
        (a, b) => a.row_no - b.row_no
      );
      const selectedVisitTreatment = currentTreatmentWoDelete.map(
        (item, index) => {
          if (index === rowIndex && columnID !== "delete") {
            return {
              ...item,
              [columnID]: value,
            };
          }
          return item;
        }
      );

      const currentTreatmentWDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const mergeSelectedWDeletedTreatment = [
        ...currentTreatmentWDelete,
        ...selectedVisitTreatment,
      ];

      setTreatmentList(mergeSelectedWDeletedTreatment);
    }
  };

  //set table data
  const setMiscData = (rowIndex, columnID, value) => {
    let visitMisceDetail = [...miscList];

    if (columnID === "delete") {
      let result = [...visitMisceDetail];
      //check whether is a current existing misc

      const currentMiscneWoDelete = visitMisceDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMiscVerified = currentMiscneWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMiscVerified) {
        const currentMiscIndex = result.findIndex(
          (e) => e.id === currentMiscVerified.id
        );
        result[currentMiscIndex] = {
          ...currentMiscVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddMiscVerified = currentMiscneWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter((e) => e.name !== newAddMiscVerified.name);
      }

      let resultWDelete = result.filter(
        (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
      );
      let resultWoDelete = result.filter(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      resultWDelete = resultWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      resultWoDelete = resultWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      setMiscList([...resultWoDelete, ...resultWDelete]);
    } else if (columnID === "up") {
      //check whether is a current existing medicine

      let currentWoDelete = visitMisceDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitMisceDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex - 1]] = [
          currentWoDelete[currentIndex - 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified - 1]] =
          [
            currentWoDelete[newAddVerified - 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      setMiscList([...currentWoDelete, ...currentWDelete]);
    } else if (columnID === "down") {
      //check whether is a current existing medicine

      let currentWoDelete = visitMisceDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitMisceDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex + 1]] = [
          currentWoDelete[currentIndex + 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified + 1]] =
          [
            currentWoDelete[newAddVerified + 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      setMiscList([...currentWoDelete, ...currentWDelete]);
    } else {
      let currentMiscWoDelete = visitMisceDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      currentMiscWoDelete = currentMiscWoDelete.sort(
        (a, b) => a.row_no - b.row_no
      );
      const selectedVisitMisc = currentMiscWoDelete.map((item, index) => {
        if (index === rowIndex && columnID !== "delete") {
          return {
            ...item,
            [columnID]: value,
          };
        }
        return item;
      });

      const currentMiscWDelete = visitMisceDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const mergeSelectedWDeletedMisc = [
        ...currentMiscWDelete,
        ...selectedVisitMisc,
      ];

      setMiscList(mergeSelectedWDeletedMisc);
    }
  };

  //set table data

  const setPaymentData = (id, column, value) => {
    if (column === "discount_Amount") {
      setDiscount((p) => ({
        ...p,
        [id]: value,
      }));
    }
    if (column === "extra_Amount") {
      setExtraAmt((p) => ({
        ...p,
        [id]: value,
      }));
    }
  };

  const handleAcupunctureNumInfo = (event, value) => {
    const elementsIndex = memoSelectedAcupunctureGroupIndex;
    //make a copy of group option
    let newArray = [...memoAcupunctureGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setAcupunctureGroupOption(newArray);
  };

  const handleMedicineGroupInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value ? event.target.value : null,
    };

    setGroupOption(newArray);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const handleMedicineGroupNumInfo = (event, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
    //make a copy of group option
    let newArray = [...groupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setGroupOption(newArray);
  };

  function checkDiscount(price, discount, extraAmt) {
    let vals = true;
    Object.keys(price).map((priceKey) => {
      if (price[priceKey] + extraAmt[priceKey] < discount[priceKey]) {
        vals = false;
        return vals;
      }

      return vals;
    });

    return vals;
  }

  const [openPatientHistory, setOpenPatientHistory] = useState(false);

  //override store header

  const openPatientHistoryChange = (value) => {
    if (value) {
      const storeHeader = submisionJSON(
        editOrderInfo,
        amountHeader,
        price,
        discount,
        extraAmt,
        costingHeader,
        groupOption,
        acupunctureGroupOption,
        miscList,
        treatmentList,
        visitNotesDetails,
        payment,
        uploadFileObjects
      );
      setEditOrderInfo(storeHeader.store_header);
    }
    setOpenPatientHistory(value);
  };

  //save and submit
  const onSave = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();
    const checkDiscounts = checkDiscount(price, discount, extraAmt);

    if (!checkDiscounts) {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
      setSubmitErrorAlarm(true);
      setMsgAlarm(t("translation:Invalid Discount"));
    }
    if (checkVal && checkDiscounts) {
      const invalidAddress = groupOption.filter(
        (item) =>
          item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
          item.is_delivery_services === true &&
          item.delivery_road_name == null
      );

      if (invalidAddress.length) {
        const result = Object.groupBy(
          invalidAddress,
          ({ delivery_address }) => delivery_address
        );

        const addressKeys = Object.entries(result);
        setVerifyAddressList(addressKeys);
        setIsSubmitMode(false);
        setIsSaveLoading(true);
        dispatch(
          getVisitVerifyAddress.pending({
            address: addressKeys[0][0] !== "null" ? addressKeys[0][0] : null,
          })
        );
      } else {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: "smooth",
        });
        setOrderSaveDetailView(true);
        setOrderSaveDetailInfo(null);
        dispatch(
          postVisitSaveStoreOrder.pending(
            submisionJSON(
              editOrderInfo,
              amountHeader,
              price,
              discount,
              extraAmt,
              costingHeader,
              groupOption,
              acupunctureGroupOption,
              miscList,
              treatmentList,
              visitNotesDetails,
              payment,
              uploadFileObjects
            )
          )
        );
      }
    }
  };
  const onSubmit = () => {
    const checkDiscounts = checkDiscount(price, discount, extraAmt);

    if (!checkDiscounts) {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
      setSubmitErrorAlarm(true);
      setMsgAlarm(t("translation:Invalid Discount"));
    } else {
      const invalidAddress = groupOption.filter(
        (item) =>
          item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
          item.is_delivery_services === true &&
          item.delivery_road_name == null
      );

      if (invalidAddress.length) {
        const result = Object.groupBy(
          invalidAddress,
          ({ delivery_address }) => delivery_address
        );

        const addressKeys = Object.entries(result);
        setVerifyAddressList(addressKeys);
        setIsSubmitMode(true);
        setIsSaveLoading(true);
        dispatch(
          getVisitVerifyAddress.pending({
            address: addressKeys[0][0] !== "null" ? addressKeys[0][0] : null,
          })
        );
      } else {
        setOrderDetailView(true);
        setOrderDetailInfo(null);
        dispatch(
          postVisitSubmitStoreOrder.pending(
            submisionJSON(
              editOrderInfo,
              amountHeader,
              price,
              discount,
              extraAmt,
              costingHeader,
              groupOption,
              acupunctureGroupOption,
              miscList,
              treatmentList,
              visitNotesDetails,
              payment,
              uploadFileObjects
            )
          )
        );
      }
    }
  };

  const onSubmitWInformalAddr = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (isSubmitMode) {
      setOrderDetailView(true);
      setOrderDetailInfo(null);
      dispatch(
        postVisitSubmitStoreOrder.pending(
          submisionJSON(
            editOrderInfo,
            amountHeader,
            price,
            discount,
            extraAmt,
            costingHeader,
            groupOption,
            acupunctureGroupOption,
            miscList,
            treatmentList,
            visitNotesDetails,
            payment,
            uploadFileObjects
          )
        )
      );
    } else {
      setOrderSaveDetailView(true);
      setOrderSaveDetailInfo(null);
      dispatch(
        postVisitSaveStoreOrder.pending(
          submisionJSON(
            editOrderInfo,
            amountHeader,
            price,
            discount,
            extraAmt,
            costingHeader,
            groupOption,
            acupunctureGroupOption,
            miscList,
            treatmentList,
            visitNotesDetails,
            payment,
            uploadFileObjects
          )
        )
      );
    }
  };

  const onConfirmSubmit = () => {
    setConfirmSubmitOpen(true);
  };

  const onComfirmAddress = () => {
    let newArray = [...memoGroupOption];
    addressChoiceList.groups.map((item) => {
      const elementsIndex = memoGroupOption.findIndex(
        (groupItem) => groupItem.id === item.id && groupItem.name === item.name
      );
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        delivery_address: chosenAddress.address,
        delivery_blk_no: chosenAddress.blk_no,
        delivery_road_name: chosenAddress.road_name,
        delivery_building: chosenAddress.building,
        delivery_postal_code: chosenAddress.postal_code,
      };
    });

    setGroupOption(newArray);
  };

  //useMemo
  const groupOptionAll = () => {
    return groupOption;
  };

  const memoGroupOption = React.useMemo(groupOptionAll, [groupOption]);

  const selectedGroup = () => {
    if (price) {
      return memoGroupOption.find(
        (item) => item.name === group && item.id === groupId
      );
    }
  };

  const memoSelectedGroup = React.useMemo(selectedGroup, [
    memoGroupOption,
    group,
    groupId,
    price,
  ]);

  const selectedGroupIndex = () => {
    return memoGroupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroupIndex = React.useMemo(selectedGroupIndex, [
    memoGroupOption,
    group,
    groupId,
  ]);

  const acupunctureGroupOptionAll = () => {
    return acupunctureGroupOption;
  };

  const memoAcupunctureGroupOption = React.useMemo(acupunctureGroupOptionAll, [
    acupunctureGroupOption,
  ]);

  const selectedAcupunctureGroupIndex = () => {
    return memoAcupunctureGroupOption.findIndex(
      (item) => item.name === acupunctureGroup && item.id === acupunctureGroupId
    );
  };

  const memoSelectedAcupunctureGroupIndex = React.useMemo(
    selectedAcupunctureGroupIndex,
    [memoAcupunctureGroupOption, acupunctureGroup, acupunctureGroupId]
  );

  const selectedAcupunctureGroup = () => {
    return memoAcupunctureGroupOption.find(
      (item) => item.name === acupunctureGroup && item.id === acupunctureGroupId
    );
  };

  const memoSelectedAcupunctureGroup = React.useMemo(selectedAcupunctureGroup, [
    memoAcupunctureGroupOption,
    acupunctureGroup,
    acupunctureGroupId,
  ]);

  //because treatment dont consist of group and it can be used as table data directly
  const treatmentWODelete = () => {
    //filter deleted treatment
    return treatmentList.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
  };
  const memoTreatmentWODelete = React.useMemo(treatmentWODelete, [
    treatmentList,
  ]);

  //because misc dont consist of group and it can be used as table data directly
  const miscWODelete = () => {
    return miscList.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
  };
  const memoMiscWODelete = React.useMemo(miscWODelete, [miscList]);

  const handleCheckIncluded = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.checked,
    };

    setGroupOption(newArray);
  };
  //useEffects

  useEffect(() => {
    prevSubmitVisitOrderStatus.current = false;
    prevSaveVisitOrderStatus.current = false;
    prevChangeBranchTreatmentOnlyStatus.current = false;
    prevChangeBranchAcupointOnlyStatus.current = false;
    prevValidateStatus.current = false;
  }, []);
  useEffect(() => {
    dispatch(getVisitCurrentOrganisation.pending());
    dispatch(getMedicineMeasureUnit.pending());

    dispatch(getDefaultBranch.pending());
    dispatch(
      getVisitPostalCodeSurchargeList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      postVisitPhysicians.pending({
        search_user_id_value: editOrderInfo.physician.id,
        search_user_name_value: null,
        search_username_value: null,
      })
    );
  }, [dispatch, editOrderInfo.physician]);

  // useEffect(() => {
  //   if (editOrderInfo.store_medicine_headers.length) {
  //     editOrderInfo.store_medicine_headers.map((item) => {
  //       if (item.branch) {
  //         console.log(item.branch);
  //         console.log(
  //           visitOrder.dispensaryBranchesAlliance.organisation_branch
  //         );
  //         const availableBranch =
  //           visitOrder.dispensaryBranchesAlliance.organisation_branch.some(
  //             (item) => item.branch.id === item.branch.id
  //           );
  //         //setChoseBranch(true);
  //         console.log(availableBranch);
  //       }
  //     });
  //   }
  // }, [dispatch, editOrderInfo.store_medicine_headers]);

  useEffect(() => {
    if (
      visitOrder.isLoadingPostVisitChangeBranchTreatmentOnly !==
      prevChangeBranchTreatmentOnlyStatus.current
    ) {
      //check the previous ref with current state
      prevChangeBranchTreatmentOnlyStatus.current =
        visitOrder.isLoadingPostVisitChangeBranchTreatmentOnly;

      if (
        visitOrder.postVisitChangeBranchTreatmentOnlyIsSuccess === true &&
        visitOrder.isLoadingPostVisitChangeBranchTreatmentOnly === false
      ) {
        setTreatmentList(
          visitOrder.postVisitChangeBranchTreatmentOnly.visit_treatment_details
        );
      }
    }
  }, [
    visitOrder.postVisitChangeBranchTreatmentOnlyIsSuccess,
    visitOrder.isLoadingPostVisitChangeBranchTreatmentOnly,
    visitOrder.error,
    visitOrder.postVisitChangeBranchTreatmentOnly,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingPostVisitChangeBranchAcupointOnly !==
      prevChangeBranchAcupointOnlyStatus.current
    ) {
      //check the previous ref with current state
      prevChangeBranchAcupointOnlyStatus.current =
        visitOrder.isLoadingPostVisitChangeBranchAcupointOnly;

      if (
        visitOrder.postVisitChangeBranchAcupointOnlyIsSuccess === true &&
        visitOrder.isLoadingPostVisitChangeBranchAcupointOnly === false
      ) {
        setAcupunctureGroupOption(
          visitOrder.postVisitChangeBranchAcupointOnly.visit_acupuncture_headers
        );
      }
    }
  }, [
    visitOrder.postVisitChangeBranchAcupointOnlyIsSuccess,
    visitOrder.isLoadingPostVisitChangeBranchAcupointOnly,
    visitOrder.error,
    visitOrder.postVisitChangeBranchAcupointOnly,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingSaveStoreOrder !== prevSaveVisitOrderStatus.current
    ) {
      //check the previous ref with current state
      prevSaveVisitOrderStatus.current = visitOrder.isLoadingSaveStoreOrder;

      if (
        visitOrder.postVisitSaveStoreOrderIsSuccess === true &&
        visitOrder.isLoadingSaveStoreOrder === false
      ) {
        setOrderSaveDetailView(true);
        setSaveSuccessAlarm(true);
        setSaveErrorAlarm(false);
        setOrderSaveDetailInfo(visitOrder.saveStoreOrder.store_header);

        window.scrollTo(0, 0);
      }
      if (
        visitOrder.postVisitSaveStoreOrderIsSuccess === false &&
        visitOrder.isLoadingSaveStoreOrder === false
      ) {
        setOrderSaveDetailView(false);
        setSaveSuccessAlarm(false);
        setSaveErrorAlarm(true);
        setMsgAlarm(visitOrder.error.saveStoreOrderError);
        // if (
        //   memoSelectedGroup.medicine_type &&
        //   memoSelectedGroup.organisation_branch
        // ) {
        //   const choices = {
        //     medicine_type: memoSelectedGroup.medicine_type,
        //     organisation_branch: memoSelectedGroup.organisation_branch,
        //   };
        //   dispatch(getVisitMedicineTypesAlliance.pending(choices));
        //   dispatch(postVisitFormulasAlliance.pending(choices));
        // }
        window.scrollTo(0, 0);
      }
    }
  }, [
    visitOrder.postVisitSaveStoreOrderIsSuccess,
    visitOrder.isLoadingSaveStoreOrder,
    visitOrder.error,
    visitOrder.saveStoreOrder,
    // memoSelectedGroup,
    // getVisitMedicineTypesAlliance,
    // postVisitFormulasAlliance,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingSubmitStoreOrder !==
      prevSubmitVisitOrderStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitVisitOrderStatus.current = visitOrder.isLoadingSubmitStoreOrder;

      if (
        visitOrder.postVisitSubmitStoreOrderIsSuccess === true &&
        visitOrder.isLoadingSubmitStoreOrder === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setOrderDetailInfo(visitOrder.submitStoreOrder.store_header);
        setOrderDetailView(true);
        window.scrollTo(0, 0);
      }
      if (
        visitOrder.postVisitSubmitStoreOrderIsSuccess === false &&
        visitOrder.isLoadingSubmitStoreOrder === false
      ) {
        setOrderDetailView(false);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(visitOrder.error.submitStoreOrderError);
        window.scrollTo(0, 0);
      }
    }
  }, [
    visitOrder.postVisitSubmitStoreOrderIsSuccess,
    visitOrder.isLoadingSubmitStoreOrder,
    visitOrder.error,
    visitOrder.submitStoreOrder,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingGetVisitVerifyAddress !== prevValidateStatus.current
    ) {
      //check the previous ref with current state
      prevValidateStatus.current = visitOrder.isLoadingGetVisitVerifyAddress;

      if (
        visitOrder.getVisitVerifyAddressIsSuccess === true &&
        visitOrder.isLoadingGetVisitVerifyAddress === false
      ) {
        const temVerifyAddressList = verifyAddressList.filter(
          (item, index) => index !== 0
        );

        setVerifyAddressList(temVerifyAddressList);

        if (Object.keys(visitOrder.getVisitVerifyAddress).length !== 0) {
          if (visitOrder.getVisitVerifyAddress[0].postal_code === "NIL") {
            setVerifyAddressList(null);
            setIsSaveLoading(false);
            //error pop up
            setIsInformalAddressAlert(true);
          } else {
            //let user to choose
            setIsAddressChoiceAlert(true);
            const temAddressChoiceList = {
              addressList: visitOrder.getVisitVerifyAddress,
              groups: verifyAddressList[0][1],
            };
            seAddressChoiceList(temAddressChoiceList);
            setIsSaveLoading(false);
            setChosenAddress(null);
          }
        } else {
          //cant found any address
          setVerifyAddressList(null);
          setIsSaveLoading(false);
          //error pop up
          setIsInformalAddressAlert(true);
        }
      }
      if (
        visitOrder.getVisitVerifyAddressIsSuccess === false &&
        visitOrder.isLoadingGetVisitVerifyAddress === false
      ) {
        setIsSaveLoading(false);
        setVerifyAddressList(null);
        //error pop up
        setIsInformalAddressAlert(true);
      }
    }
  }, [
    visitOrder.getVisitVerifyAddressIsSuccess,
    visitOrder.isLoadingGetVisitVerifyAddress,
    visitOrder.error,
    visitOrder.getVisitVerifyAddress,
  ]);
  useEffect(() => {
    if (
      visitOrder.currentOrganistion &&
      curUser.defaultBranch &&
      visitOrder.postPhysiciansList
    ) {
      let physicianConsultationFee = null;

      if (
        Object.keys(visitOrder.postPhysiciansList.organisation_physician)
          .length !== 0
      ) {
        physicianConsultationFee =
          visitOrder.postPhysiciansList.organisation_physician.find(
            (item) => (item.id = editOrderInfo.physician.id)
          ).consultation_charge;
      }
      const currentOrganistion = visitOrder.currentOrganistion.organisation;
      const roundingTemConsultationFee = organisationPriceRounding(
        Number(
          physicianConsultationFee
            ? physicianConsultationFee
            : curUser.defaultBranch.branch.consultation_charge
        ),
        currentOrganistion.order_cat_amt_rmt,
        currentOrganistion.order_cat_amt_rpvt
      );
      setPrice((p) => ({
        ...p,
        consultationFee: roundingTemConsultationFee,
      }));
    }
  }, [
    curUser.defaultBranch,
    visitOrder.currentOrganistion,
    price.consultationFee,
    setPrice,
    visitOrder.postPhysiciansList,
    editOrderInfo.physician,
  ]);

  useEffect(() => {
    if (visitOrder.currentOrganistion) {
      const currentOrganistion = visitOrder.currentOrganistion.organisation;

      //should not count deleted group
      const temAcupuncturePrice = memoAcupunctureGroupOption.reduce(
        (sum, li) => {
          if (
            li.status_type === generalConstants.APPROVED_STATUS_TYPE &&
            li.visit_acupuncture_details &&
            Object.keys(li.visit_acupuncture_details).length !== 0 &&
            li.visit_acupuncture_details.some(
              (item) =>
                item.status_type === generalConstants.APPROVED_STATUS_TYPE
            )
          ) {
            let temSubTotalRound = organisationPriceRounding(
              li.price,
              currentOrganistion.med_details_price_rmt,
              currentOrganistion.med_details_price_rpvt
            );

            return Number(sum) + Number(temSubTotalRound);
          } else {
            return Number(sum);
          }
        },
        0
      );
      const roundingTemAcupuncturePrice = organisationPriceRounding(
        Number(temAcupuncturePrice),
        currentOrganistion.order_cat_amt_rmt,
        currentOrganistion.order_cat_amt_rpvt
      );
      setPrice((p) => ({
        ...p,
        acupuncturePrice: roundingTemAcupuncturePrice,
      }));
    }
  }, [memoAcupunctureGroupOption, visitOrder.currentOrganistion]);

  useEffect(() => {
    //filter deleted treatment
    if (visitOrder.currentOrganistion) {
      const currentOrganistion = visitOrder.currentOrganistion.organisation;
      const temTreatmentPrice = memoTreatmentWODelete.reduce((sum, li) => {
        let temSubTotalRound = organisationPriceRounding(
          li.quantity * li.unit_selling_price,
          currentOrganistion.med_details_price_rmt,
          currentOrganistion.med_details_price_rpvt
        );

        return Number(sum) + Number(temSubTotalRound);
      }, 0);
      const roundingtemTreatmentPrice = organisationPriceRounding(
        Number(temTreatmentPrice),
        currentOrganistion.order_cat_amt_rmt,
        currentOrganistion.order_cat_amt_rpvt
      );
      setPrice((p) => ({
        ...p,
        treatmentPrice: roundingtemTreatmentPrice,
      }));
    }
  }, [memoTreatmentWODelete, visitOrder.currentOrganistion]);

  useEffect(() => {
    //filter deleted misc
    if (visitOrder.currentOrganistion) {
      const currentOrganistion = visitOrder.currentOrganistion.organisation;
      const temMiscPrice = memoMiscWODelete.reduce((sum, li) => {
        let temSubTotalRound = organisationPriceRounding(
          li.quantity * li.unit_selling_price,
          currentOrganistion.med_details_price_rmt,
          currentOrganistion.med_details_price_rpvt
        );

        return Number(sum) + Number(temSubTotalRound);
      }, 0);
      const roundingtemTreatmentPrice = organisationPriceRounding(
        Number(temMiscPrice),
        currentOrganistion.order_cat_amt_rmt,
        currentOrganistion.order_cat_amt_rpvt
      );
      setPrice((p) => ({
        ...p,
        miscPrice: roundingtemTreatmentPrice,
      }));
    }
  }, [memoMiscWODelete, visitOrder.currentOrganistion]);

  useEffect(() => {
    if (visitOrder.currentOrganistion) {
      const totalFee = parseFloat(
        (
          Number(price.deliveryFee) +
          Number(price.brewingFee) +
          Number(price.consultationFee) +
          Number(price.medicineTab) +
          Number(price.vendorGstAmt) +
          Number(price.acupuncturePrice) +
          Number(price.treatmentPrice) +
          Number(price.miscPrice)
        ).toFixed(10)
      );

      const totalDiscount = parseFloat(
        (
          Number(discount.deliveryFee) +
          Number(discount.brewingFee) +
          Number(discount.consultationFee) +
          Number(discount.medicineTab) +
          Number(discount.acupuncturePrice) +
          Number(discount.treatmentPrice) +
          Number(discount.miscPrice)
        ).toFixed(10)
      );

      const totalExtra = parseFloat(
        (
          Number(extraAmt.deliveryFee) +
          Number(extraAmt.brewingFee) +
          Number(extraAmt.consultationFee) +
          Number(extraAmt.medicineTab) +
          Number(extraAmt.acupuncturePrice) +
          Number(extraAmt.treatmentPrice) +
          Number(extraAmt.miscPrice)
        ).toFixed(10)
      );

      const priceWOdiscount = parseFloat(
        (Number(totalFee) - Number(totalDiscount) + Number(totalExtra)).toFixed(
          10
        )
      );
      const currentOrganistion = visitOrder.currentOrganistion.organisation;
      const amountHeader = organisationPriceRoundingWGst(
        Number(priceWOdiscount),
        currentOrganistion,
        Number(payment.discountInPrecent)
      );

      setAmountHeader(amountHeader);
    }
  }, [price, discount, extraAmt, payment, visitOrder.currentOrganistion]);

  if (
    visitOrder.isLoadingSaveStoreOrder !== true &&
    visitOrder.isLoadingSubmitStoreOrder !== true &&
    !orderDetailView &&
    !orderSaveDetailView
  ) {
    return (
      <div>
        {/* <Collapse in={saveSuccessAlarm || addSaveSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSaveSuccessAlarm(false);
                  setAddSaveSuccessAlarm(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {t("translation:Order has been saved")}
          </Alert>
        </Collapse> */}

        <SubmissionNotificationDialog open={saveErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSaveErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </SubmissionNotificationDialog>

        <SubmissionNotificationDialog open={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </SubmissionNotificationDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={isAddressChoiceAlert}
          setOpen={setIsAddressChoiceAlert}
          onConfirm={onComfirmAddress}>
          {t("translation:Please choose the delivery address")}.
          {addressChoiceList !== null && (
            <Grid item xs={12} className={classes.subTotalInfoField}>
              <Autocomplete
                name="Address"
                disableClearable
                required
                options={addressChoiceList?.addressList}
                getOptionLabel={(option) =>
                  option.address ? option.address : ""
                }
                value={chosenAddress}
                isOptionEqualToValue={(option, value) => {
                  if (option.address === value.address) return option;
                }}
                onChange={(e, option) => {
                  setChosenAddress(option);
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Address")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={isInformalAddressAlert}
          setOpen={setIsInformalAddressAlert}
          onConfirm={onSubmitWInformalAddr}>
          {t("translation:Found an informal address")}.
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmSubmitOpen}
          setOpen={setConfirmSubmitOpen}
          onConfirm={onSubmit}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        {editMedicalOpen && (
          <EditUserMedicalDialog
            orderDetailInfo={editOrderInfo}
            setEditOrderInfo={setEditOrderInfo}
            setEditMedicalOpen={setEditMedicalOpen}
            editMedicalOpen={editMedicalOpen}
          />
        )}
        <form
          onSubmit={handleSubmit(onConfirmSubmit)}
          onKeyDown={(e) => noEnterSubmitForm(e)}>
          <Grid className={classes.gridRoot} container>
            <Grid item xs={6}>
              <Typography display="block" variant="h4">
                {editOrderInfo.order_number}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <PageAction
                submissionTitle={t("translation:Submit to Dispensary Branch")}
                onSave={onSave}
                isSaveLoading={isSaveLoading}
                setView={() => setOrderEditView(false)}
              />
            </Grid>
            <Typography display="block" variant="h4">
              {editOrderInfo.physician.name}
            </Typography>
            <Grid item xs={12}>
              <Panel>
                <Grid container>
                  <Grid item>
                    <Link className={classes.boldMaroon075}>
                      <Button
                        className={classes.boldMaroon075}
                        onClick={() => setEditMedicalOpen(true)}>
                        {t("translation:Edit Allergy & Medical History")}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <PatientInfoTable
                  name={editOrderInfo.staff.name}
                  date_of_birth={editOrderInfo.staff.date_of_birth}
                  gender_type={editOrderInfo.staff.gender_type}
                  marital_type={editOrderInfo.staff.marital_type}
                  occupation={editOrderInfo.staff.occupation}
                  allergy={editOrderInfo.staff.allergy}
                  medical_history={editOrderInfo.staff.medical_history}
                  t={t}
                />
              </Panel>
            </Grid>
            <br />

            <PatientHistoryDialog
              staffId={editOrderInfo.staff.id}
              handleCopyOnClick={handleCopyOnClick}
              openPatientHistory={openPatientHistory}
              setOpenPatientHistory={openPatientHistoryChange}
              copyableMode={true}
            />
            <Grid item xs={3}>
              <Panel>
                <EditVisitNotesDetails
                  visitNotesDetails={visitNotesDetails}
                  setVisitNotesDetails={setVisitNotesDetails}
                />
                <EditMcIssued
                  visitNotesDetails={visitNotesDetails}
                  setVisitNotesDetails={setVisitNotesDetails}
                />
                <EditMdIssued
                  visitNotesDetails={visitNotesDetails}
                  setVisitNotesDetails={setVisitNotesDetails}
                />

                {visitOrder.currentOrganistion && (
                  <EditVisitAttachment
                    organisationCode={
                      visitOrder.currentOrganistion.organisation.codename
                    }
                    folderPath={"visit_attachment"}
                    uploadFileObjects={uploadFileObjects}
                    setUploadFileObjects={setUploadFileObjects}
                  />
                )}
                {visitOrder.currentOrganistion && (
                  <DownloadVisitAttachment
                    organisationCode={
                      visitOrder.currentOrganistion.organisation.codename
                    }
                    folderPath={"visit_attachment"}
                    uploadFileObjects={uploadFileObjects}
                  />
                )}
              </Panel>
            </Grid>
            <Grid item xs={9}>
              <TabContext value={tabValue}>
                <Grid item xs={12}>
                  <StyledTabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth">
                    {visitOrder.currentOrganistion &&
                      typeof amountHeader.amt_after_discount === "number" && (
                        <StyledTab
                          className={classes.defualtCursor}
                          value="0"
                          label={
                            <span>
                              <Typography display="block" variant="subtitle1">
                                {t("translation:MEDICINE")}
                              </Typography>

                              <Typography display="block" variant="subtitle1">
                                $ {price.medicineTab}
                              </Typography>
                            </span>
                          }></StyledTab>
                      )}

                    {visitOrder.currentOrganistion &&
                      typeof amountHeader.amt_after_discount === "number" && (
                        <StyledTab
                          value="1"
                          label={
                            <span>
                              <Typography display="block" variant="subtitle1">
                                {t("translation:ACUPUNCTURE")}
                              </Typography>

                              <Typography display="block" variant="subtitle1">
                                $ {price.acupuncturePrice}
                              </Typography>
                            </span>
                          }
                        />
                      )}
                    {visitOrder.currentOrganistion &&
                      typeof amountHeader.amt_after_discount === "number" && (
                        <StyledTab
                          value="2"
                          label={
                            <span>
                              <Typography display="block" variant="subtitle1">
                                {t("translation:TREATMENT")}
                              </Typography>

                              <Typography display="block" variant="subtitle1">
                                $ {price.treatmentPrice}
                              </Typography>
                            </span>
                          }
                        />
                      )}
                    {visitOrder.currentOrganistion &&
                      typeof amountHeader.amt_after_discount === "number" && (
                        <StyledTab
                          value="3"
                          label={
                            <span>
                              <Typography display="block" variant="subtitle1">
                                {t("translation:PAYMENT")}
                              </Typography>

                              <Typography display="block" variant="subtitle1">
                                ${" "}
                                {organisationPriceRounding(
                                  amountHeader.amt_with_gst,
                                  visitOrder.currentOrganistion.organisation
                                    .amt_after_discount_in_percent_rmt,
                                  visitOrder.currentOrganistion.organisation
                                    .amt_after_discount_in_percent_rpvt
                                )}
                              </Typography>
                            </span>
                          }
                        />
                      )}
                  </StyledTabs>
                </Grid>

                <TabPanel className={classes.gridRoot} value="0">
                  <Panel>
                    <GroupOption
                      currentOrganistion={visitOrder.currentOrganistion}
                      memoGroupOption={memoGroupOption}
                      groupOption={groupOption}
                      setGroupOption={setGroupOption}
                      group={group}
                      setGroup={setGroup}
                      memoSelectedGroupIndex={memoSelectedGroupIndex}
                      medicineList={medicineList}
                      setMedicineList={setMedicineList}
                      memoSelectedGroup={memoSelectedGroup}
                      setShowBrewing={setShowBrewing}
                      setShowDelivery={setShowDelivery}
                      setIsRawHerb={setIsRawHerb}
                      setGroupId={setGroupId}
                      groupId={groupId}
                      getDispensaryBranchesAlliance={
                        getVisitDispensaryBranchesAlliance
                      }
                      getMedicineTypesAlliance={getVisitMedicineTypesAlliance}
                      postFormulasAlliance={postVisitFormulasAlliance}
                      postMedicineFormulasAlliance={
                        postVisitMedicineFormulasAlliance
                      }
                      postBranchMedicines={postVisitBranchMedicines}
                      order={visitOrder}
                      physicianMultiplier={
                        visitOrder.postPhysiciansList &&
                        Object.keys(
                          visitOrder.postPhysiciansList.organisation_physician
                        ).length !== 0
                          ? visitOrder.postPhysiciansList.organisation_physician.find(
                              (item) => (item.id = editOrderInfo.physician.id)
                            ).multiplier
                          : null
                      }
                      showMultiplier={true}
                      showChangeBranch={true}
                    />
                  </Panel>

                  {Object.keys(medicineList).length !== 0 &&
                    visitOrder.dispensaryBranchesAlliance &&
                    Object.keys(
                      visitOrder.dispensaryBranchesAlliance.organisation_branch
                    ).length !== 0 &&
                    common.medicineMeasureUnitList && (
                      <div>
                        <Panel>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              className={classes.userInfoField}>
                              <MedicineListTable
                                t={t}
                                currentOrganistionBranch={
                                  memoSelectedGroup.organisation_branch
                                }
                                order={visitOrder}
                                data={medicineList.sort(
                                  (a, b) => a.row_no - b.row_no
                                )}
                                isRawHerb={isRawHerb}
                                number_of_days={
                                  memoSelectedGroup.number_of_days
                                }
                                setData={setMedicineData}
                                measureUnit={
                                  common.medicineMeasureUnitList.measure_units
                                }
                                infoModule="VISIT"
                              />
                            </Grid>

                            <GroupMedicineInfo
                              memoGroupOption={memoGroupOption}
                              handleMedicineGroupNumInfo={
                                handleMedicineGroupNumInfo
                              }
                              handleMedicineGroupInfo={handleMedicineGroupInfo}
                              memoSelectedGroupIndex={memoSelectedGroupIndex}
                              memoSelectedGroup={memoSelectedGroup}
                              setGroupOption={setGroupOption}
                              module="VISIT"
                            />
                          </Grid>
                          <GroupTotal
                            handleMedicineGroupNumInfo={
                              handleMedicineGroupNumInfo
                            }
                            price={price}
                            memoSelectedGroup={memoSelectedGroup}
                            showMultiplier={true}
                          />
                        </Panel>
                        {(showBrewing ||
                          showDelivery ||
                          memoSelectedGroup.delivery_incl_prescription ===
                            true ||
                          memoSelectedGroup.delivery_incl_receipt === true) && (
                          <Panel>
                            <Grid
                              item
                              xs={12}
                              className={classes.userInfoField}>
                              <FormControlLabel
                                control={
                                  <StyledCheckbox
                                    checked={
                                      memoSelectedGroup.delivery_incl_prescription
                                        ? true
                                        : false
                                    }
                                    onChange={handleCheckIncluded}
                                    name="delivery_incl_prescription"
                                    cy_data="deliveryInclPrescription"
                                  />
                                }
                                label={t(
                                  "translation:Delivery Include Prescription"
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.userInfoField}>
                              <FormControlLabel
                                control={
                                  <StyledCheckbox
                                    checked={
                                      memoSelectedGroup.delivery_incl_receipt
                                        ? true
                                        : false
                                    }
                                    onChange={handleCheckIncluded}
                                    name="delivery_incl_receipt"
                                    cy_data="deliveryInclInvoice"
                                  />
                                }
                                label={t(
                                  "translation:Delivery Include Invoice"
                                )}
                              />
                            </Grid>
                            <EditBrewing
                              memoSelectedGroupIndex={memoSelectedGroupIndex}
                              memoSelectedGroup={memoSelectedGroup}
                              memoGroupOption={memoGroupOption}
                              groupOption={groupOption}
                              setGroupOption={setGroupOption}
                              setPrice={setPrice}
                              showBrewing={showBrewing}
                              handleMedicineGroupInfo={handleMedicineGroupInfo}
                              order={visitOrder}
                            />
                            <EditDelivery
                              memoSelectedGroup={memoSelectedGroup}
                              memoSelectedGroupIndex={memoSelectedGroupIndex}
                              memoGroupOption={memoGroupOption}
                              setGroupOption={setGroupOption}
                              editOrderInfo={editOrderInfo}
                              setPrice={setPrice}
                              user={user}
                              showDelivery={showDelivery}
                              handleMedicineGroupInfo={handleMedicineGroupInfo}
                              order={visitOrder}
                              module="VISIT"
                              confirmSubmitOpen={confirmSubmitOpen}
                            />

                            <SharedDelivery
                              memoSelectedGroup={memoSelectedGroup}
                              memoSelectedGroupIndex={memoSelectedGroupIndex}
                              memoGroupOption={memoGroupOption}
                              setGroupOption={setGroupOption}
                            />
                          </Panel>
                        )}
                      </div>
                    )}

                  {memoSelectedGroup && (
                    <ProceedDay
                      memoSelectedGroup={memoSelectedGroup}
                      memoGroupOption={memoGroupOption}
                      setGroupOption={setGroupOption}
                      memoSelectedGroupIndex={memoSelectedGroupIndex}
                      module="VISIT"
                    />
                  )}
                  {visitOrder.currentOrganistion &&
                    visitOrder.dispensaryBranchesAlliance &&
                    Object.keys(
                      visitOrder.dispensaryBranchesAlliance.organisation_branch
                    ).length !== 0 &&
                    visitOrder.getVisitPostalCodeSurchargeList && (
                      <AllianceTotalPrice
                        medicineList={medicineList}
                        memoGroupOption={memoGroupOption}
                        memoSelectedGroup={memoSelectedGroup}
                        group={group}
                        setGroupOption={setGroupOption}
                        price={price}
                        setPrice={setPrice}
                        memoSelectedGroupIndex={memoSelectedGroupIndex}
                        order={visitOrder}
                        costingHeader={costingHeader}
                        setCostingHeader={setCostingHeader}
                        setIsSaveLoading={setIsSaveLoading}
                      />
                    )}
                  <Note
                    groupPrice={true}
                    prescriptionTimes={true}
                    noOfPacks={true}
                  />
                </TabPanel>

                <TabPanel className={classes.gridRoot} value="1">
                  <Panel>
                    <AcupunctureGroupOption
                      memoAcupunctureGroupOption={memoAcupunctureGroupOption}
                      acupunctureGroupOption={acupunctureGroupOption}
                      setAcupunctureGroupOption={setAcupunctureGroupOption}
                      acupunctureGroup={acupunctureGroup}
                      setAcupunctureGroup={setAcupunctureGroup}
                      memoSelectedAcupunctureGroupIndex={
                        memoSelectedAcupunctureGroupIndex
                      }
                      acupointList={acupointList}
                      setAcupointList={setAcupointList}
                      memoSelectedAcupunctureGroup={
                        memoSelectedAcupunctureGroup
                      }
                      acupunctureGroupId={acupunctureGroupId}
                      setAcupunctureGroupId={setAcupunctureGroupId}
                    />
                  </Panel>

                  {Object.keys(acupointList).length !== 0 && (
                    <>
                      <Panel>
                        <Grid item xs={12} className={classes.userInfoField}>
                          <AcupointListTable
                            t={t}
                            data={acupointList.sort(
                              (a, b) => a.row_no - b.row_no
                            )}
                            setData={setAcupointsData}
                            infoModule="VISIT"
                          />
                        </Grid>
                      </Panel>

                      <Panel>
                        <Grid item xs={12} className={classes.userInfoField}>
                          <GroupAcupunctureInfo
                            memoSelectedAcupunctureGroup={
                              memoSelectedAcupunctureGroup
                            }
                            handleAcupunctureNumInfo={handleAcupunctureNumInfo}
                            acupointList={acupointList}
                          />
                        </Grid>
                      </Panel>
                    </>
                  )}
                </TabPanel>

                <TabPanel className={classes.gridRoot} value="2">
                  <Panel>
                    <EditTreatment
                      treatmentList={treatmentList}
                      setTreatmentList={setTreatmentList}
                    />
                  </Panel>
                  {visitOrder.currentOrganistion &&
                    Object.keys(memoTreatmentWODelete).length !== 0 && (
                      <>
                        <Panel>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <TreatmentListTable
                              t={t}
                              data={memoTreatmentWODelete.sort(
                                (a, b) => a.row_no - b.row_no
                              )}
                              setData={setTreatmentData}
                              med_details_price_rmt={
                                visitOrder.currentOrganistion.organisation
                                  .med_details_price_rmt
                              }
                              med_details_price_rpvt={
                                visitOrder.currentOrganistion.organisation
                                  .med_details_price_rpvt
                              }
                              infoModule="VISIT"
                            />
                          </Grid>
                        </Panel>
                      </>
                    )}
                </TabPanel>

                <TabPanel className={classes.gridRoot} value="3">
                  <Panel>
                    <EditMiscFee
                      miscList={miscList}
                      setMiscList={setMiscList}
                    />
                  </Panel>
                  {visitOrder.currentOrganistion &&
                    Object.keys(memoMiscWODelete).length !== 0 && (
                      <>
                        <Panel>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <MiscListTable
                              t={t}
                              data={memoMiscWODelete.sort(
                                (a, b) => a.row_no - b.row_no
                              )}
                              setData={setMiscData}
                              med_details_price_rmt={
                                visitOrder.currentOrganistion.organisation
                                  .med_details_price_rmt
                              }
                              med_details_price_rpvt={
                                visitOrder.currentOrganistion.organisation
                                  .med_details_price_rpvt
                              }
                            />
                          </Grid>
                        </Panel>
                      </>
                    )}

                  <Panel>
                    <PaymentTable
                      price={price}
                      discount={discount}
                      extraAmt={extraAmt}
                      setPaymentData={setPaymentData}
                      t={t}
                    />
                  </Panel>

                  <Panel>
                    {visitOrder.currentOrganistion && (
                      <PaymentTotal
                        payment={payment}
                        setPayment={setPayment}
                        amountHeader={amountHeader}
                        currentOrganistion={
                          visitOrder.currentOrganistion.organisation
                        }
                        gst_value={
                          visitOrder.currentOrganistion.organisation.gst_value
                        }
                      />
                    )}
                  </Panel>
                </TabPanel>
              </TabContext>
            </Grid>
            <RequiredNote />
            <PageAction
              submissionTitle={t("translation:Submit to Dispensary Branch")}
              onSave={onSave}
              isSaveLoading={isSaveLoading}
              setView={() => setOrderEditView(false)}
            />
          </Grid>
        </form>
      </div>
    );
  } else if (orderDetailInfo && orderDetailView) {
    //submit successfully and rendering detail page
    return (
      <div>
        <SubmissionNotificationDialog open={submitSuccessAlarm}>
          <Alert
            onClick={() => {
              setSubmitSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSubmitOrder">
            {t("translation:Order has been submitted")}
          </Alert>
        </SubmissionNotificationDialog>
        {visitOrder.submitStoreOrder && (
          <OrderDetailPage
            orderDetailInfo={orderDetailInfo}
            setOrderDetailInfo={setOrderDetailInfo}
            setOrderDetailView={setOrderEditView}
          />
        )}
      </div>
    );
  } else if (orderSaveDetailInfo && orderSaveDetailView) {
    //save successfully and rendering detail page
    return (
      <div>
        <SubmissionNotificationDialog open={saveSuccessAlarm}>
          <Alert
            onClick={() => {
              setSaveSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSaveOrder">
            {t("translation:Order has been saved")}
          </Alert>
        </SubmissionNotificationDialog>
        {visitOrder.saveStoreOrder && (
          <OrderDetailPage
            orderDetailInfo={orderSaveDetailInfo}
            setOrderDetailView={setOrderEditView}
          />
        )}
      </div>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
};

export default OrderEditPage;
