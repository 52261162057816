import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DELIVERYORDERSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERSUMMARYLIST"
);

export const GET_DELIVERYORDERBYID_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERBYID"
);

export const GET_DELIVERYORDERLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERLIST"
);

export const POST_SAVEDELIVERYORDER_ASYNC = createAsyncTypes(
  "POST_SAVETDELIVERYORDER"
);

export const POST_SUBMITDELIVERYORDER_ASYNC = createAsyncTypes(
  "POST_SUBMITDELIVERYORDER"
);

export const POST_SAVEDELIVERYORDERWCHECK_ASYNC = createAsyncTypes(
  "POST_SAVETDELIVERYORDERWCHECK"
);

export const POST_SUBMITDELIVERYORDERWCHECK_ASYNC = createAsyncTypes(
  "POST_SUBMITDELIVERYORDERWCHECK"
);

export const POST_SUBMITALLDELIVERY_ASYNC = createAsyncTypes(
  "POST_SUBMITALLDELIVERY"
);

export const PUT_DELIVERYORDERWIPMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDERWIPMODE"
);

export const PUT_DELIVERYORDEREDITMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDEREDITMODE"
);

export const PUT_DELIVERYORDERPENDINGMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDERPENDINGMODE"
);

export const GET_DELIVERYCOURIERLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYCOURIERLIST"
);

export const GET_DELIVERYCOURIERBYBRANCH_ASYNC = createAsyncTypes(
  "GET_DELIVERYCOURIERBYBRANCH"
);

export const GET_DELIVERYORDERBYSTOREHEADERID_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERBYSTOREHEADERID"
);

export const GET_STOREHEADERSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_STOREHEADERSUMMARYLIST"
);

export const GET_STOREHEADERLIST_ASYNC = createAsyncTypes(
  "GET_STOREHEADERLIST"
);

export const PUT_DELIVERYORDERPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDERPRINTDATE"
);

export const PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC =
  createAsyncTypes("PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS");

export const POST_EXPORTDELIVERYORDERS_ASYNC = createAsyncTypes(
  "POST_EXPORTDELIVERYORDERS"
);

export const POST_EXPORTDELIVERYORDERSV2_ASYNC = createAsyncTypes(
  "POST_EXPORTDELIVERYORDERSV2"
);

export const GET_ROUTINGSERVICEPAYLOAD_ASYNC = createAsyncTypes(
  "GET_ROUTINGSERVICEPAYLOAD"
);

export const GET_GEOMETRYVALUEPAYLOAD_ASYNC = createAsyncTypes(
  "GET_GEOMETRYVALUEPAYLOAD"
);

export const GET_ROUTEBYGRIDPAYLOAD_ASYNC = createAsyncTypes(
  "GET_ROUTEBYGRIDPAYLOAD"
);

export const GET_AREACLUSTERPAYLOAD_ASYNC = createAsyncTypes(
  "GET_AREACLUSTERPAYLOAD"
);

export const GET_ONEMAPSTATICMAPPAYLOAD_ASYNC = createAsyncTypes(
  "GET_ONEMAPSTATICMAPPAYLOAD"
);

export const GET_DELIVERYORDERPOSTALCODES_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERPOSTALCODES"
);

export const GET_GOOGLESTATICMAPPAYLOAD_ASYNC = createAsyncTypes(
  "GET_GOOGLESTATICMAPPAYLOAD"
);

export const GET_DELIVERYOPTIMIZEDROUTING_ASYNC = createAsyncTypes(
  "GET_DELIVERYOPTIMIZEDROUTING"
);

export const GET_DELIVERYOPTIMIZEDROUTINGMAP_ASYNC = createAsyncTypes(
  "GET_DELIVERYOPTIMIZEDROUTINGMAP"
);

export const GET_DELIVERYVERIFYADDRESS_ASYNC = createAsyncTypes(
  "GET_DELIVERYVERIFYADDRESS"
);

export const PUT_UPDATEALLSTOREDELIVERYADDRESS_ASYNC = createAsyncTypes(
  "PUT_UPDATEALLSTOREDELIVERYADDRESS"
);

export const POST_SEARCHCSVCOURIERDELIVERYADDRESS_ASYNC = createAsyncTypes(
  "POST_SEARCHCSVCOURIERDELIVERYADDRESS"
);

export const POST_SEARCHCOURIERDELIVERYADDRESS_ASYNC = createAsyncTypes(
  "POST_SEARCHCOURIERDELIVERYADDRESS"
);

export const PUT_UPDATEDELIVERYTRACKINGROWNO_ASYNC = createAsyncTypes(
  "PUT_UPDATEDELIVERYTRACKINGROWNO"
);

export const GET_DELIVERYORDERVERIFYADDRESSLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERVERIFYADDRESSLIST"
);
