//view_need_type

//permission_type 1=READ 2=CREATE 3=UPDATE 4=DELETE

export const OWN_VIEW_TYPE = 1;
export const ALL_VIEW_TYPE = 2;

//status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
export const NEW_STATUS_TYPE = 1;
export const PENDING_STATUS_TYPE = 2;
export const APPROVED_STATUS_TYPE = 3;
export const WIP_STATUS_TYPE = 4;
export const PRINTOUT_STATUS_TYPE = 5;
export const CANCELED_STATUS_TYPE = 6;
export const DELETED_STATUS_TYPE = 7;
export const CLOSED_STATUS_TYPE = 8;
//permission_type 1=READ 2=CREATE 3=UPDATE 4=DELETE

export const NO_PERMISSION_TYPE = 0;
export const READ_PERMISSION_TYPE = 1;
export const CREATE_PERMISSION_TYPE = 2;
export const UPDATE_PERMISSION_TYPE = 3;
export const DELETE_PERMISSION_TYPE = 4;

//right_type
// ADMIN_USER = 1
// ADMIN_ROLE = 2
// ADMIN_USER_ROLE = 3
// ADMIN_ROLE_RIGHTS = 4
// ADMIN_MEDICINE_TYPE = 5
// ADMIN_MEDICINE = 6
// ADMIN_PAYMENT_METHOD = 7
// ADMIN_PAYMENT_TERM = 8
// ADMIN_BRANCH = 9
// ADMIN_BRANCH_MEDICINE = 10
// ADMIN_ORGANISATION = 11
// ADMIN_ORGANISATION_BRANCH = 12
// ADMIN_ORGANISATION_USER = 13
// ADMIN_USER_ORGANISATION = 14
// WORK_ORDER_STORE = 15
// ADMIN_COURIER = 16
// ADMIN_BRANCH_COURIER = 17
// WORK_ORDER_DISPENSARY = 18
// WORK_ORDER_BREWING = 19
// WORK_ORDER_DELIVERY = 20
// MAINTENANCE_MODE_ACCESS = 21
// ADMIN_ALLIANCE = 22
// ADMIN_ALLIANCE_ORGANISATION = 23
// ADMIN_FORMULA = 24
// ADMIN_FORMULA_MEDICINE = 25
// WORK_ORDER_VISIT = 26
// ADMIN_ACUPOINT = 27
// ADMIN_TREATMENT = 28
// WORK_ORDER_INVOICE = 29
// WORK_ORDER_INVOICE_MEDICINE_COSTING = 30
// ADMIN_ORGANISATION_PHYSICIAN = 31
// ADMIN_USER_EDUCATION = 32
// Appointment [20220429]
// WORK_ORDER_APPOINTMENT = 33
// ADMIN_PHYSICIAN = 34
// ADMIN_BRANCH_SCHEDULE = 35
// ADMIN_BRANCH_PHYSICIAN_SCHEDULE = 36
// ADMIN_BRANCH_ADHOC_SCHEDULE = 37
// ADMIN_BRANCH_PHYSICIAN_ADHOC_SCHEDULE = 38
// ADMIN_EMAIL_GROUP = 39
// ADMIN_EMAIL_GROUP_USER = 40
// ADMIN_TREATMENT_TYPE = 41
// ADMIN_USER_CREDIT = 42;
// ADMIN_ORGANISATION_STRIPE = 43;
// WORK_ORDER_RECEIPT = 44;
// ADMIN_SUPPLIER = 45;
// WORK_ORDER_ORDER_FORM = 46;
// WORK_ORDER_PURCHASE_ORDER = 47;
// ADMIN_SUPPLIER_PAYMENT_METHOD = 48;
// ADMIN_SUPPLIER_PAYMENT_TERM = 49;
// DASHBOARD = 50
// HOMECARE_SYNC = 51
// REPORT_WORK_ORDER = 52
export const NO_RIGHT_TYPE = 0;
export const ADMIN_USER_RIGHT_TYPE = 1;
export const ADMIN_ROLE_RIGHT_TYPE = 2;
export const ADMIN_USER_ROLE_RIGHT_TYPE = 3;
export const ADMIN_ROLE_RIGHTS_RIGHT_TYPE = 4;
export const ADMIN_MEDICINE_TYPE_RIGHT_TYPE = 5;
export const ADMIN_MEDICINE_RIGHT_TYPE = 6;
export const ADMIN_PAYMENT_METHOD_RIGHT_TYPE = 7;
export const ADMIN_PAYMENT_TERM_RIGHT_TYPE = 8;
export const ADMIN_BRANCH_RIGHT_TYPE = 9;
export const ADMIN_BRANCH_MEDICINE_RIGHT_TYPE = 10;
export const ADMIN_ORGANISATION_RIGHT_TYPE = 11;
export const ADMIN_ORGANISATION_BRANCH_RIGHT_TYPE = 12;
export const ADMIN_ORGANISATION_USER_RIGHT_TYPE = 13;
export const ADMIN_USER_ORGANISATION_RIGHT_TYPE = 14;
export const WORK_ORDER_STORE_RIGHT_TYPE = 15;
export const ADMIN_COURIER_RIGHT_TYPE = 16;
export const ADMIN_BRANCH_COURIER_RIGHT_TYPE = 17;
export const WORK_ORDER_DISPENSARY_RIGHT_TYPE = 18;
export const WORK_ORDER_BREWING_RIGHT_TYPE = 19;
export const WORK_ORDER_DELIVERY_RIGHT_TYPE = 20;
export const MAINTENANCE_MODE_ACCESS_RIGHT_TYPE = 21;
export const ADMIN_ALLIANCE_RIGHT_TYPE = 22;
export const ADMIN_ALLIANCE_ORGANISATION_RIGHT_TYPE = 23;
export const ADMIN_FORMULA_RIGHT_TYPE = 24;
export const ADMIN_FORMULA_MEDICINE_RIGHT_TYPE = 25;
export const WORK_ORDER_VISIT_RIGHT_TYPE = 26;
export const ADMIN_ACUPOINT_RIGHT_TYPE = 27;
export const ADMIN_TREATMENT_RIGHT_TYPE = 28;
export const WORK_ORDER_INVOICE_RIGHT_TYPE = 29;
export const WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE = 30;
export const ADMIN_USER_EDUCATION_RIGHT_TYPE = 31;
export const WORK_ORDER_APPOINTMENT_RIGHT_TYPE = 32;
export const ADMIN_PHYSICIAN_RIGHT_TYPE = 33;
export const ADMIN_BRANCH_SCHEDULE_RIGHT_TYPE = 34;
export const ADMIN_BRANCH_PHYSICIAN_SCHEDULE_RIGHT_TYPE = 35;
export const ADMIN_BRANCH_ADHOC_SCHEDULE_RIGHT_TYPE = 36;
export const ADMIN_BRANCH_PHYSICIAN_ADHOC_SCHEDULE_RIGHT_TYPE = 37;
export const ADMIN_EMAIL_GROUP_RIGHT_TYPE = 38;
export const ADMIN_EMAIL_GROUP_USER_RIGHT_TYPE = 39;
export const ADMIN_TREATMENT_TYPE_RIGHT_TYPE = 40;
export const ADMIN_USER_CREDIT_RIGHT_TYPE = 41;
export const ADMIN_ORGANISATION_STRIPE_RIGHT_TYPE = 42;
export const WORK_ORDER_RECEIPT_RIGHT_TYPE = 43;
export const ADMIN_SUPPLIER_RIGHT_TYPE = 44;
export const WORK_ORDER_ORDER_FORM_RIGHT_TYPE = 45;
export const WORK_ORDER_PURCHASE_ORDER_RIGHT_TYPE = 46;
export const ADMIN_SUPPLIER_PAYMENT_METHOD_RIGHT_TYPE = 47;
export const ADMIN_SUPPLIER_PAYMENT_TERM_RIGHT_TYPE = 48;
export const DASHBOARD_RIGHT_TYPE = 49;
//export const HOMECARE_SYNC_RIGHT_TYPE = 50;
//export const REPORT_WORK_ORDER_RIGHT_TYPE = 51; not in used
export const ADMIN_CLOUD_STORAGE_RIGHT_TYPE = 50;
export const ADMIN_CLOUD_STORAGE_FOLDER_RIGHT_TYPE = 51;
export const ADMIN_PRESCRIPTION_MESSAGE_RIGHT_TYPE = 52;
export const ADMIN_SYSTEM_SCHEDULE_RIGHT_TYPE = 53;
export const ADMIN_WIKI_DATA_RIGHT_TYPE = 54;
export const ADMIN_BRANCH_ROOM_RIGHT_TYPE = 55;
export const ADMIN_NOTIFICATION_RIGHT_TYPE = 56;
export const ADMIN_USER_API_KEY_RIGHT_TYPE = 57;
export const ADMIN_POSTAL_CODE_SURCHARGE_RIGHT_TYPE = 58;
export const ADMIN_ORGANISATION_TWILIO_RIGHT_TYPE = 59;
export const ADMIN_FILTER_RULE_RIGHT_TYPE = 60;
export const ADMIN_ORGANISATION_SMTP_RIGHT_TYPE = 61;
export const ADMIN_COMMISSION_SCHEME_RIGHT_TYPE = 62;
export const REPORT_ACUPOINT_RIGHT_TYPE = 63;
export const REPORT_MEDICINE_RIGHT_TYPE = 64;
export const REPORT_TREATMENT_RIGHT_TYPE = 65;
export const REPORT_FORMULA_RIGHT_TYPE = 66;
export const REPORT_FORMULA_MEDICINE_RIGHT_TYPE = 67;
export const REPORT_BRANCH_MEDICINE_RIGHT_TYPE = 68;
export const REPORT_COMMISSION_SCHEME_RIGHT_TYPE = 69;
export const REPORT_USER_CREDIT_RIGHT_TYPE = 70;
export const REPORT_SMS_MESSAGE_IN_OUT_RIGHT_TYPE = 71;
export const REPORT_SMTP_MESSAGE_IN_OUT_RIGHT_TYPE = 72;
export const REPORT_WORK_ORDER_INVOICE_RIGHT_TYPE = 73;
export const REPORT_WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE = 74;
export const REPORT_WORK_ORDER_STORE_RIGHT_TYPE = 75;
export const REPORT_WORK_ORDER_VISIT_RIGHT_TYPE = 76;
export const ADMIN_USER_MFA_RIGHT_TYPE = 77;
export const ADMIN_USER_API_KEY_RIGHTS_RIGHT_TYPE = 78;
export const ADMIN_EMAIL_STORE_ACCOUNT_RIGHT_TYPE = 79;
export const ADMIN_EMAIL_STORE_SENDER_RIGHT_TYPE = 80;
export const ADMIN_EMAIL_STORE_RECIPIENT_RIGHT_TYPE = 81;
export const WORK_ORDER_EMAIL_STORE_RIGHT_TYPE = 82;
export const REPORT_ORGANISATION_USER_RIGHT_TYPE = 83;
export const REPORT_DELIVERY_RECIPIENT_RIGHT_TYPE = 84;
export const REPORT_ORDER_DETAILS_RIGHT_TYPE = 85;
export const ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE = 86;
export const REPORT_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE = 87;

export const ADMIN_VISIT_REVIEW_RIGHT_TYPE = 88;
export const ADMIN_CUSTOM_WORD_RIGHT_TYPE = 89;
export const REPORT_CUSTOM_WORD_RIGHT_TYPE = 90;
export const ADMIN_MACHINE_RIGHT_TYPE = 91;

export const WORK_ORDER_PACKING_RIGHT_TYPE = 92;

export const ADMIN_APPOINTMENT_NOTIFICATION_RIGHT_TYPE = 93;

export const ADMIN_ORGANISATION_DETRACK_RIGHT_TYPE = 94;
export const ADMIN_DELIVERY_CUTOFF_SCHEDULE_RIGHT_TYPE = 95;
export const ADMIN_NON_BUSINESS_DAY_RIGHT_TYPE = 96;
export const WORK_ORDER_FULFILLMENT_RIGHT_TYPE = 97;
export const ADMIN_EMAIL_MEDICINE_TYPE_RIGHT_TYPE = 98;
export const REPORT_WORK_ORDER_PURCHASE_ORDER_RIGHT_TYPE = 99;

export const ADMIN_PROMO_CODE_RIGHT_TYPE = 100;
export const REPORT_WIKI_DATA_RIGHT_TYPE = 101;
export const REPORT_WORK_ORDER_DISPENSARY_RIGHT_TYPE = 102;
export const ADMIN_POSTAL_CODE_DISTRICT_RIGHT_TYPE = 103;
export const WORK_ORDER_DELIVERY_TRACKING_RIGHT_TYPE = 104;
export const ADMIN_USER_DEVICE_KEY_RIGHT_TYPE = 105;
export const ADMIN_DELIVERY_TRACKING_NOTIFICATION_RIGHT_TYPE = 106;
export const ADMIN_FILE_ATTACHMENT_NOTIFICATION_RIGHT_TYPE = 107;
export const ADMIN_INVOICE_PDF_NOTIFICATION_RIGHT_TYPE = 108;
export const REPORT_WORK_ORDER_DELIVERY_TRACKING_RIGHT_TYPE = 109;
//add permission type
export const WIP_ADD_PERMISSION_TYPE = 1;
export const APPROVAL_ADD_PERMISSION_TYPE = 2;
export const SUBMIT_ADD_PERMISSION_TYPE = 3;
export const EDIT_INSTRUCTIONS_PERMISSION_TYPE = 4;
export const EDIT_PHYSICIAN_LICENSE_PERMISSION_TYPE = 5;
export const EDIT_FILE_UPLOAD_TYPE = 6;
export const INVOICE_OVERRIDE_PERMISSION_TYPE = 7;

//twilio template purpose

export const APPOINTMENT_REMINDER_TEMPLATE = 1;
export const INVOICE_PDF_TEMPLATE = 2;
export const FILE_ATTACHMENT_TEMPLATE = 3;
export const MFA_OTP_CODE_TEMPLATE = 4;
export const DELIVERY_TRACKING_NEW_STATUS_TEMPLATE = 5;
export const DELIVERY_TRACKING_PENDING_STATUS_TEMPLATE = 6;
export const DELIVERY_TRACKING_APPROVED_STATUS_TEMPLATE = 7;
export const DELIVERY_TRACKING_WIP_STATUS_TEMPLATE = 8;
export const DELIVERY_TRACKING_PRINTOUT_STATUS_TEMPLATE = 9;
export const DELIVERY_TRACKING_CANCELLED_STATUS_TEMPLATE = 10;
export const DELIVERY_TRACKING_DELETED_STATUS_TEMPLATE = 11;
export const DELIVERY_TRACKING_CLOSED_STATUS_TEMPLATE = 12;

//work order type
//change the name
export const COUNTER_VIEW_TYPE = "counter_view";
export const STORE_WORK_ORDER_TYPE = 1;
export const VISIT_WORK_ORDER_TYPE = 2;
export const DISPENSARY_WORK_ORDER_TYPE = 3;
export const BREWING_WORK_ORDER_TYPE = 4;
export const DELIVERY_WORK_ORDER_TYPE = 5;
export const INVOICE_WORK_ORDER_TYPE = 6;
export const INVOICE_MEDICINE_COSTING_WORK_ORDER_TYPE = 7;
export const APPOINTMENT_WORK_ORDER_TYPE = 8;

//dashboard socket io status code
export const USER_XSRF_TOKEN_COOKIE_NAME = "USER-XSRF-TOKEN";
export const SOCKET_SUCCESS_CODE = 200;
export const SOCKET_ERROR_CODE = 400;

//dashboard socket recall api in milisecond
export const SOCKET_RECALL_API_MILISECOND = 60 * 1000;
export const SOCKET_CALL_API_ENANBLED = true;

//dashboard recall api in milisecond
export const DASHBOARD_RECALL_API_MILISECOND = 60 * 1000;
export const DASHBOARD_CALL_API_ENANBLED = false;

//weekdays
function t() {}
t("translation:Monday");
t("translation:Tuesday");
t("translation:Wednesday");
t("translation:Thurday");
t("translation:Friday");
t("translation:Saturday");
t("translation:Sunday");

export const weekdays = [
  { day: 1, name: "Monday" },
  { day: 2, name: "Tuesday" },
  { day: 3, name: "Wednesday" },
  { day: 4, name: "Thurday" },
  { day: 5, name: "Friday" },
  { day: 6, name: "Saturday" },
  { day: 7, name: "Sunday" },
];

export const weekdaysOffset = [
  { day: 0, name: "Sunday" },
  { day: 1, name: "Monday" },
  { day: 2, name: "Tuesday" },
  { day: 3, name: "Wednesday" },
  { day: 4, name: "Thurday" },
  { day: 5, name: "Friday" },
  { day: 6, name: "Saturday" },
];

//credit type
export const CREDIT_TOP_UP_TYPE = 1;
export const CREDIT_DEDUCT_TYPE = 2;
export const CREDIT_ADJUST_TYPE = 3;
export const CREDIT_REFUND_TYPE = 4;

//Rounding Place Value

export const HUNDRED_RPVT = 1;
export const TEN_RPVT = 2;
export const ONE_RPVT = 3;
export const TENTH_RPVT = 4;
export const HUNDREDTH_RPVT = 5;
export const THOUSANDTH_RPVT = 6;
export const TEN_THOUSANDTH_RPVT = 7;

//Round method
export const ROUND_UP_RMT = 1;
export const ROUND_OFF_RMT = 2;
export const ROUND_DOWN_RMT = 3;

//FAQ notion URL

export const FAQURL =
  "https://www.notion.so/balancebrew/b78c4ac26ab9404b9f1097b2c0233049?v=2a2e59af2a4246e28128e1c24a151c1f";

//water amount
export const WATERAMOUNT_100CC = 1;
export const WATERAMOUNT_200CC = 2;
export const WATERAMOUNT_60CC = 3;
export const WATERAMOUNT_150CC = 4;
