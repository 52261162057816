import apiConstants from "../_constants/apiConstants";
import { headerWithauth, headerWithauthBlob } from "../_helper/apiHeader";
import axios from "axios";

const deliveryOrderApis = {
  getDeliveryOrderSummaryListApi,
  getDeliveryOrderByIdApi,
  getDeliveryOrderListApi,
  postSaveDeliveryOrderApi,
  postSubmitDeliveryOrderApi,
  postSaveDeliveryOrderWCheckApi,
  postSubmitDeliveryOrderWCheckApi,
  postSubmitAllDeliveryApi,
  putDeliveryOrderWIPModeApi,
  putDeliveryOrderEditModeApi,
  putDeliveryOrderPendingModeApi,
  getDeliveryCourierListApi,
  getDeliveryCourierByBranchApi,
  getDeliveryOrderByStoreHeaderIdApi,
  getStoreHeaderListApi,
  getStoreHeaderSummaryListApi,
  putDeliveryOrderPrintDateApi,
  putDeliveryOrderUpdateStoreDeliveryAddressApi,
  postExportDeliveryOrdersApi,
  postExportDeliveryOrdersV2Api,
  getRoutingServicePayloadApi,
  getGeometryValuePayloadApi,
  getRouteByGridPayloadApi,
  getAreaClusterPayloadApi,
  getOnemapStaticMapPayloadApi,
  getDeliveryOrderPostalCodesApi,
  getGoogleStaticMapPayloadApi,
  getDeliveryOptimizedRoutingApi,
  getDeliveryOptimizedRoutingMapApi,
  getDeliveryVerifyAddressApi,
  putUpdateAllStoreDeliveryAddressApi,
  postSearchCsvCourierDeliveryAddressApi,
  postSearchCourierDeliveryAddressApi,
  putUpdateDeliveryTrackingRowNoApi,
  getDeliveryOrderVerifyAddressListApi,
};

async function getDeliveryOrderSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryOrderSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderByIdApi(model) {
  const url = apiConstants.apiGetDeliveryOrderById + model;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetDeliveryOrderList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSaveDeliveryOrderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSaveDeliveryOrder, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitDeliveryOrderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSubmitDeliveryOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSaveDeliveryOrderWCheckApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSaveDeliveryOrderWCheck,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitDeliveryOrderWCheckApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSubmitDeliveryOrderWCheck,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitAllDeliveryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitAllDelivery, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderPendingModeApi(model) {
  const url = apiConstants.apiPutDeliveryOrderPendingMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderEditModeApi(model) {
  const url = apiConstants.apiPutDeliveryOrderEditMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderWIPModeApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutDeliveryOrderWIPMode,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryCourierListApi() {
  return await axios
    .get(apiConstants.apiGetDeliveryCourierList, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryCourierByBranchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryCourierByBranch,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderByStoreHeaderIdApi(model) {
  const url = apiConstants.apiGetDeliveryOrderByStoreHeaderId + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreHeaderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetStoreHeaderList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreHeaderSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetStoreHeaderSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderPrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutDeliveryOrderPrintDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderUpdateStoreDeliveryAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutDeliveryOrderUpdateStoreDeliveryAddress,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postExportDeliveryOrdersApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostExportDeliveryOrders,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postExportDeliveryOrdersV2Api(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostExportDeliveryOrdersV2,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getRoutingServicePayloadApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetRoutingServicePayload,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getGeometryValuePayloadApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetGeometryValuePayload,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getRouteByGridPayloadApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetRouteByGridPayload, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAreaClusterPayloadApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetAreaClusterPayload, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOnemapStaticMapPayloadApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetOnemapStaticMapPayload,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderPostalCodesApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryOrderPostalCodes,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getGoogleStaticMapPayloadApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetGoogleStaticMapPayload,
      requestOptions,
      headerWithauthBlob
    )
    .then((response) => {
      let srcValue = {
        data: "data:image/png;base64," + response.data.image,
      };
      return srcValue;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOptimizedRoutingApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryOptimizedRouting,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOptimizedRoutingMapApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryOptimizedRoutingMap,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryVerifyAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryVerifyAddress,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateAllStoreDeliveryAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutUpdateAllStoreDeliveryAddress,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSearchCsvCourierDeliveryAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSearchCsvCourierDeliveryAddress,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSearchCourierDeliveryAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSearchCourierDeliveryAddress,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateDeliveryTrackingRowNoApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPutUpdateDeliveryTrackingRowNo,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderVerifyAddressListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryOrderVerifyAddressList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default deliveryOrderApis;
