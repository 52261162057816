import { apiPostSearchPostalCode } from "../_constants/externalApiConstants";
import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import { headerWithauthFormData } from "../_helper/apiHeader";
import axios from "axios";

const externetApis = {
  getPostalCodeListApi,
  postS3FileApi,
  getSingaporeHolidayListApi,
  getSingaporeYearlyHolidayApi,
};

async function getPostalCodeListApi(postcodeSearch, pageNum) {
  return await axios
    .get(apiPostSearchPostalCode(postcodeSearch, pageNum))
    .then((response) => {
      const allRepos = response;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postS3FileApi(file) {
  var formData = new FormData();
  formData.append("AWSAccessKeyId", file.AWSAccessKeyId);
  formData.append("key", file.key);
  formData.append("policy", file.policy);
  formData.append("signature", file.signature);
  formData.append("file", file.file);

  return await axios
    .post(file.postUrl, formData, headerWithauthFormData)
    .then((response) => {
      const allRepos = response;
      return allRepos;
    })
    .catch((error) => {
      return error;
    });
}

async function getSingaporeHolidayListApi() {
  return await axios
    .get(apiConstants.apiGetSingaporeHolidayList, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getSingaporeYearlyHolidayApi(model) {
  const url = apiConstants.apiGetSingaporeYearlyHoliday + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default externetApis;
