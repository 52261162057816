import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  // getDispensaryBranches,
  getMedicineTypes,
  getDispensaryBranchesAlliance,
  getMedicineTypesAlliance,
  postBranchMedicines,
  getCurrentOrganisation,
  postSaveStoreOrder,
  postSubmitStoreOrder,
  getStoreOrderList,
  getStoreOrderSummaryList,
  getStoreOrderById,
  putStoreEditMode,
  postFormulasAlliance,
  postMedicineFormulasAlliance,
  putStorePrintDate,
  getStorePrescriptionMsgList,
  getStoreMedicineInfo,
  getStoreOrderListLinkedDelivery,
  getStoreOrderSummaryListLinkedDelivery,
  getStoreOrderByIdLinkedDelivery,
  getStorePostalCodeSurchargeList,
  getStoreAvailableProceedOn,
  getStoreVerifyAddress,
} from "../actions/workOrderActions";
import * as types from "../_constants/workOrderConstants";
import * as notification from "../_constants/notificationConstants";
import workOrderApis from "../_apis/workOrderApis";
import * as sessionLog from "../components/functions/sessionStorage";

const logName = "storeLog";
export default function* workOrderSagas() {
  // yield all([
  //   takeLatest(
  //     types.GET_DISPENSARYBRANCHES_ASYNC.PENDING,
  //     getDispensaryBranchesCall
  //   ),
  // ]);
  yield all([
    takeLatest(types.GET_MEDICINETYPES_ASYNC.PENDING, getMedicineTypesCall),
  ]);
  yield all([
    takeLatest(
      types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.PENDING,
      getDispensaryBranchesAllianceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINETYPESALLIANCE_ASYNC.PENDING,
      getMedicineTypesAllianceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_FORMULASALLIANCE_ASYNC.PENDING,
      postFormulasAllianceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_MEDICINEFORMULASALLIANCE_ASYNC.PENDING,
      postMedicineFormulasAllianceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHMEDICINES_ASYNC.PENDING,
      postBranchMedicinesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_CURRENTORGANISATION_ASYNC.PENDING,
      getCurrentOrganisationCall
    ),
  ]);
  yield all([
    takeLatest(types.POST_SAVESTOREORDER_ASYNC.PENDING, postSaveStoreOrderCall),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITSTOREORDER_ASYNC.PENDING,
      postSubmitStoreOrderCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_STOREORDERLIST_ASYNC.PENDING, getStoreOrderListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.PENDING,
      getStoreOrderListLinkedDeliveryCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_STOREORDERSUMMARYLIST_ASYNC.PENDING,
      getStoreOrderSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.PENDING,
      getStoreOrderSummaryListLinkedDeliveryCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_STOREORDERBYID_ASYNC.PENDING, getStoreOrderByIdCall),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.PENDING,
      getStoreOrderByIdLinkedDeliveryCall
    ),
  ]);

  yield all([
    takeLatest(types.PUT_STOREEDITMODE_ASYNC.PENDING, putStoreEditModeCall),
  ]);
  yield all([
    takeLatest(types.PUT_STOREPRINTDATE_ASYNC.PENDING, putStorePrintDateCall),
  ]);
  yield all([
    takeLatest(
      types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.PENDING,
      getStorePrescriptionMsgListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREMEDICINEINFO_ASYNC.PENDING,
      getStoreMedicineInfoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREPOSTALCODESURCHARGE_ASYNC.PENDING,
      getStorePostalCodeSurchargeListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREAVAILABLEPROCEEDON_ASYNC.PENDING,
      getStoreAvailableProceedOnCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREVERIFYADDRESS_ASYNC.PENDING,
      getStoreVerifyAddressCall
    ),
  ]);
}

// function* getDispensaryBranchesCall() {
//   try {
//     const data = yield call(workOrderApis.getDispensaryBranchesApi);
//     yield put(getDispensaryBranches.success(data));
//   } catch (error) {
//     if (error.response?.data?.message)
//       yield put(getDispensaryBranches.error(error.response?.data?.message));
//     else yield put(getDispensaryBranches.error(notification.CONST_NOINTERNET));
//   }
// }

function* getMedicineTypesCall() {
  try {
    sessionLog.setUserSessionStorage(logName, "getMedicineTypesCall");
    const data = yield call(workOrderApis.getMedicineTypesApi);
    yield put(getMedicineTypes.success(data));
    sessionLog.setUserSessionStorage(logName, "getMedicineTypesCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getMedicineTypes.error(error.response?.data?.message));
    else yield put(getMedicineTypes.error(notification.CONST_NOINTERNET));
  }
}

function* getDispensaryBranchesAllianceCall() {
  try {
    sessionLog.setUserSessionStorage(
      logName,
      "getDispensaryBranchesAllianceCall"
    );
    const data = yield call(workOrderApis.getDispensaryBranchesAllianceApi);
    yield put(getDispensaryBranchesAlliance.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getDispensaryBranchesAllianceCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        getDispensaryBranchesAlliance.error(error.response?.data?.message)
      );
    else
      yield put(
        getDispensaryBranchesAlliance.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getMedicineTypesAllianceCall(searchModel) {
  try {
    sessionLog.setUserSessionStorage(logName, "getMedicineTypesAllianceCall");
    const data = yield call(
      workOrderApis.getMedicineTypesAllianceApi,
      searchModel.data
    );
    yield put(getMedicineTypesAlliance.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getMedicineTypesAllianceCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getMedicineTypesAlliance.error(error.response?.data?.message));
    else
      yield put(getMedicineTypesAlliance.error(notification.CONST_NOINTERNET));
  }
}

function* postFormulasAllianceCall(searchModel) {
  try {
    sessionLog.setUserSessionStorage(logName, "postFormulasAllianceCall");
    const data = yield call(
      workOrderApis.postFormulasAllianceApi,
      searchModel.data
    );
    yield put(postFormulasAlliance.success(data));
    sessionLog.setUserSessionStorage(logName, "postFormulasAllianceCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(postFormulasAlliance.error(error.response?.data?.message));
    else yield put(postFormulasAlliance.error(notification.CONST_NOINTERNET));
  }
}

function* postMedicineFormulasAllianceCall(searchModel) {
  try {
    sessionLog.setUserSessionStorage(
      logName,
      "postMedicineFormulasAllianceCall"
    );
    const data = yield call(
      workOrderApis.postMedicineFormulasAllianceApi,
      searchModel.data
    );
    yield put(postMedicineFormulasAlliance.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "postMedicineFormulasAllianceCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        postMedicineFormulasAlliance.error(error.response?.data?.message)
      );
    else
      yield put(
        postMedicineFormulasAlliance.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchMedicinesCall(searchModel) {
  try {
    sessionLog.setUserSessionStorage(logName, "postBranchMedicinesCall");
    const data = yield call(
      workOrderApis.postBranchMedicinesApi,
      searchModel.data
    );
    yield put(postBranchMedicines.success(data));
    sessionLog.setUserSessionStorage(logName, "postBranchMedicinesCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(postBranchMedicines.error(error.response?.data?.message));
    else yield put(postBranchMedicines.error(notification.CONST_NOINTERNET));
  }
}

function* getCurrentOrganisationCall() {
  try {
    sessionLog.setUserSessionStorage(logName, "getCurrentOrganisationCall");
    const data = yield call(workOrderApis.getCurrentOrganisationApi);
    yield put(getCurrentOrganisation.success(data));
    sessionLog.setUserSessionStorage(logName, "getCurrentOrganisationCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getCurrentOrganisation.error(error.response?.data?.message));
    else yield put(getCurrentOrganisation.error(notification.CONST_NOINTERNET));
  }
}

function* postSaveStoreOrderCall(model) {
  try {
    sessionLog.setUserSessionStorage(logName, "postSaveStoreOrderCall");
    const data = yield call(workOrderApis.postSaveStoreOrderApi, model.data);
    yield put(postSaveStoreOrder.success(data));
    sessionLog.setUserSessionStorage(logName, "postSaveStoreOrderCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(postSaveStoreOrder.error(error.response?.data?.message));
    else yield put(postSaveStoreOrder.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitStoreOrderCall(model) {
  try {
    sessionLog.setUserSessionStorage(logName, "postSubmitStoreOrderCall");
    const data = yield call(workOrderApis.postSubmitStoreOrderApi, model.data);
    yield put(postSubmitStoreOrder.success(data));
    sessionLog.setUserSessionStorage(logName, "postSubmitStoreOrderCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(postSubmitStoreOrder.error(error.response?.data?.message));
    else yield put(postSubmitStoreOrder.error(notification.CONST_NOINTERNET));
  }
}

function* getStoreOrderListCall(model) {
  try {
    sessionLog.setUserSessionStorage(logName, "getStoreOrderListCall");
    const data = yield call(workOrderApis.getStoreOrderListApi, model.data);
    yield put(getStoreOrderList.success(data));
    sessionLog.setUserSessionStorage(logName, "getStoreOrderListCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getStoreOrderList.error(error.response?.data?.message));
    else yield put(getStoreOrderList.error(notification.CONST_NOINTERNET));
  }
}

function* getStoreOrderListLinkedDeliveryCall(userModel) {
  try {
    const data = yield call(workOrderApis.getStoreOrderListApi, userModel.data);

    yield put(getStoreOrderListLinkedDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getStoreOrderListLinkedDelivery.error(error.response?.data?.message)
      );
    else
      yield put(
        getStoreOrderListLinkedDelivery.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getStoreOrderSummaryListCall(userModel) {
  try {
    sessionLog.setUserSessionStorage(logName, "getStoreOrderSummaryListCall");
    const data = yield call(
      workOrderApis.getStoreOrderSummaryListApi,
      userModel.data
    );
    yield put(getStoreOrderSummaryList.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getStoreOrderSummaryListCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getStoreOrderSummaryList.error(error.response?.data?.message));
    else
      yield put(getStoreOrderSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getStoreOrderSummaryListLinkedDeliveryCall(userModel) {
  try {
    sessionLog.setUserSessionStorage(
      logName,
      "getStoreOrderByIdLinkedDeliveryCall"
    );
    const data = yield call(
      workOrderApis.getStoreOrderSummaryListApi,
      userModel.data
    );

    yield put(getStoreOrderSummaryListLinkedDelivery.success(data));

    sessionLog.setUserSessionStorage(
      logName,
      "getStoreOrderByIdLinkedDeliveryCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        getStoreOrderSummaryListLinkedDelivery.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getStoreOrderSummaryListLinkedDelivery.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getStoreOrderByIdCall(userModel) {
  try {
    sessionLog.setUserSessionStorage(logName, "getStoreOrderByIdCall");
    const data = yield call(workOrderApis.getStoreOrderByIdApi, userModel.data);
    yield put(getStoreOrderById.success(data));
    sessionLog.setUserSessionStorage(logName, "getStoreOrderByIdCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getStoreOrderById.error(error.response?.data?.message));
    else yield put(getStoreOrderById.error(notification.CONST_NOINTERNET));
  }
}

function* getStoreOrderByIdLinkedDeliveryCall(userModel) {
  try {
    sessionLog.setUserSessionStorage(
      logName,
      "getStoreOrderByIdLinkedDeliveryCall"
    );
    const data = yield call(workOrderApis.getStoreOrderByIdApi, userModel.data);
    yield put(getStoreOrderByIdLinkedDelivery.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getStoreOrderByIdLinkedDeliveryCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        getStoreOrderByIdLinkedDelivery.error(error.response?.data?.message)
      );
    else
      yield put(
        getStoreOrderByIdLinkedDelivery.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putStoreEditModeCall(model) {
  try {
    sessionLog.setUserSessionStorage(logName, "putStoreEditModeCall");
    const data = yield call(workOrderApis.putStoreEditModeApi, model.data);
    yield put(putStoreEditMode.success(data));
    sessionLog.setUserSessionStorage(logName, "putStoreEditModeCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(putStoreEditMode.error(error.response?.data?.message));
    else yield put(putStoreEditMode.error(notification.CONST_NOINTERNET));
  }
}

function* putStorePrintDateCall(Model) {
  try {
    sessionLog.setUserSessionStorage(logName, "putStorePrintDateCall");
    const data = yield call(workOrderApis.putStorePrintDateApi, Model.data);
    yield put(putStorePrintDate.success(data));
    sessionLog.setUserSessionStorage(logName, "putStorePrintDateCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(putStorePrintDate.error(error.response?.data?.message));
    else yield put(putStorePrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* getStorePrescriptionMsgListCall() {
  try {
    sessionLog.setUserSessionStorage(
      logName,
      "getStorePrescriptionMsgListCall"
    );
    const data = yield call(workOrderApis.getStorePrescriptionMsgListApi);
    yield put(getStorePrescriptionMsgList.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getStorePrescriptionMsgListCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        getStorePrescriptionMsgList.error(error.response?.data?.message)
      );
    else
      yield put(
        getStorePrescriptionMsgList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getStoreMedicineInfoCall(Model) {
  try {
    sessionLog.setUserSessionStorage(logName, "getStoreMedicineInfoCall");
    const data = yield call(workOrderApis.getStoreMedicineInfoApi, Model.data);
    yield put(getStoreMedicineInfo.success(data));
    sessionLog.setUserSessionStorage(logName, "getStoreMedicineInfoCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getStoreMedicineInfo.error(error.response?.data?.message));
    else yield put(getStoreMedicineInfo.error(notification.CONST_NOINTERNET));
  }
}

function* getStorePostalCodeSurchargeListCall(Model) {
  try {
    sessionLog.setUserSessionStorage(
      logName,
      "getStorePostalCodeSurchargeListCall"
    );
    const data = yield call(
      workOrderApis.getStorePostalCodeSurchargeListApi,
      Model.data
    );
    yield put(getStorePostalCodeSurchargeList.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getStorePostalCodeSurchargeListCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        getStorePostalCodeSurchargeList.error(error.response?.data?.message)
      );
    else
      yield put(
        getStorePostalCodeSurchargeList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getStoreAvailableProceedOnCall(Model) {
  try {
    sessionLog.setUserSessionStorage(logName, "getStoreAvailableProceedOnCall");
    const data = yield call(
      workOrderApis.getStoreAvailableProceedOnApi,
      Model.data
    );
    yield put(getStoreAvailableProceedOn.success(data));
    sessionLog.setUserSessionStorage(
      logName,
      "getStoreAvailableProceedOnCallSuc"
    );
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(
        getStoreAvailableProceedOn.error(error.response?.data?.message)
      );
    else
      yield put(
        getStoreAvailableProceedOn.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getStoreVerifyAddressCall(Model) {
  try {
    sessionLog.setUserSessionStorage(logName, "getStoreVerifyAddressCall");
    const data = yield call(workOrderApis.getStoreVerifyAddressApi, Model.data);
    yield put(getStoreVerifyAddress.success(data));
    sessionLog.setUserSessionStorage(logName, "getStoreVerifyAddressCallSuc");
  } catch (error) {
    sessionLog.setUserSessionStorage(logName, error);
    if (error.response?.data?.message)
      yield put(getStoreVerifyAddress.error(error.response?.data?.message));
    else yield put(getStoreVerifyAddress.error(notification.CONST_NOINTERNET));
  }
}
