import { createAsyncTypes } from "../_helper/Utilities";

export const GET_VISITDISPENSARYBRANCHES_ASYNC = createAsyncTypes(
  "GET_VISITDISPENSARYBRANCHES"
);

export const GET_VISITMEDICINETYPES_ASYNC = createAsyncTypes(
  "GET_VISITMEDICINETYPES"
);

export const GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC = createAsyncTypes(
  "GET_VISITDISPENSARYBRANCHESALLIANCE"
);

export const GET_VISITMEDICINETYPESALLIANCE_ASYNC = createAsyncTypes(
  "GET_VISITMEDICINETYPESALLIANCE"
);

export const POST_VISITFORMULASALLIANCE_ASYNC = createAsyncTypes(
  "POST_VISITFORMULASALLIANCE"
);

export const POST_VISITMEDICINEFORMULASALLIANCE_ASYNC = createAsyncTypes(
  "POST_VISITMEDICINEFORMULASALLIANCE"
);

export const POST_VISITBRANCHMEDICINES_ASYNC = createAsyncTypes(
  "POST_VISITBRANCHMEDICINES"
);

export const GET_VISITCURRENTORGANISATION_ASYNC = createAsyncTypes(
  "GET_VISITCURRENTORGANISATION"
);

export const POST_VISITSAVESTOREORDER_ASYNC = createAsyncTypes(
  "POST_VISITSAVESTOREORDER"
);

export const POST_VISITSUBMITSTOREORDER_ASYNC = createAsyncTypes(
  "POST_VISITSUBMITSTOREORDER"
);

export const GET_VISITSTOREORDERLIST_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERLIST"
);

export const GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERLISTLINKEDDELIVERY"
);

export const GET_VISITSTOREORDERSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERSUMMARYLIST"
);

export const GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC =
  createAsyncTypes("GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY");

export const GET_VISITSTOREORDERBYID_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERBYID"
);

export const GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERBYIDLINKEDDELIVERY"
);

export const GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERBYIDPATIENTHISTORY"
);

export const GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC = createAsyncTypes(
  "GET_VISITSTOREORDERDASHBOARDSEARCHLIST"
);

export const GET_PATIENTVISITSTOREORDERLIST_ASYNC = createAsyncTypes(
  "GET_PATIENTVISITSTOREORDERLIST"
);

export const PUT_VISITSTOREEDITMODE_ASYNC = createAsyncTypes(
  "PUT_VISITSTOREEDITMODE"
);

export const GET_VISITACUPOINTS_ASYNC = createAsyncTypes("GET_VISITACUPOINTS");

export const GET_VISITTREATMENTTYPES_ASYNC = createAsyncTypes(
  "GET_VISITTREATMENTTYPES"
);

export const POST_VISITTREATMENTS_ASYNC = createAsyncTypes(
  "POST_VISITTREATMENTS"
);

export const GET_VISITPAYMENTMETHODS_ASYNC = createAsyncTypes(
  "GET_VISITPAYMENTMETHODS"
);

export const GET_VISITPAYMENTTERMS_ASYNC = createAsyncTypes(
  "GET_VISITPAYMENTTERMS"
);

export const POST_VISITEMPTYORDER_ASYNC = createAsyncTypes(
  "POST_VISITEMPTYORDER"
);

export const GET_VISITPHYSICIANS_ASYNC = createAsyncTypes(
  "GET_VISITPHYSICIANS"
);

export const POST_VISITPHYSICIANS_ASYNC = createAsyncTypes(
  "POST_VISITPHYSICIANS"
);

export const GET_VISITUSERLIST_ASYNC = createAsyncTypes("GET_VISITUSERLIST");

export const GET_VISITUSERADVANCEDSEARCH_ASYNC = createAsyncTypes(
  "GET_VISITUSERADVANCEDSEARCH"
);

export const POST_VISITCREATEUSER_ASYNC = createAsyncTypes(
  "POST_VISITCREATEUSER"
);

export const PUT_VISITUPDATEUSER_ASYNC = createAsyncTypes(
  "PUT_VISITUPDATEUSER"
);

export const GET_VISITISUSERCURRENTORGANISATION_ASYNC = createAsyncTypes(
  "GET_VISITISUSERCURRENTORGANISATION"
);

export const PUT_VISITUPDATEUSERORGANISATION_ASYNC = createAsyncTypes(
  "PUT_VISITUPDATEUSERORGANISATION"
);

export const PUT_VISITSETARRIVALON_ASYNC = createAsyncTypes(
  "PUT_VISITSETARRIVALON"
);

export const PUT_VISITSETDEPARTUREON_ASYNC = createAsyncTypes(
  "PUT_VISITSETDEPARTUREON"
);

export const PUT_VISITSETCONSULTON_ASYNC = createAsyncTypes(
  "PUT_VISITSETCONSULTON"
);

export const PUT_VISITSETQUEUENUMBER_ASYNC = createAsyncTypes(
  "PUT_VISITSETQUEUENUMBER"
);

export const PUT_VISITUPDATEVISITNOTES_ASYNC = createAsyncTypes(
  "PUT_VISITUPDATEVISITNOTES"
);

export const PUT_VISITPRINTDATE_ASYNC = createAsyncTypes("PUT_VISITPRINTDATE");

export const PUT_UPDATEUSERMEDICALHISTORY_ASYNC = createAsyncTypes(
  "PUT_UPDATEUSERMEDICALHISTORY"
);

export const GET_VISITPRESCRIPTIONMSGLIST_ASYNC = createAsyncTypes(
  "GET_VISITPRESCRIPTIONMSGLIST"
);

export const GET_VISITMEDICINEINFO_ASYNC = createAsyncTypes(
  "GET_VISITMEDICINEINFO"
);

export const GET_VISITACUPOINTINFO_ASYNC = createAsyncTypes(
  "GET_VISITACUPOINTINFO"
);

export const GET_VISITTREATMENTINFO_ASYNC = createAsyncTypes(
  "GET_VISITTREATMENTINFO"
);

export const POST_VISITCHANGEBRANCH_ASYNC = createAsyncTypes(
  "POST_VISITCHANGEBRANCH"
);

export const POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC = createAsyncTypes(
  "POST_VISITCHANGEBRANCHTREATMENTONLY"
);

export const POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC = createAsyncTypes(
  "POST_VISITCHANGEBRANCHACUPOINTONLY"
);

export const PUT_UPDATEVISITACUPOINTS_ASYNC = createAsyncTypes(
  "PUT_UPDATEVISITACUPOINTS"
);

export const GET_VISITPOSTALCODESURCHARGE_ASYNC = createAsyncTypes(
  "GET_VISITPOSTALCODESURCHARGE"
);

export const GET_VISITAVAILABLEPROCEEDON_ASYNC = createAsyncTypes(
  "GET_VISITAVAILABLEPROCEEDON"
);

export const GET_VISITVERIFYADDRESS_ASYNC = createAsyncTypes(
  "GET_VISITVERIFYADDRESS"
);
