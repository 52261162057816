import * as types from "../_constants/deliveryOrderConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderSummaryListSuccess: false,
        isLoadingDeliveryOrderSummaryList: true,
        //may have issue to submit order
        deliveryOrderSummaryList: null,
      };

    case types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderSummaryListSuccess: false,
        isLoadingDeliveryOrderSummaryList: false,
        deliveryOrderSummaryList: null,
        error: { ...state.error, deliveryOrderSummaryListError: action.error },
      };

    case types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderSummaryListSuccess: true,
        isLoadingDeliveryOrderSummaryList: false,
        deliveryOrderSummaryList: action.data,
      };

    case types.GET_DELIVERYORDERBYID_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderByIdIsSuccess: false,
        isLoadingGetDeliveryOrderById: true,
        //may have issue to submit order
        getDeliveryOrderById: null,
      };

    case types.GET_DELIVERYORDERBYID_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderByIdIsSuccess: false,
        isLoadingGetDeliveryOrderById: false,
        getDeliveryOrderById: null,
        error: { ...state.error, getDeliveryOrderByIdError: action.error },
      };

    case types.GET_DELIVERYORDERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderByIdIsSuccess: true,
        isLoadingGetDeliveryOrderById: false,
        getDeliveryOrderById: action.data,
      };

    case types.GET_DELIVERYORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderListSuccess: false,
        isLoadingDeliveryOrderList: true,
        //may have issue to submit order
        deliveryOrderList: null,
      };

    case types.GET_DELIVERYORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderListSuccess: false,
        isLoadingDeliveryOrderList: false,
        deliveryOrderList: null,
        error: { ...state.error, deliveryOrderListError: action.error },
      };

    case types.GET_DELIVERYORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderListSuccess: true,
        isLoadingDeliveryOrderList: false,
        deliveryOrderList: action.data,
      };

    case types.POST_SAVEDELIVERYORDER_ASYNC.PENDING:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: false,
        isLoadingSaveDeliveryOrder: true,
        saveDeliveryOrder: null,
      };

    case types.POST_SAVEDELIVERYORDER_ASYNC.ERROR:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: false,
        isLoadingSaveDeliveryOrder: false,
        saveDeliveryOrder: null,
        error: {
          ...state.error,
          postSaveDeliveryOrder: action.error,
        },
      };

    case types.POST_SAVEDELIVERYORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: true,
        isLoadingSaveDeliveryOrder: false,
        saveDeliveryOrder: action.data,
      };

    case types.POST_SUBMITDELIVERYORDER_ASYNC.PENDING:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: false,
        isLoadingSubmitDeliveryOrder: true,
        submitDeliveryOrder: null,
      };

    case types.POST_SUBMITDELIVERYORDER_ASYNC.ERROR:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: false,
        isLoadingSubmitDeliveryOrder: false,
        submitDeliveryOrder: null,
        error: { ...state.error, postSubmitDeliveryOrder: action.error },
      };

    case types.POST_SUBMITDELIVERYORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: true,
        isLoadingSubmitDeliveryOrder: false,
        submitDeliveryOrder: action.data,
      };

    case types.POST_SAVEDELIVERYORDERWCHECK_ASYNC.PENDING:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: false,
        isLoadingSaveDeliveryOrder: true,
        saveDeliveryOrder: null,
      };

    case types.POST_SAVEDELIVERYORDERWCHECK_ASYNC.ERROR:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: false,
        isLoadingSaveDeliveryOrder: false,
        saveDeliveryOrder: null,
        error: {
          ...state.error,
          postSaveDeliveryOrder: action.error,
        },
      };

    case types.POST_SAVEDELIVERYORDERWCHECK_ASYNC.SUCCESS:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: true,
        isLoadingSaveDeliveryOrder: false,
        saveDeliveryOrder: action.data,
      };

    case types.POST_SUBMITDELIVERYORDERWCHECK_ASYNC.PENDING:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: false,
        isLoadingSubmitDeliveryOrder: true,
        submitDeliveryOrder: null,
      };

    case types.POST_SUBMITDELIVERYORDERWCHECK_ASYNC.ERROR:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: false,
        isLoadingSubmitDeliveryOrder: false,
        submitDeliveryOrder: null,
        error: { ...state.error, postSubmitDeliveryOrder: action.error },
      };

    case types.POST_SUBMITDELIVERYORDERWCHECK_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: true,
        isLoadingSubmitDeliveryOrder: false,
        submitDeliveryOrder: action.data,
      };
    case types.POST_SUBMITALLDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        postSubmitAllDeliveryIsSuccess: false,
        isLoadingSubmitAllDelivery: true,
        submitAllDelivery: null,
      };

    case types.POST_SUBMITALLDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        postSubmitAllDeliveryIsSuccess: false,
        isLoadingSubmitAllDelivery: false,
        submitAllDelivery: null,
        error: { ...state.error, postSubmitAllDelivery: action.error },
      };

    case types.POST_SUBMITALLDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitAllDeliveryIsSuccess: true,
        isLoadingSubmitAllDelivery: false,
        submitAllDelivery: action.data,
      };
    case types.PUT_DELIVERYORDEREDITMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderEditModeSuccess: false,
        isLoadingDeliveryOrderEditMode: true,
        putDeliveryOrderEditMode: null,
      };

    case types.PUT_DELIVERYORDEREDITMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderEditModeSuccess: false,
        isLoadingDeliveryOrderEditMode: false,
        error: { ...state.error, deliveryOrderEditModeError: action.error },
        putDeliveryOrderEditMode: null,
      };

    case types.PUT_DELIVERYORDEREDITMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderEditModeSuccess: true,
        isLoadingDeliveryOrderEditMode: false,
        putDeliveryOrderEditMode: action.data,
      };

    case types.PUT_DELIVERYORDERWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderWIPModeSuccess: false,
        isLoadingDeliveryOrderWIPMode: true,
        putDeliveryOrderWIPMode: null,
      };

    case types.PUT_DELIVERYORDERWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderWIPModeSuccess: false,
        isLoadingDeliveryOrderWIPMode: false,
        putDeliveryOrderWIPMode: null,
        error: { ...state.error, deliveryOrderWIPModeError: action.error },
      };

    case types.PUT_DELIVERYORDERWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderWIPModeSuccess: true,
        isLoadingDeliveryOrderWIPMode: false,
        putDeliveryOrderWIPMode: action.data,
      };

    case types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderPendingModeSuccess: false,
        isLoadingDeliveryOrderPendingMode: true,
        deliveryOrderPendingMode: null,
      };

    case types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderPendingModeSuccess: false,
        isLoadingDeliveryOrderPendingMode: false,
        deliveryOrderPendingMode: null,
        error: { ...state.error, deliveryOrderPendingModeError: action.error },
      };

    case types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderPendingModeSuccess: true,
        isLoadingDeliveryOrderPendingMode: false,
        deliveryOrderPendingMode: action.data,
      };

    case types.GET_DELIVERYCOURIERLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryCourierListSuccess: false,
        isLoadingDeliveryCourierList: true,
        deliveryCourierList: null,
      };

    case types.GET_DELIVERYCOURIERLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryCourierListSuccess: false,
        isLoadingDeliveryCourierList: false,
        deliveryCourierList: null,
        error: { ...state.error, deliveryCourierListError: action.error },
      };

    case types.GET_DELIVERYCOURIERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryCourierListSuccess: true,
        isLoadingDeliveryCourierList: false,
        deliveryCourierList: action.data,
      };

    case types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryCourierByBranchIsSuccess: false,
        isLoadingDeliveryCourierByBranch: true,
        deliveryCourierByBranch: null,
      };

    case types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryCourierByBranchIsSuccess: false,
        isLoadingDeliveryCourierByBranch: false,
        deliveryCourierByBranch: null,
        error: { ...state.error, deliveryCourierByBranchError: action.error },
      };

    case types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryCourierByBranchIsSuccess: true,
        isLoadingDeliveryCourierByBranch: false,
        deliveryCourierByBranch: action.data,
      };

    case types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderListByStoreHeaderIdSuccess: false,
        isLoadingDeliveryOrderListByStoreHeaderId: true,
        //may have issue to submit order
        getDeliveryOrderListByStoreHeaderId: null,
      };

    case types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderListByStoreHeaderIdSuccess: false,
        isLoadingDeliveryOrderListByStoreHeaderId: false,
        getDeliveryOrderListByStoreHeaderId: null,
        error: {
          ...state.error,
          deliveryOrderListByStoreHeaderIdError: action.error,
        },
      };

    case types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderListByStoreHeaderIdSuccess: true,
        isLoadingDeliveryOrderListByStoreHeaderId: false,
        getDeliveryOrderListByStoreHeaderId: action.data,
      };

    case types.GET_STOREHEADERLIST_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderListSuccess: false,
        isLoadingStoreOrderList: true,
        //may have issue to submit order
        storeOrderList: null,
      };

    case types.GET_STOREHEADERLIST_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderListSuccess: false,
        isLoadingStoreOrderList: false,
        storeOrderList: null,
        error: { ...state.error, storeOrderListError: action.error },
      };

    case types.GET_STOREHEADERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderListSuccess: true,
        isLoadingStoreOrderList: false,
        storeOrderList: action.data,
      };

    case types.GET_STOREHEADERSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderSummaryListSuccess: false,
        isLoadingStoreOrderSummaryList: true,
        //may have issue to submit order
        storeOrderSummaryList: null,
      };

    case types.GET_STOREHEADERSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderSummaryListSuccess: false,
        isLoadingStoreOrderSummaryList: false,
        storeOrderSummaryList: null,
        error: { ...state.error, storeOrderSummaryListError: action.error },
      };

    case types.GET_STOREHEADERSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderSummaryListSuccess: true,
        isLoadingStoreOrderSummaryList: false,
        storeOrderSummaryList: action.data,
      };

    case types.PUT_DELIVERYORDERPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderPrintDateIsSuccess: false,
        isLoadingDeliveryOrderPrintDate: true,
        deliveryOrderPrintDate: null,
      };

    case types.PUT_DELIVERYORDERPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderPrintDateIsSuccess: false,
        isLoadingDeliveryOrderPrintDate: false,
        deliveryOrderPrintDate: null,
        error: { ...state.error, deliveryOrderPrintDateError: action.error },
      };

    case types.PUT_DELIVERYORDERPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderPrintDateIsSuccess: true,
        isLoadingDeliveryOrderPrintDate: false,
        deliveryOrderPrintDate: action.data,
      };

    case types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderUpdateStoreAddressIsSuccess: false,
        isLoadingDeliveryOrderUpdateStoreAddress: true,
        deliveryOrderUpdateStoreAddress: null,
      };

    case types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderUpdateStoreAddressIsSuccess: false,
        isLoadingDeliveryOrderUpdateStoreAddress: false,
        deliveryOrderUpdateStoreAddress: null,
        error: {
          ...state.error,
          deliveryOrderUpdateStoreAddressError: action.error,
        },
      };

    case types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderUpdateStoreAddressIsSuccess: true,
        isLoadingDeliveryOrderUpdateStoreAddress: false,
        deliveryOrderUpdateStoreAddress: action.data,
      };

    case types.POST_EXPORTDELIVERYORDERS_ASYNC.PENDING:
      return {
        ...state,
        postExportDeliveryOrdersIsSuccess: false,
        isLoadingExportDeliveryOrders: true,
        exportDeliveryOrders: null,
      };

    case types.POST_EXPORTDELIVERYORDERS_ASYNC.ERROR:
      return {
        ...state,
        postExportDeliveryOrdersIsSuccess: false,
        isLoadingExportDeliveryOrders: false,
        exportDeliveryOrders: null,
        error: {
          ...state.error,
          exportDeliveryOrdersError: action.error,
        },
      };

    case types.POST_EXPORTDELIVERYORDERS_ASYNC.SUCCESS:
      return {
        ...state,
        postExportDeliveryOrdersIsSuccess: true,
        isLoadingExportDeliveryOrders: false,
        exportDeliveryOrders: action.data,
      };

    case types.POST_EXPORTDELIVERYORDERSV2_ASYNC.PENDING:
      return {
        ...state,
        postExportDeliveryOrdersV2IsSuccess: false,
        isLoadingExportDeliveryOrdersV2: true,
        exportDeliveryOrdersV2: null,
      };

    case types.POST_EXPORTDELIVERYORDERSV2_ASYNC.ERROR:
      return {
        ...state,
        postExportDeliveryOrdersV2IsSuccess: false,
        isLoadingExportDeliveryOrdersV2: false,
        exportDeliveryOrdersV2: null,
        error: {
          ...state.error,
          exportDeliveryOrdersV2Error: action.error,
        },
      };

    case types.POST_EXPORTDELIVERYORDERSV2_ASYNC.SUCCESS:
      return {
        ...state,
        postExportDeliveryOrdersV2IsSuccess: true,
        isLoadingExportDeliveryOrdersV2: false,
        exportDeliveryOrdersV2: action.data,
      };

    case types.GET_ROUTINGSERVICEPAYLOAD_ASYNC.PENDING:
      return {
        ...state,
        getRoutingServicePayloadIsSuccess: false,
        isLoadingGetRoutingServicePayload: true,
        getRoutingServicePayload: null,
      };

    case types.GET_ROUTINGSERVICEPAYLOAD_ASYNC.ERROR:
      return {
        ...state,
        getRoutingServicePayloadIsSuccess: false,
        isLoadingGetRoutingServicePayload: false,
        getRoutingServicePayload: null,
        error: {
          ...state.error,
          getRoutingServicePayloadError: action.error,
        },
      };

    case types.GET_ROUTINGSERVICEPAYLOAD_ASYNC.SUCCESS:
      return {
        ...state,
        getRoutingServicePayloadIsSuccess: true,
        isLoadingGetRoutingServicePayload: false,
        getRoutingServicePayload: action.data,
      };

    case types.GET_GEOMETRYVALUEPAYLOAD_ASYNC.PENDING:
      return {
        ...state,
        getGeometryValuePayloadIsSuccess: false,
        isLoadingGetGeometryValuePayload: true,
        getGeometryValuePayload: null,
      };

    case types.GET_GEOMETRYVALUEPAYLOAD_ASYNC.ERROR:
      return {
        ...state,
        getGeometryValuePayloadIsSuccess: false,
        isLoadingGetGeometryValuePayload: false,
        getGeometryValuePayload: null,
        error: {
          ...state.error,
          getGeometryValuePayloadError: action.error,
        },
      };

    case types.GET_GEOMETRYVALUEPAYLOAD_ASYNC.SUCCESS:
      return {
        ...state,
        getGeometryValuePayloadIsSuccess: true,
        isLoadingGetGeometryValuePayload: false,
        getGeometryValuePayload: action.data,
      };

    case types.GET_ROUTEBYGRIDPAYLOAD_ASYNC.PENDING:
      return {
        ...state,
        getRouteByGridPayloadIsSuccess: false,
        isLoadingGetRouteByGridPayload: true,
        getRouteByGridPayload: null,
      };

    case types.GET_ROUTEBYGRIDPAYLOAD_ASYNC.ERROR:
      return {
        ...state,
        getRouteByGridPayloadIsSuccess: false,
        isLoadingGetRouteByGridPayload: false,
        getRouteByGridPayload: null,
        error: {
          ...state.error,
          getRouteByGridPayloadError: action.error,
        },
      };

    case types.GET_ROUTEBYGRIDPAYLOAD_ASYNC.SUCCESS:
      return {
        ...state,
        getRouteByGridPayloadIsSuccess: true,
        isLoadingGetRouteByGridPayload: false,
        getRouteByGridPayload: action.data,
      };

    case types.GET_AREACLUSTERPAYLOAD_ASYNC.PENDING:
      return {
        ...state,
        getAreaClusterPayloadIsSuccess: false,
        isLoadingGetAreaClusterPayload: true,
        getAreaClusterPayload: null,
      };

    case types.GET_AREACLUSTERPAYLOAD_ASYNC.ERROR:
      return {
        ...state,
        getAreaClusterPayloadIsSuccess: false,
        isLoadingGetAreaClusterPayload: false,
        getAreaClusterPayload: null,
        error: {
          ...state.error,
          getAreaClusterPayloadError: action.error,
        },
      };

    case types.GET_AREACLUSTERPAYLOAD_ASYNC.SUCCESS:
      return {
        ...state,
        getAreaClusterPayloadIsSuccess: true,
        isLoadingGetAreaClusterPayload: false,
        getAreaClusterPayload: action.data,
      };

    case types.GET_ONEMAPSTATICMAPPAYLOAD_ASYNC.PENDING:
      return {
        ...state,
        getOnemapStaticMapPayloadIsSuccess: false,
        isLoadingGetOnemapStaticMapPayload: true,
        getOnemapStaticMapPayload: null,
      };

    case types.GET_ONEMAPSTATICMAPPAYLOAD_ASYNC.ERROR:
      return {
        ...state,
        getOnemapStaticMapPayloadIsSuccess: false,
        isLoadingGetOnemapStaticMapPayload: false,
        getOnemapStaticMapPayload: null,
        error: {
          ...state.error,
          getOnemapStaticMapPayloadError: action.error,
        },
      };

    case types.GET_ONEMAPSTATICMAPPAYLOAD_ASYNC.SUCCESS:
      return {
        ...state,
        getOnemapStaticMapPayloadIsSuccess: true,
        isLoadingGetOnemapStaticMapPayload: false,
        getOnemapStaticMapPayload: action.data,
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: false,
        isLoadingGetDeliveryOrderPostalCodes: true,
        getDeliveryOrderPostalCodes: null,
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: false,
        isLoadingGetDeliveryOrderPostalCodes: false,
        getDeliveryOrderPostalCodes: null,
        error: {
          ...state.error,
          getDeliveryOrderPostalCodesError: action.error,
        },
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: true,
        isLoadingGetDeliveryOrderPostalCodes: false,
        getDeliveryOrderPostalCodes: action.data,
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: false,
        isLoadingGetDeliveryOrderPostalCodes: true,
        getDeliveryOrderPostalCodes: null,
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: false,
        isLoadingGetDeliveryOrderPostalCodes: false,
        getDeliveryOrderPostalCodes: null,
        error: {
          ...state.error,
          getDeliveryOrderPostalCodesError: action.error,
        },
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: true,
        isLoadingGetDeliveryOrderPostalCodes: false,
        getDeliveryOrderPostalCodes: action.data,
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: false,
        isLoadingGetDeliveryOrderPostalCodes: true,
        getDeliveryOrderPostalCodes: null,
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: false,
        isLoadingGetDeliveryOrderPostalCodes: false,
        getDeliveryOrderPostalCodes: null,
        error: {
          ...state.error,
          getDeliveryOrderPostalCodesError: action.error,
        },
      };

    case types.GET_DELIVERYORDERPOSTALCODES_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderPostalCodesIsSuccess: true,
        isLoadingGetDeliveryOrderPostalCodes: false,
        getDeliveryOrderPostalCodes: action.data,
      };

    case types.GET_GOOGLESTATICMAPPAYLOAD_ASYNC.PENDING:
      return {
        ...state,
        getGoogleStaticMapPayloadIsSuccess: false,
        isLoadingGetGoogleStaticMapPayload: true,
        getGoogleStaticMapPayload: null,
      };

    case types.GET_GOOGLESTATICMAPPAYLOAD_ASYNC.ERROR:
      return {
        ...state,
        getGoogleStaticMapPayloadIsSuccess: false,
        isLoadingGetGoogleStaticMapPayload: false,
        getGoogleStaticMapPayload: null,
        error: {
          ...state.error,
          getGoogleStaticMapPayloadError: action.error,
        },
      };

    case types.GET_GOOGLESTATICMAPPAYLOAD_ASYNC.SUCCESS:
      return {
        ...state,
        getGoogleStaticMapPayloadIsSuccess: true,
        isLoadingGetGoogleStaticMapPayload: false,
        getGoogleStaticMapPayload: action.data,
      };

    case types.GET_DELIVERYOPTIMIZEDROUTING_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOptimizedRoutingIsSuccess: false,
        isLoadingGetDeliveryOptimizedRouting: true,
        getDeliveryOptimizedRouting: null,
      };

    case types.GET_DELIVERYOPTIMIZEDROUTING_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOptimizedRoutingIsSuccess: false,
        isLoadingGetDeliveryOptimizedRouting: false,
        getDeliveryOptimizedRouting: null,
        error: {
          ...state.error,
          getDeliveryOptimizedRoutingError: action.error,
        },
      };

    case types.GET_DELIVERYOPTIMIZEDROUTING_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOptimizedRoutingIsSuccess: true,
        isLoadingGetDeliveryOptimizedRouting: false,
        getDeliveryOptimizedRouting: action.data,
      };

    case types.GET_DELIVERYOPTIMIZEDROUTINGMAP_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOptimizedRoutingMapIsSuccess: false,
        isLoadingGetDeliveryOptimizedRoutingMap: true,
        getDeliveryOptimizedRoutingMap: null,
      };

    case types.GET_DELIVERYOPTIMIZEDROUTINGMAP_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOptimizedRoutingMapIsSuccess: false,
        isLoadingGetDeliveryOptimizedRoutingMap: false,
        getDeliveryOptimizedRoutingMap: null,
        error: {
          ...state.error,
          getDeliveryOptimizedRoutingMapError: action.error,
        },
      };

    case types.GET_DELIVERYOPTIMIZEDROUTINGMAP_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOptimizedRoutingMapIsSuccess: true,
        isLoadingGetDeliveryOptimizedRoutingMap: false,
        getDeliveryOptimizedRoutingMap: action.data,
      };

    case types.GET_DELIVERYVERIFYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryVerifyAddressIsSuccess: false,
        isLoadingGetDeliveryVerifyAddress: true,
        getDeliveryVerifyAddress: null,
      };

    case types.GET_DELIVERYVERIFYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryVerifyAddressIsSuccess: false,
        isLoadingGetDeliveryVerifyAddress: false,
        getDeliveryVerifyAddress: null,
        error: {
          ...state.error,
          getDeliveryVerifyAddressError: action.error,
        },
      };

    case types.GET_DELIVERYVERIFYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryVerifyAddressIsSuccess: true,
        isLoadingGetDeliveryVerifyAddress: false,
        getDeliveryVerifyAddress: action.data,
      };

    case types.PUT_UPDATEALLSTOREDELIVERYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        putUpdateAllStoreDeliveryAddressIsSuccess: false,
        isLoadingPutUpdateAllStoreDeliveryAddress: true,
        putUpdateAllStoreDeliveryAddress: null,
      };

    case types.PUT_UPDATEALLSTOREDELIVERYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        putUpdateAllStoreDeliveryAddressIsSuccess: false,
        isLoadingPutUpdateAllStoreDeliveryAddress: false,
        putUpdateAllStoreDeliveryAddress: null,
        error: {
          ...state.error,
          putUpdateAllStoreDeliveryAddressError: action.error,
        },
      };

    case types.PUT_UPDATEALLSTOREDELIVERYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateAllStoreDeliveryAddressIsSuccess: true,
        isLoadingPutUpdateAllStoreDeliveryAddress: false,
        putUpdateAllStoreDeliveryAddress: action.data,
      };

    case types.POST_SEARCHCSVCOURIERDELIVERYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        postSearchCsvCourierDeliveryAddressIsSuccess: false,
        isLoadingPostSearchCsvCourierDeliveryAddress: true,
        postSearchCsvCourierDeliveryAddress: null,
      };

    case types.POST_SEARCHCSVCOURIERDELIVERYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        postSearchCsvCourierDeliveryAddressIsSuccess: false,
        isLoadingPostSearchCsvCourierDeliveryAddress: false,
        postSearchCsvCourierDeliveryAddress: null,
        error: {
          ...state.error,
          postSearchCsvCourierDeliveryAddressError: action.error,
        },
      };

    case types.POST_SEARCHCSVCOURIERDELIVERYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        postSearchCsvCourierDeliveryAddressIsSuccess: true,
        isLoadingPostSearchCsvCourierDeliveryAddress: false,
        postSearchCsvCourierDeliveryAddress: action.data,
      };

    case types.POST_SEARCHCOURIERDELIVERYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        postSearchCourierDeliveryAddressIsSuccess: false,
        isLoadingPostSearchCourierDeliveryAddress: true,
        postSearchCourierDeliveryAddress: null,
      };

    case types.POST_SEARCHCOURIERDELIVERYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        postSearchCourierDeliveryAddressIsSuccess: false,
        isLoadingPostSearchCourierDeliveryAddress: false,
        postSearchCourierDeliveryAddress: null,
        error: {
          ...state.error,
          postSearchCourierDeliveryAddressError: action.error,
        },
      };

    case types.POST_SEARCHCOURIERDELIVERYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        postSearchCourierDeliveryAddressIsSuccess: true,
        isLoadingPostSearchCourierDeliveryAddress: false,
        postSearchCourierDeliveryAddress: action.data,
      };

    case types.PUT_UPDATEDELIVERYTRACKINGROWNO_ASYNC.PENDING:
      return {
        ...state,
        putUpdateDeliveryTrackingRowNoIsSuccess: false,
        isLoadingPutUpdateDeliveryTrackingRowNo: true,
        putUpdateDeliveryTrackingRowNo: null,
      };

    case types.PUT_UPDATEDELIVERYTRACKINGROWNO_ASYNC.ERROR:
      return {
        ...state,
        putUpdateDeliveryTrackingRowNoIsSuccess: false,
        isLoadingPutUpdateDeliveryTrackingRowNo: false,
        putUpdateDeliveryTrackingRowNo: null,
        error: {
          ...state.error,
          putUpdateDeliveryTrackingRowNoError: action.error,
        },
      };

    case types.PUT_UPDATEDELIVERYTRACKINGROWNO_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateDeliveryTrackingRowNoIsSuccess: true,
        isLoadingPutUpdateDeliveryTrackingRowNo: false,
        putUpdateDeliveryTrackingRowNo: action.data,
      };

    case types.GET_DELIVERYORDERVERIFYADDRESSLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderVerifyAddressListIsSuccess: false,
        isLoadingGetDeliveryOrderVerifyAddressList: true,
        getDeliveryOrderVerifyAddressList: null,
      };

    case types.GET_DELIVERYORDERVERIFYADDRESSLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderVerifyAddressListIsSuccess: false,
        isLoadingGetDeliveryOrderVerifyAddressList: false,
        getDeliveryOrderVerifyAddressList: null,
        error: {
          ...state.error,
          getDeliveryOrderVerifyAddressListError: action.error,
        },
      };

    case types.GET_DELIVERYORDERVERIFYADDRESSLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderVerifyAddressListIsSuccess: true,
        isLoadingGetDeliveryOrderVerifyAddressList: false,
        getDeliveryOrderVerifyAddressList: action.data,
      };

    default:
      return state;
  }
};
