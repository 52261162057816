import React, { useState, useEffect } from "react";

import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
//components
import { useStyles } from "../../../../../components/globalStyles";
import StoreListLinkedDeliveryTable from "../editOrder/StoreListLinkedDeliveryTable";
import * as generalConstants from "../../../../../_constants/generalConstants";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import StyledCheckbox from "../../../../../components/StyledCheckbox";

import { SortingSearchTable } from "../../../../../components/SortingSearchTable";

import * as pageConfigure from "../../../../../_constants/pageConstants";

import StyledRadioCheckbox from "../../../../../components/StyledRadioCheckbox";

const SharedDelivery = ({
  memoSelectedGroup,
  memoSelectedGroupIndex,
  memoGroupOption,
  setGroupOption,
  viemOnly = false,
  module,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const [storeHeader, setStoreHeader] = useState(null);

  const [deliveryOwned, setDeliveryOwned] = useState(null);

  const [groupSelectView, setGroupSelectView] = useState(false);

  const [groupSelectInfo, setGroupSelectInfo] = useState(null);

  const [alertMsg, setAlertMsg] = useState(false);

  const [alertType, setAlertType] = useState("success");

  const [msgAlarm, setMsgAlarm] = useState(null);

  const [keyRef, setKeyRef] = useState(0);

  const viewColumns = [
    {
      Header: "",
      accessor: "checkVisitOrder",
      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <StyledRadioCheckbox
            checked={
              deliveryOwned !== null && deliveryOwned.id === row.original.id
                ? true
                : false
            }
            name="checkVisitOrder"
            onChange={(e) => handleVisitOrderCheck(e, row)}
          />
        );
      },
    },

    {
      Header: t("translation:Group"),
      accessor: "name",
    },

    {
      Header: t("translation:Recipient"),
      accessor: "delivery_name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "delivery_contact_number",
    },

    {
      Header: t("translation:Postal Code"),
      accessor: "delivery_postal_code",
    },

    {
      Header: t("translation:Address Details"),
      accessor: "delivery_address_details",
    },

    {
      Header: t("translation:Address"),
      accessor: "delivery_address",
    },

    {
      Header: t("translation:Remarks"),
      accessor: "delivery_remarks",
    },
  ];
  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const onConfirmSubmit = (event, action) => {
    if (!groupSelectView) {
      setAlertMsg(false);
      setGroupSelectView(false);
      //[20230721]
      //check same dispensary
      //check peending
      //check multiple group
      const selectAvailableGroup = storeHeader
        ? storeHeader.store_medicine_headers.filter(
            (item) =>
              item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
              item.is_delivery_services === true &&
              item.organisation_branch.id ===
                memoSelectedGroup.organisation_branch.id
          )
        : null;
      if (selectAvailableGroup !== null && selectAvailableGroup?.length > 0) {
        if (selectAvailableGroup.length !== 0) {
          setKeyRef((item) => item + 1);
          if (selectAvailableGroup.length === 1) {
            setGroupSelectView(false);
            setCheck(true);
            setOpen(false);
            const elementsIndex = memoSelectedGroupIndex;
            let newArray = [...memoGroupOption];
            const temDeliveryOwned = {
              delivery_address: selectAvailableGroup[0].delivery_address,
              delivery_address_details:
                selectAvailableGroup[0].delivery_address_details,
              delivery_contact_number:
                selectAvailableGroup[0].delivery_contact_number,
              delivery_name: selectAvailableGroup[0].delivery_name,
              delivery_postal_code:
                selectAvailableGroup[0].delivery_postal_code,
              delivery_remarks: selectAvailableGroup[0].delivery_remarks,
              delivery_blk_no: selectAvailableGroup[0].delivery_blk_no,
              delivery_road_name: selectAvailableGroup[0].delivery_road_name,
              delivery_building: selectAvailableGroup[0].delivery_building,
              id: selectAvailableGroup[0].id,
              name: selectAvailableGroup[0].name,
              store_header: {
                desc: null,
                id: storeHeader.id,
                name: storeHeader.name,
                order_number: storeHeader.order_number,
              },
            };
            if (newArray[elementsIndex].delivery_owned?.id) {
              newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                is_delivery_services: false,
                delivery_address: null,
                delivery_address_details: null,
                delivery_blk_no: null,
                delivery_building: null,
                delivery_contact_number: null,
                delivery_name: null,
                delivery_postal_code: null,
                delivery_remarks: null,
                delivery_road_name: null,
                delivery_owned: {
                  id: newArray[elementsIndex].delivery_owned.id,
                  delivery_linked_store_medicine_header: temDeliveryOwned,
                  status_type: generalConstants.APPROVED_STATUS_TYPE,
                },
              };
            } else {
              newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                is_delivery_services: false,
                delivery_address: null,
                delivery_address_details: null,
                delivery_blk_no: null,
                delivery_building: null,
                delivery_contact_number: null,
                delivery_name: null,
                delivery_postal_code: null,
                delivery_remarks: null,
                delivery_road_name: null,
                delivery_owned: {
                  id: 0,
                  delivery_linked_store_medicine_header: temDeliveryOwned,
                  status_type: generalConstants.APPROVED_STATUS_TYPE,
                },
              };
            }

            setGroupOption(newArray);

            setDeliveryOwned(temDeliveryOwned);
          } else {
            setGroupSelectView(true);
            setGroupSelectInfo(selectAvailableGroup);
          }
        }
      } else {
        setAlertMsg(true);

        setAlertType("error");

        setMsgAlarm(t("translation:No valid order"));
        setKeyRef((item) => item + 1);
      }
    } else {
      if (deliveryOwned) {
        const elementsIndex = memoSelectedGroupIndex;
        let newArray = [...memoGroupOption];

        if (newArray[elementsIndex].delivery_owned?.id) {
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            is_delivery_services: false,
            delivery_owned: {
              id: newArray[elementsIndex].delivery_owned.id,
              delivery_linked_store_medicine_header: deliveryOwned,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
            },
          };
        } else {
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            is_delivery_services: false,
            delivery_owned: {
              id: 0,
              delivery_linked_store_medicine_header: deliveryOwned,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
            },
          };
        }

        setGroupOption(newArray);
        setCheck(true);
        setOpen(false);
      } else {
        setAlertMsg(true);

        setAlertType("error");
        setMsgAlarm(t("translation:Please select a group"));
      }
    }
  };

  const handleVisitOrderCheck = (e, row) => {
    if (e.target.checked) {
      const deliveryOwner = {
        delivery_address: row.original.delivery_address,
        delivery_address_details: row.original.delivery_address_details,
        delivery_contact_number: row.original.delivery_contact_number,
        delivery_name: row.original.delivery_name,
        delivery_postal_code: row.original.delivery_postal_code,
        delivery_blk_no: row.original.delivery_blk_no,
        delivery_road_name: row.original.delivery_road_name,
        delivery_building: row.original.delivery_building,
        delivery_remarks: row.original.delivery_remarks,
        id: row.original.id,
        name: row.original.name,
        store_header: {
          desc: null,
          id: storeHeader.id,
          name: storeHeader.name,
          order_number: storeHeader.order_number,
        },
      };
      setDeliveryOwned(deliveryOwner);
    } else {
      setDeliveryOwned(null);
    }
  };

  const handleLinkedDeliveryService = (event, action) => {
    setCheck(false);
    if (check) {
      const elementsIndex = memoSelectedGroupIndex;
      let newArray = [...memoGroupOption];

      if (newArray[elementsIndex].delivery_owned.id) {
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          is_delivery_services: false,
          delivery_owned: {
            id: newArray[elementsIndex].delivery_owned.id,
            delivery_linked_store_medicine_header: null,
            status_type: generalConstants.DELETED_STATUS_TYPE,
          },
        };
      } else {
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          is_delivery_services: false,
          delivery_owned: null,
        };
      }

      setGroupOption(newArray);
    }
    if (!check) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setDeliveryOwned(null);
      setStoreHeader(null);
      setGroupSelectInfo(null);
      setGroupSelectView(false);
    }
  }, [open]);

  useEffect(() => {
    setCheck(
      memoSelectedGroup?.delivery_owned?.status_type ===
        generalConstants.APPROVED_STATUS_TYPE
    );
  }, [memoSelectedGroup.delivery_owned]);

  return (
    <>
      {open && (
        <ConfirmDialog
          key={keyRef}
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Link Delivery Services")}
          open={open}
          setOpen={setOpen}
          maxWidth="lg"
          onConfirm={onConfirmSubmit}
          submitWithClose={false}>
          <Collapse in={alertMsg}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMsg(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
          {groupSelectView && (
            <>
              {" "}
              <Grid item xs={12}>
                <SortingSearchTable
                  disabledSearch
                  columns={viewColumns}
                  data={groupSelectInfo}
                  pageInfo={viewPageInfo}
                  setPageInfo={setViewPageInfo}
                  disabledFooter
                />
              </Grid>
            </>
          )}
          {!groupSelectView && (
            <StoreListLinkedDeliveryTable
              storeHeader={storeHeader}
              setStoreHeader={setStoreHeader}
              module={module}
            />
          )}
        </ConfirmDialog>
      )}

      <Grid container>
        <Grid item xs={12} className={classes.userInfoField}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={check}
                disabled={viemOnly}
                onChange={handleLinkedDeliveryService}
                name="sharedDelivery"
              />
            }
            label={t("translation:Link Delivery Services")}
          />
        </Grid>

        {memoSelectedGroup?.delivery_owned?.status_type ===
          generalConstants.APPROVED_STATUS_TYPE &&
          check === true && (
            <>
              <Grid item xs={12} className={classes.userInfoField}>
                <Autocomplete
                  options={[]}
                  disabled
                  multiple
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.store_header
                      .order_number
                  }
                  style={{ width: "100%" }}
                  renderTags={(value, props) => (
                    <Chip
                      label={
                        memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header?.store_header
                          .order_number +
                        "/" +
                        memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header?.name
                      }
                      clickable={false}
                    />
                  )}
                  renderInput={(params) => {
                    const InputProps = { ...params.InputProps };
                    InputProps.endAdornment = null;
                    return (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Linked to order/ group")}
                        variant="outlined"
                        InputProps={InputProps}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.delivery_name
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header?.delivery_name
                      : ""
                  }
                  name="delivery_name"
                  label={t("translation:Recipient")}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header
                      ?.delivery_contact_number
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_contact_number
                      : ""
                  }
                  name="delivery_contact_number"
                  required
                  label={t("translation:Contact Number")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header
                      ?.delivery_postal_code
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_postal_code
                      : ""
                  }
                  name="delivery_postal_code"
                  label={t("translation:Postal Code")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header
                      ?.delivery_address_details
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_address_details
                      : ""
                  }
                  name="delivery_address_details"
                  placeholder={t("translation:Floor/ Unit Number")}
                  variant="outlined"
                  label={t("translation:Address Details")}
                  disabled
                />
              </Grid>

              {memoSelectedGroup.delivery_owned
                ?.delivery_linked_store_medicine_header?.delivery_blk_no ? (
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    disabled
                    value={
                      memoSelectedGroup.delivery_owned
                        ?.delivery_linked_store_medicine_header?.delivery_blk_no
                    }
                    name="blk_no"
                    label={t("translation:Block No")}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <></>
              )}

              {memoSelectedGroup.delivery_owned
                ?.delivery_linked_store_medicine_header?.delivery_road_name ? (
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    disabled
                    value={
                      memoSelectedGroup.delivery_owned
                        ?.delivery_linked_store_medicine_header
                        ?.delivery_road_name
                    }
                    name="road_name"
                    label={t("translation:Road Name")}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <></>
              )}

              {memoSelectedGroup.delivery_owned
                ?.delivery_linked_store_medicine_header?.delivery_building ? (
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    disabled
                    value={
                      memoSelectedGroup.delivery_owned
                        ?.delivery_linked_store_medicine_header
                        ?.delivery_building
                    }
                    name="building"
                    label={t("translation:Building")}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.delivery_address
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_address
                      : ""
                  }
                  required
                  multiline
                  rows={3}
                  name="delivery_address"
                  label={t("translation:Address")}
                  variant="outlined"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.delivery_remarks
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_remarks
                      : ""
                  }
                  multiline
                  rows={3}
                  name="delivery_remarks"
                  label={t("translation:Remarks")}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};
export default SharedDelivery;
